import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
} from '@mui/material';

import './CreateCost.css';
import toast from 'react-hot-toast';
import { useRedirect } from 'react-admin';
import { trigger } from '../utils/events';

async function createFond(param: { name: any; description: any }) {
  // {{url}}/fund
  const url = process.env.REACT_APP_API_URL;
  try {
    const response = await fetch(`${url}/fund`, {
      credentials: 'include',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(param),
    });
    const data = await response.json();
    console.log(data);
  } catch (e) {
    console.log(e);
  }
}

export const CostsAnalyticsCreate = () => {
  async function submitHandler(data: any) {
    console.log(data);
    if (data.name === '' || data.name === undefined) {
      toast.error('Name is required');
    }
    try {
      await createFond({
        name: data.name,
        description: data.description,
      }).then(() => {
        trigger('updateMenu', {});

      } );
    } catch (error: any) {
      toast.error(error?.toString());
    }
  }
  const redirect = useRedirect();

  return (
    <Paper className={'create_fond_page'}>
      <form
        onSubmit={async (event: any) => {
          event.preventDefault();
          try {
            await submitHandler({
              name: event.target[0].value,
              description: event.target[2].value,
            });
            redirect('/costsAnalytics');
          } catch (error: any) {
            toast.error(error?.toString());
          }
        }}
      >
        <TextField label="Name" variant="outlined" />
        <TextField label="Description" variant="outlined" />
        <Button type={'submit'} variant="contained">
          Create
        </Button>
      </form>
    </Paper>
  );
};
