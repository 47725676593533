import { Edit, FormTab, required, TabbedForm, TextInput } from 'react-admin';

const ServerProviderEdit = () => (
  <Edit>
    <TabbedForm>
      <FormTab label="D" sx={{ maxWidth: '40em' }}>
        <TextInput autoFocus source="name" fullWidth validate={required()} />
      </FormTab>
    </TabbedForm>
  </Edit>
);

export default ServerProviderEdit;
