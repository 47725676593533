import LiquiditybBotssList from './LiquiditybBotssList';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import LiquiditybBotsCreate from './LiquiditybBotsCreate';
import LiquiditybBotsEdit from './LiquiditybBotsEdit';

export default {
  list: LiquiditybBotssList,
  create: LiquiditybBotsCreate,
  icon: AccountBalanceIcon,
  edit: LiquiditybBotsEdit,
};
