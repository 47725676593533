import { Box, Drawer, Theme, useMediaQuery } from '@mui/material';
import { CreateButton, EditButton, List, TopToolbar } from 'react-admin';

import { useCallback } from 'react';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';
import FilesListDesktop from './FilesListDesktop';
import FileViewDrawer from './FileViewDrawer';

const FilesList = () => {
  const isXSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));
  const location = useLocation();
  const navigate = useNavigate();
  const handleClose = useCallback(() => {
    navigate('/files');
  }, [navigate]);
  const match = matchPath('/files/:id', location.pathname);
  return (
    <Box display="flex">
      <List
        sx={{
          flexGrow: 1,
          transition: (theme: any) =>
            theme.transitions.create(['all'], {
              duration: theme.transitions.duration.enteringScreen,
            }),
          marginRight: !!match ? '400px' : 0,
        }}
        actions={<ListActions />}
        sort={{ field: 'created', order: 'DESC' }}
      >
        {isXSmall ? (
          <FilesListDesktop />
        ) : (
          <FilesListDesktop
            selectedRow={!!match ? parseInt((match as any).params.id, 10) : undefined}
          />
        )}
      </List>
      <Drawer
        variant="persistent"
        open={!!match}
        anchor="right"
        onClose={handleClose}
        sx={{ zIndex: 100 }}
      >
        {!!match && <FileViewDrawer id={(match as any).params.id} onCancel={handleClose} />}
      </Drawer>
    </Box>
  );
};

const ListActions = ({ isSmall }: any) => (
  <TopToolbar sx={{ minHeight: { sm: 56 } }}>
    <CreateButton />
    <EditButton />
  </TopToolbar>
);

export default FilesList;
