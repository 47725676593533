import {
  Create,
  FormTab,
  ReferenceInput,
  required,
  SelectInput,
  TabbedForm,
  TextInput,
} from 'react-admin';

const BlockchainCreate = () => {
  return (
    <Create>
      <TabbedForm>
        <FormTab label="resources.blockchaintype.tabs.main" sx={{ maxWidth: '40em' }}>
          <TextInput autoFocus source="name" fullWidth validate={required()} />
          <TextInput source="height" fullWidth />
          <TextInput validate={required()} source="nodeUrl" fullWidth />
          <ReferenceInput
            label="Blockchain type"
            source="blockchainTypeId"
            reference="blockchain_types"
            sort={{ field: 'name', order: 'ASC' }}
            alwaysOn
          >
            <SelectInput optionText="name" optionValue="id" defaultValue={''} />
          </ReferenceInput>
          <TextInput source="coreCoinSymbol" fullWidth validate={required()} />
        </FormTab>
      </TabbedForm>
    </Create>
  );
};

export default BlockchainCreate;
