import { Theme, Typography, useMediaQuery } from '@mui/material';
import { Datagrid, FunctionField, List, useGetList } from 'react-admin';
import { IExchange } from '../../types';

const VersionsList = () => {
  const { isLoading, data: exchanges } = useGetList<IExchange>('exchanges', {
    filter: {},
    sort: { field: 'id', order: 'DESC' },
    pagination: { page: 1, perPage: 100 },
  });
  return (
    <List
      sx={{ display: isLoading ? 'none' : 'block' }}
      resource="blockchains/versions"
      sort={{ field: 'id', order: 'DESC' }}
    >
      <Datagrid>
        <FunctionField label="Notifier" render={(record: any) => record?.coreCoinSymbol} />
        <FunctionField label="Type" render={(record: any) => record?.blockchainTypeName} />
        <FunctionField
          label="Latest Vers"
          render={(record: any) => record?.blockchainTypeLastNotifierVersion}
        />
        {exchanges
          ? exchanges.map((exch: IExchange, index) => {
              return (
                <FunctionField
                  key={index}
                  label={exch.name}
                  render={(record: any) => {
                    let color = '';
                    const finded = record.exchanges.find((i: any) => i.id === exch.id);
                    if (record?.blockchainTypeLastNotifierVersion != finded.version) {
                      color = 'red';
                    }
                    return <Typography sx={{ color }}>{finded.version}</Typography>;
                  }}
                />
              );
            })
          : null}
      </Datagrid>
    </List>
  );
};
export default VersionsList;
