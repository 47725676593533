import UserCreate from './UserCreate';
import UserEdit from './UserEdit';
import UserIcon from '@mui/icons-material/People';
import UserList from './UserList';

export default {
  list: UserList,
  create: UserCreate,
  edit: UserEdit,
  icon: UserIcon,
};
