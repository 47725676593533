import React, { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { CircularProgress, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { TableCellRight } from '../../employees/TableCellRight';

const getStastistic = async () => {
  const statistic = await fetch(`${process.env.REACT_APP_API_URL}/exchanges/analitics/all`, {
    method: 'GET',
    credentials: 'include',
    redirect: 'follow',
  });
  const res = await statistic.json();
  return res;
};

const NotifireAnalistic = () => {
  const [statistic, setStatistic] = useState([]);

  // @ts-ignore
  useEffect(async () => {
    const stats = await getStastistic();
    setStatistic(stats);
  }, [setStatistic]);

  return (
    <Box>
      <h1>Notifier analitics</h1>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Project</TableCell>
            <TableCellRight>Count</TableCellRight>
            <TableCellRight>Total</TableCellRight>
            <TableCellRight>Unconnected</TableCellRight>
            <TableCellRight>Status</TableCellRight>
          </TableRow>
        </TableHead>
        <TableBody>
          {statistic && statistic?.length ? (
            statistic.map((item: any) => (
              <TableRow>
                <TableCell>{item.name}</TableCell>
                <TableCellRight>{item.notifiers}</TableCellRight>
                <TableCellRight>{item.totalNotifiers}</TableCellRight>
                <TableCellRight>{item.unconnectedNotifiers}</TableCellRight>
                <TableCellRight>
                  {item.status ? (
                    <div style={{ color: 'green' }}>Health</div>
                  ) : (
                    <div style={{ color: 'red' }}>Unhealth</div>
                  )}
                </TableCellRight>
              </TableRow>
            ))
          ) : (
            <CircularProgress />
          )}
        </TableBody>
      </Table>
    </Box>
  );
};

export default NotifireAnalistic;
