import * as React from 'react';
import { Avatar, SxProps } from '@mui/material';
import { FieldProps, useRecordContext } from 'react-admin';
import { IEmployee } from './FullNameEmployeeField';
import { deepOrange } from '@mui/material/colors';

interface Props extends FieldProps<IEmployee> {
  sx?: SxProps;
  size?: string;
}

const AvatarEmployeeField = ({ size = '25', sx }: Props) => {
  const record = useRecordContext<IEmployee>();
  if (!record) return null;
  const first = record.firstname.slice(0, 1);
  const last = record.lastname.slice(0, 1);
  const ava = first + last;
  return (
    <Avatar
      style={{
        margin: '0 8px 0',
        fontSize: '12px',
        width: parseInt(size, 10),
        height: parseInt(size, 10),
      }}
      sx={{ bgcolor: deepOrange[500] }}
    >
      {ava}
    </Avatar>
  );
};

export default AvatarEmployeeField;
