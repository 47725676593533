import { Theme, Typography, useMediaQuery } from '@mui/material';
import {
  CreateButton,
  Datagrid,
  EditButton,
  FunctionField,
  List,
  SearchInput,
  TextField,
  TopToolbar,
} from 'react-admin';
import SkillsListAside from './SkillsListAside';

const skillsFilters = [<SearchInput source="q" alwaysOn />];

const SkillsList = () => {
  const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('md'));
  return (
    <List
      aside={<SkillsListAside />}
      filters={isSmall ? skillsFilters : undefined}
      actions={<ListActions />}
      sort={{ field: 'id', order: 'DESC' }}
    >
      <Datagrid rowClick="show">
        <Typography display="flex" component="div">
          <FunctionField
            label="Image"
            sx={{ marginRight: '1em' }}
            render={(record: any) => {
              return <img src={`/icons/${record.name}.svg`} width="20" height="20" />;
            }}
          />
          <TextField source="name" />
        </Typography>
      </Datagrid>
    </List>
  );
};

const ListActions = () => (
  <TopToolbar sx={{ minHeight: { sm: 56 } }}>
    <CreateButton />
    <EditButton />
  </TopToolbar>
);

export default SkillsList;
