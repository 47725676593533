import CurrencyBitcoinIcon from '@mui/icons-material/CurrencyBitcoin';
import BlockchainCreate from './BlockchainCreate';
import BlockchainEdit from './BlockchainEdit';
import BlockchainList from './BlockchainList';
import BlockchainShow from './BlockchainShow';

export default {
  list: BlockchainList,
  create: BlockchainCreate,
  icon: CurrencyBitcoinIcon,
  edit: BlockchainEdit,
  show: BlockchainShow,
};
