import * as React from 'react';
import { useTranslate, EditProps } from 'react-admin';
import { Box, Stack, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { Review } from '../types';

interface Props extends EditProps<Review> {
  onCancel: () => void;
}

const FileViewDrawer = ({ onCancel, ...props }: Props) => {
  const translate = useTranslate();
  const url = `${process.env.REACT_APP_API_URL}/files/${props.id}`;

  return (
    <Box pt={5} width={{ xs: '100vW', sm: 500 }} mt={{ xs: 2, sm: 1 }}>
      <Stack direction="row" p={2}>
        <Typography variant="h6" flex="1">
          {translate('resources.files.detail')}
        </Typography>
        <IconButton onClick={onCancel} size="small">
          <CloseIcon />
        </IconButton>
      </Stack>

      <Box height={{ xs: 700 }} width={{ xs: '100vW', sm: 500 }}>
        <object data={url} type="application/pdf" width="100%" height="100%">
          <p>
            Alternative text - include a link <a href={url}>to the PDF!</a>
          </p>
        </object>
      </Box>
    </Box>
  );
};

export default FileViewDrawer;
