import * as React from 'react';
import { Fragment, useCallback, useEffect, useState } from 'react';
import {
  AutocompleteInput,
  BooleanField,
  Datagrid,
  DateField,
  DateInput,
  RaRecord,
  List,
  ListContextProvider,
  NullableBooleanInput,
  NumberField,
  ReferenceInput,
  ReferenceField,
  SearchInput,
  TextField,
  TextInput,
  useGetList,
  useListContext,
  FunctionField,
} from 'react-admin';
import { useMediaQuery, Divider, Tabs, Tab, Theme } from '@mui/material';

import NbItemsField from './NbItemsField';
import CustomerReferenceField from '../visitors/CustomerReferenceField';
import AddressField from '../visitors/AddressField';
import MobileGrid from './MobileGrid';
import { Customer } from '../types';
import DateFnsUtils from '@date-io/date-fns';
import lv from 'date-fns/locale/fr';
import { dateFormat } from '../utils/helpers';

const OrderList = () => (
  <List
    filterDefaultValues={{ status: 'ordered' }}
    sort={{ field: 'date', order: 'DESC' }}
    perPage={25}
    filters={orderFilters}
  >
    <TabbedDatagrid />
  </List>
);

const orderFilters = [
  <SearchInput source="q" alwaysOn />,
  <ReferenceInput source="customer_id" reference="customers">
    <AutocompleteInput
      optionText={(choice?: Customer) =>
        choice?.id ? `${choice.first_name} ${choice.last_name}` : ''
      }
    />
  </ReferenceInput>,
  <DateInput source="date_gte" />,
  <DateInput source="date_lte" />,
  <TextInput source="total_gte" />,
  <NullableBooleanInput source="returned" />,
];

const tabs = [
  { id: 'ordered', name: 'ordered' },
  { id: 'delivered', name: 'delivered' },
  { id: 'cancelled', name: 'cancelled' },
];

const useGetTotals = (filterValues: any) => {
  const { total: totalOrdered } = useGetList('commands', {
    pagination: { perPage: 1, page: 1 },
    sort: { field: 'id', order: 'ASC' },
    filter: { ...filterValues, status: 'ordered' },
  });
  const { total: totalDelivered } = useGetList('commands', {
    pagination: { perPage: 1, page: 1 },
    sort: { field: 'id', order: 'ASC' },
    filter: { ...filterValues, status: 'delivered' },
  });
  const { total: totalCancelled } = useGetList('commands', {
    pagination: { perPage: 1, page: 1 },
    sort: { field: 'id', order: 'ASC' },
    filter: { ...filterValues, status: 'cancelled' },
  });

  return {
    ordered: totalOrdered,
    delivered: totalDelivered,
    cancelled: totalCancelled,
  };
};

const TabbedDatagrid = () => {
  const listContext = useListContext();
  const { data, filterValues, setFilters, displayedFilters, isLoading } = listContext;
  const isXSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));
  const [ordered, setOrdered] = useState<RaRecord[]>([]);
  const [delivered, setDelivered] = useState<RaRecord[]>([]);
  const [cancelled, setCancelled] = useState<RaRecord[]>([]);
  const totals = useGetTotals(filterValues) as any;

  useEffect(() => {
    if (isLoading) {
      return;
    }
    switch (filterValues.status) {
      case 'ordered':
        setOrdered(data);
        break;
      case 'delivered':
        setDelivered(data);
        break;
      case 'cancelled':
        setCancelled(data);
        break;
    }
  }, [data, isLoading, filterValues.status]);

  const handleChange = useCallback(
    (event: React.ChangeEvent<{}>, value: any) => {
      setFilters({ ...filterValues, status: value }, displayedFilters, false);
    },
    [displayedFilters, filterValues, setFilters],
  );

  const selectedData =
    filterValues.status === 'ordered'
      ? ordered
      : filterValues.status === 'delivered'
      ? delivered
      : cancelled;

  return (
    <Fragment>
      <Tabs
        variant="fullWidth"
        centered
        value={filterValues.status}
        indicatorColor="primary"
        onChange={handleChange}
      >
        {tabs.map(choice => (
          <Tab
            key={choice.id}
            label={totals[choice.name] ? `${choice.name} (${totals[choice.name]})` : choice.name}
            value={choice.id}
          />
        ))}
      </Tabs>
      <Divider />
      {isXSmall ? (
        <ListContextProvider value={{ ...listContext, data: selectedData }}>
          <MobileGrid data={selectedData} />
        </ListContextProvider>
      ) : (
        <>
          {filterValues.status === 'ordered' && (
            <ListContextProvider value={{ ...listContext }}>
              <Datagrid optimized rowClick="edit">
                <FunctionField
                  source="date"
                  label="date"
                  render={(record: any) => {
                    return dateFormat(record.date);
                  }}
                />
                <TextField source="reference" />
                <CustomerReferenceField />
                <ReferenceField
                  source="customer_id"
                  reference="customers"
                  link={false}
                  label="resources.commands.fields.address"
                >
                  <AddressField />
                </ReferenceField>
                <NbItemsField />
                <NumberField
                  source="total"
                  options={{
                    style: 'currency',
                    currency: 'USD',
                  }}
                  sx={{ fontWeight: 'bold' }}
                />
              </Datagrid>
            </ListContextProvider>
          )}
          {filterValues.status === 'delivered' && (
            <ListContextProvider value={{ ...listContext }}>
              <Datagrid rowClick="edit">
                <FunctionField
                  source="date"
                  label="date"
                  render={(record: any) => {
                    return dateFormat(record.date);
                  }}
                />
                <TextField source="reference" />
                <CustomerReferenceField />
                <ReferenceField
                  source="customer_id"
                  reference="customers"
                  link={false}
                  label="resources.commands.fields.address"
                >
                  <AddressField />
                </ReferenceField>
                <NbItemsField />
                <NumberField
                  source="total"
                  options={{
                    style: 'currency',
                    currency: 'USD',
                  }}
                  sx={{ fontWeight: 'bold' }}
                />
                <BooleanField source="returned" sx={{ mt: -0.5, mb: -0.5 }} />
              </Datagrid>
            </ListContextProvider>
          )}
          {filterValues.status === 'cancelled' && (
            <ListContextProvider value={{ ...listContext }}>
              <Datagrid rowClick="edit">
                <FunctionField
                  source="date"
                  label="date"
                  render={(record: any) => {
                    return dateFormat(record.date);
                  }}
                />
                <TextField source="reference" />
                <CustomerReferenceField />
                <ReferenceField
                  source="customer_id"
                  reference="customers"
                  link={false}
                  label="resources.commands.fields.address"
                >
                  <AddressField />
                </ReferenceField>
                <NbItemsField />
                <NumberField
                  source="total"
                  options={{
                    style: 'currency',
                    currency: 'USD',
                  }}
                  sx={{ fontWeight: 'bold' }}
                />
                <BooleanField source="returned" sx={{ mt: -0.5, mb: -0.5 }} />
              </Datagrid>
            </ListContextProvider>
          )}
        </>
      )}
    </Fragment>
  );
};

export default OrderList;
