import * as React from 'react';
import { Datagrid, List, FunctionField, TextField, DateInput } from 'react-admin';
import { Button, Table, TableCell, TableHead } from '@mui/material';
import { useEffect } from 'react';
import { Box } from '@mui/system';
import { Pagination } from 'react-admin';
import { dateFormat, dateFormatter, dateParser } from '../utils/helpers';

const getStats = async (filter: any) => {
  const stats = await fetch(
    `${process.env.REACT_APP_API_URL}/employees/salaries/stats?filter=${JSON.stringify(filter)}`,
    {
      credentials: 'include',
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );
  const res = stats.json();
  return res;
};

const PaymentsTable = () => {
  return (
    <>
      <Datagrid sort={{ field: 'startDate', order: 'ASC' }}>
        <FunctionField
          label="Name"
          render={(record: any) => `${record?.firstname} ${record.lastname}`}
        />
        <TextField source="position.name" />
        <FunctionField
          source="startDate"
          label="Start Date"
          render={(record: any) => dateFormat(record.startDate)}
        />
        <TextField source="status" />
        <TextField source="salary" />
        <TextField source="paymentType" />
        <TextField source="totalOvertimes" />
        <TextField source="bonus" />
        <TextField source="penalty" />
        <TextField source="paymentDetails" />
        <FunctionField
          label="Total Salary"
          render={(record: any) =>
            `${record?.totalSalary}  ${record?.currency !== undefined ? ` ${record.currency}` : ''}`
          }
        />
        <Button>Оплатить</Button>
      </Datagrid>
    </>
  );
};

function CustomPaymentsInfo({ stats }: any) {
  return (
    <Box>
      <Table>
        <TableHead>
          <TableCell>Total Employees</TableCell>
          <TableCell>Total Salary</TableCell>
          <TableCell>Avarage Salary</TableCell>
          <TableCell>Total Bonuses</TableCell>
          <TableCell>Total Penalties</TableCell>
          <TableCell>Total Overtimes</TableCell>
        </TableHead>
        <TableCell>{stats?.totalEmployees}</TableCell>
        <TableCell>{stats?.totalSalary}</TableCell>
        <TableCell>{stats?.avarageSalary}</TableCell>
        <TableCell>{stats?.totalBonuses}</TableCell>
        <TableCell>{stats?.totalPenalties}</TableCell>
        <TableCell>{stats?.totalOvertimes}</TableCell>
      </Table>
    </Box>
  );
}

const PostPagination = () => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} />;

const PaymentsList = () => {
  const [stats, setStats] = React.useState<any>(null);
  const [date, setDate] = React.useState<Date | null>(new Date());

  // @ts-ignore
  useEffect(async () => {
    if (date) {
      const stat = await getStats({ date });
      setStats(stat);
    }
  }, [date]);

  const handleDateChange = (date: any) => {
    setDate(new Date(date.target.value));
  };

  return (
    <>
      <List
        pagination={<PostPagination />}
        title="Payments"
        filters={[
          <DateInput
            required={false}
            defaultValue={null}
            format={dateFormatter}
            parse={dateParser}
            resource="salaries"
            value={date?.toLocaleDateString() || ''}
            onChange={handleDateChange}
            source="date"
            alwaysOn
            label="Date"
          />,
        ]}
        resource="custom_payments"
      >
        <>
          <List exporter={false} pagination={false}>
            <CustomPaymentsInfo stats={stats} />
          </List>
          <PaymentsTable />
        </>
      </List>
    </>
  );
};

export default PaymentsList;
