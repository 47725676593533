import CategoryIcon from '@mui/icons-material/Bookmark';

import CategoryList from './CategoryList';
import CategoryEdit from './CategoryEdit';

export default {
  list: CategoryList,
  edit: CategoryEdit,
  icon: CategoryIcon,
};
