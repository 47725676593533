import React, { useEffect } from 'react';
import { Box } from '@mui/system';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
function getStatusColor(status: any) {
  switch (status?.toLowerCase()) {
    case 'vocation':
      return '#F9F871';
    case 'closed leaver':
      return '#F87171';
    case 'leaver':
      return '#F87171';
    case 'worker':
      return '#FFFFFF';
    case 'sick':
      return '#71F8F8';
    case 'reschedule':
      return '#71F871';
    default:
      return '#FFFFFF';
  }
}

const getOutstaff = async (range: string, filter: string) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/custom_outstaff/?range=[${range}]&filter=${filter}`,
    {
      method: 'GET',
      credentials: 'include',
    },
  );

  const data = await response.json();
  const contentRange = response.headers.get('content-range');
  const allItemsCount = contentRange?.split('/')[1];

  return { outstaf: data, totalRangeItems: allItemsCount };
};

const OutstafTable = () => {
  const [outstaff, setOutstaff] = React.useState([]);
  const [date, setDate] = React.useState(new Date());
  const [count, setCount] = React.useState<any>(0);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(8);
  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const calcRangeFromCurrentPage = () => {
    const start = page * rowsPerPage;
    const end = start + rowsPerPage;
    return `${page === 0 ? 0 : start},${end}`;
  };

  // @ts-ignore
  useEffect(async () => {
    const arr = await getOutstaff(`0,${rowsPerPage}`, JSON.stringify({ date: date.toISOString() }));
    setOutstaff(arr.outstaf);
    setCount(arr.totalRangeItems);
  }, []);

  const getOutstaffHandler = async () => {
    const outstaffData = await getOutstaff(
      calcRangeFromCurrentPage(),
      JSON.stringify({ date: date.toISOString() }),
    );
    setOutstaff(outstaffData.outstaf);
    setCount(outstaffData.totalRangeItems);
  };

  // @ts-ignore
  useEffect(async () => {
    await getOutstaffHandler();
  }, [rowsPerPage]);

  // @ts-ignore
  useEffect(async () => {
    await getOutstaffHandler();
  }, [page]);

  const dataChangeHandler = async (date: any) => {
    setPage(0);
    const outstaffData = await getOutstaff(
      `0,${rowsPerPage}`,
      JSON.stringify({ date: date.toISOString() }),
    );
    setDate(date);
    setOutstaff(outstaffData.outstaf);
    setCount(outstaffData.totalRangeItems);
  };

  function showEmployeeMonthInfo(row: any, number: number) {
    return (
      <div>
        {row.months[number].sick > 0 ||
        row.months[number].vacation > 0 ||
        row.months[number].reschedule > 0 ? (
          //
          <Tooltip
            title={
              <Box display="flex" flexDirection="column">
                {row.months[number].sick > 0 ? (
                  <TableContainer>
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell align="right">Больничный</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key={row.months[number].month}>
                          <TableCell
                            align="right"
                            style={{
                              cursor: 'pointer',
                            }}
                          >
                            {row.months[number].outstaff
                              .filter((item: any) => item.type === 'sick')
                              .map((item: any) => (
                                <Box>
                                  <Box>
                                    <p>{new Date(item.dateFrom).toLocaleDateString()}</p> -{' '}
                                    <p>{new Date(item.dateTo).toLocaleDateString()}</p>
                                  </Box>
                                </Box>
                              ))}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : null}
                {row.months[number].vacation > 0 ? (
                  <TableContainer>
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell align="right">Отпуск</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key={row.months[number].month}>
                          <TableCell
                            align="right"
                            style={{
                              cursor: 'pointer',
                            }}
                          >
                            {row.months[number].outstaff
                              .filter((item: any) => item.type === 'vacation')
                              .map((item: any) => (
                                <Box>
                                  <Box>
                                    {new Date(item.dateFrom).toLocaleDateString()} -{' '}
                                    {new Date(item.dateTo).toLocaleDateString()}
                                  </Box>
                                </Box>
                              ))}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : null}
                {row.months[number].reschedule > 0 ? (
                  <TableContainer>
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell align="right">Перенос</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key={row.months[number].month}>
                          <TableCell
                            align="right"
                            style={{
                              cursor: 'pointer',
                            }}
                          >
                            {row.months[number].outstaff
                              .filter((item: any) => item.type === 'reschedule')
                              .map((item: any) => (
                                <Box>
                                  <Box>
                                    {new Date(item.dateFrom).toLocaleDateString()} -{' '}
                                    {new Date(item.dateTo).toLocaleDateString()}
                                  </Box>
                                </Box>
                              ))}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : null}
              </Box>
            }
          >
            <div
              style={{
                // желтый
                color: '#F9F871',
              }}
            >
              {row.months[number].sick +
                row.months[number].vacation +
                row.months[number].reschedule}
            </div>
          </Tooltip>
        ) : (
          <div
            style={{
              color: getStatusColor(row.months[number].status),
            }}
          >
            {row.months[number].status}
          </div>
        )}
      </div>
    );
  }

  function showTotalYearInfo(row: any) {
    return (
      <div>
        {row.total.sick > 0 || row.total.vacation > 0 || row.total.reschedule > 0 ? (
          <Tooltip
            title={`Sick: ${row.total.sick}
                        Vacation: ${row.total.vacation}
                        Reschedule: ${row.total.reschedule}`}
          >
            <div
              style={{
                color: '#F9F871',
              }}
            >
              {row.total.sick + row.total.vacation + row.total.reschedule}
            </div>
          </Tooltip>
        ) : (
          <div
            style={{
              color: '#F9F871',
            }}
          >
            {row.total.status}
          </div>
        )}
      </div>
    );
  }

  const isMonthEqual = (m: any): any => {
    const currentMonth = new Date().getUTCMonth(); // 0 - 11
    const monthes = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    const month = monthes.indexOf(m);
    const isCurYear = new Date().getUTCFullYear() === date.getUTCFullYear();
    return {
      borderLeft: isCurYear && month === currentMonth && '1px solid rgb(79 79 79)',
      borderRight: isCurYear && month === currentMonth && '1px solid rgb(79 79 79)',
    };
  };

  return (
    <Box>
      <Box className="yaer_picker">
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            onChange={dataChangeHandler}
            value={date}
            views={['year']}
            label="Set year please"
            renderInput={(props: any) => <TextField {...props} />}
          />
        </LocalizationProvider>
      </Box>
      <TableContainer
        component={Paper}
        style={{
          width: '100%',
          height: '100%',
          overflow: 'auto',
        }}
      >
        <Table aria-label="simple table">
          <TableHead
            style={{
              backgroundColor: '#444444',
            }}
          >
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>Employee</TableCell>
              <TableCell style={isMonthEqual('Jan')} align="right">
                Jan
              </TableCell>
              <TableCell style={isMonthEqual('Feb')} align="right">
                Feb
              </TableCell>
              <TableCell style={isMonthEqual('Mar')} align="right">
                Mar
              </TableCell>
              <TableCell style={isMonthEqual('Apr')} align="right">
                Apr
              </TableCell>
              <TableCell style={isMonthEqual('May')} align="right">
                May
              </TableCell>
              <TableCell style={isMonthEqual('Jun')} align="right">
                Jun
              </TableCell>
              <TableCell style={isMonthEqual('Jul')} align="right">
                Jul
              </TableCell>
              <TableCell style={isMonthEqual('Aug')} align="right">
                Aug
              </TableCell>
              <TableCell style={isMonthEqual('Sep')} align="right">
                Sep
              </TableCell>
              <TableCell style={isMonthEqual('Oct')} align="right">
                Oct
              </TableCell>
              <TableCell style={isMonthEqual('Nov')} align="right">
                Nov
              </TableCell>
              <TableCell style={isMonthEqual('Dec')} align="right">
                Dec
              </TableCell>
              <TableCell align="right">Total</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {outstaff.map((row: any, index) => (
              <TableRow
                key={row.employeeId}
                style={{
                  cursor: 'pointer',
                }}
              >
                <TableCell component="th" scope="row">
                  {index + 1}
                </TableCell>
                <TableCell component="th" scope="row">
                  {row.firstname} {row.lastname}
                </TableCell>
                <TableCell style={isMonthEqual('Jan')} align="right">
                  {showEmployeeMonthInfo(row, 0)}
                </TableCell>
                <TableCell style={isMonthEqual('Feb')} align="right">
                  {showEmployeeMonthInfo(row, 1)}
                </TableCell>
                <TableCell style={isMonthEqual('Mar')} align="right">
                  {showEmployeeMonthInfo(row, 2)}
                </TableCell>
                <TableCell style={isMonthEqual('Apr')} align="right">
                  {showEmployeeMonthInfo(row, 3)}
                </TableCell>
                <TableCell style={isMonthEqual('May')} align="right">
                  {showEmployeeMonthInfo(row, 4)}
                </TableCell>
                <TableCell style={isMonthEqual('Jun')} align="right">
                  {showEmployeeMonthInfo(row, 5)}
                </TableCell>
                <TableCell style={isMonthEqual('Jul')} align="right">
                  {showEmployeeMonthInfo(row, 6)}
                </TableCell>
                <TableCell style={isMonthEqual('Aug')} align="right">
                  {showEmployeeMonthInfo(row, 7)}
                </TableCell>
                <TableCell style={isMonthEqual('Sep')} align="right">
                  {showEmployeeMonthInfo(row, 8)}
                </TableCell>
                <TableCell style={isMonthEqual('Oct')} align="right">
                  {showEmployeeMonthInfo(row, 9)}
                </TableCell>
                <TableCell style={isMonthEqual('Nov')} align="right">
                  {showEmployeeMonthInfo(row, 10)}
                </TableCell>
                <TableCell style={isMonthEqual('Dec')} align="right">
                  {showEmployeeMonthInfo(row, 11)}
                </TableCell>
                <TableCell align="right">{showTotalYearInfo(row)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={count}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </Box>
  );
};

export default OutstafTable;
