import * as React from 'react';
import { Card, CardMedia } from '@mui/material';
import { useRecordContext } from 'react-admin';
import { File } from '../types';

const Poster2 = () => {
  const record = useRecordContext<File>();
  if (!record) return null;
  const url = `${process.env.REACT_APP_API_URL}/files/${record.id}`;
  return (
    <Card sx={{ height: '400px' }}>
      {record.mimetype.split('/')[0] === 'image' && (
        <CardMedia
          component="img"
          image={url}
          sx={{ maxWidth: 'fit-content', maxHeight: '55em' }}
        />
      )}
      {record.mimetype === 'application/pdf' && (
        <object data={url} type="application/pdf" width="100%" height="100%">
          <p>
            Alternative text - include a link <a href={url}>to the PDF!</a>
          </p>
        </object>
      )}
    </Card>
  );
};

export default Poster2;
