import { CreateButton, Datagrid, EditButton, List, TextField, TopToolbar } from 'react-admin';
// GET {{url}}/liquidity_bots?sort=["id","DESC"]&range=[0,99]&filter={}
// filter:
//   - id?: number[],
//   - name?: string,
//   - fingerprint?: string, //uniq
//   - exchangeId?: number

const LiquiditybBotssList = () => (
  <List actions={<ListActions />} sort={{ field: 'id', order: 'DESC' }}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="fingerprint" />
      <TextField source="exchangeId" />
    </Datagrid>
  </List>
);

const ListActions = ({ isSmall }: any) => (
  <TopToolbar sx={{ minHeight: { sm: 56 } }}>
    <CreateButton />
    <EditButton />
  </TopToolbar>
);

export default LiquiditybBotssList;
