import * as React from 'react';
import DollarIcon from '@mui/icons-material/AttachMoney';
import { useTranslate } from 'react-admin';
import CardWithIcon from './CardWithIcon';

interface Props {
  value?: string;
}

const TotalProjects = (props: Props) => {
  const { value } = props;
  const translate = useTranslate();
  return (
    <CardWithIcon
      to="/projects"
      icon={DollarIcon}
      title={translate('pos.dashboard.total_projects')}
      subtitle={value}
    />
  );
};

export default TotalProjects;
