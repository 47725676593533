import * as React from 'react';
import { useRecordContext, NumberField, NumberFieldProps, FunctionField } from 'react-admin';

const ColoredHealthField = (props: NumberFieldProps) => {
  const record = useRecordContext(props);
  if (!record || !props.source) {
    return null;
  }
  const upd = record?.updatedAt;
  const left = record?.server?.ramUsage / 1024;
  const right = record?.server?.ramTotal / 1024;
  const ram = (parseInt(record?.server?.ramUsage) / parseInt(record?.server?.ramTotal)) * 100;
  const conditionRam = ram > 80;
  const value = `${(record?.server?.ramUsage / 1024 / 1024).toFixed(2)} / ${(
    record?.server?.ramTotal /
    1024 /
    1024
  ).toFixed(2)}`;
  const percent = `${ram.toFixed(2)}%`;

  const scannedHeight = record?.scannedHeight;
  const nodeHeight = record?.nodeHeight;
  const conditionBacklog = nodeHeight - scannedHeight > 2;

  const currentTime = new Date().getTime();
  const valueBackup = record.lastBackup * 1;
  const valueReplication = record.lastReplication * 1;

  const pingPhp = record?.pingFromExchange;
  const pingPhpCondition = record?.pingFromExchange;

  const pingNode = record?.pingOwnNode;

  let backup = (currentTime - valueBackup) / 1000 / 60;
  let backupCondition = backup > 60;
  if (valueBackup === 0) {
    backupCondition = false;
  }

  let replication = (currentTime - valueReplication) / 1000 / 60;
  let replicationCondition = replication > 60;
  if (valueReplication === 0) {
    replicationCondition = false;
  }

  const memoryHome = (record?.server?.homeMemoryUsage / record?.server?.homeMemoryTotal) * 100;
  const memoryHomeCondition = memoryHome > 80;
  const memoryHomePercent = `${memoryHome.toFixed()}%`;
  const status = record.status;
  const condition =
    backupCondition ||
    replicationCondition ||
    !pingPhpCondition ||
    conditionBacklog ||
    conditionRam ||
    memoryHomeCondition;

  const title = {
    ram: percent,
    toString() {
      return `
                updated: ${upd};
                * backup: ${backupCondition} ; 
                * replica: ${replicationCondition} ; 
                * ram: ${conditionRam};
                * blocks: ${conditionBacklog};
                * pingPhp: ${!pingPhpCondition};
                * memoryHome: ${memoryHomeCondition};
                * status: ${status};
            `;
    },
  };
  return condition ? (
    <FunctionField
      title={title.toString()}
      sx={{ color: 'red' }}
      render={(record: any) => 'UnHealth'}
    />
  ) : (
    <FunctionField
      title={title.toString()}
      sx={{ color: 'green' }}
      render={(record: any) => 'Health'}
    />
  );
};

ColoredHealthField.defaultProps = NumberField.defaultProps;

export default ColoredHealthField;
