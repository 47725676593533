import CallToActionIcon from '@mui/icons-material/CallToAction';

import RbacResourceList from './RbacResourceList';
import RbacResourceCreate from './RbacResourceCreate';
import RbacResourceEdit from './RbacResourceEdit';

export default {
  list: RbacResourceList,
  icon: CallToActionIcon,
  create: RbacResourceCreate,
  edit: RbacResourceEdit,
};
