import ExchangesList from './BlockchainTypeList';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import ExchangeCreate from './BlockchainTypeCreate';
import BlockchainTypeCreate from './BlockchainTypeCreate';
import BlockchainTypeList from './BlockchainTypeList';
import BlockchainTypeEdit from './BlockchainTypeEdit';

export default {
  list: BlockchainTypeList,
  create: BlockchainTypeCreate,
  icon: FormatBoldIcon,
  edit: BlockchainTypeEdit,
};
