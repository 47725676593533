import {
  Box,
  Table,
  TableCell,
  TableBody,
  TableHead,
  TableRow,
  TextField,
  Button,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { isP } from '../components/helpers';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { DateRange } from '@mui/icons-material';
import { DateField } from '@mui/x-date-pickers/DateField/DateField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useLocation, useRoutes } from 'react-router-dom';
import { useRedirect } from 'react-admin';

interface ICostsAnalyticsExtendedItem {
  fundId: number;
  quantity: string;
  amount: string;
  totalIn: string;
  totalOut: string;
  total: string;
}

export interface ICostsAnalyticsItem {
  fundId: number;
  quantity: string;
  amount: string;
  totalIn: string;
  totalOut: string;
  total: string;
  extended: ICostsAnalyticsExtendedItem | [];
}

const getCostsAnalytics = async (date: string): Promise<ICostsAnalyticsItem[] | null> => {
  const url = process.env.REACT_APP_API_URL;
  const filter = date ? { date } : {};

  try {
    const response = await fetch(`${url}/costs/analytics?filter=${JSON.stringify(filter)}`, {
      credentials: 'include',
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return response.json();
  } catch (e) {
    console.log(e);
    return null;
  }
};

const getProjects = async () => {
  try {
    const projects = await fetch(`${process.env.REACT_APP_API_URL}/fund?filter=[]`, {
      method: 'GET',
      credentials: 'include',
      redirect: 'follow',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const data = projects.json();
    return data;
  } catch (error) {
    return [];
  }
};

export const CostsAnalytics = () => {
  const [analytics, setAnalytics] = useState<ICostsAnalyticsItem[] | null>(null);
  const [date, setDate] = useState<string>(new Date().toISOString());
  const [projects, setProjects] = useState<any>([]);
  const [selectedFund, setSelectedFund] = useState<any>(null);
  console.log('analytics', analytics);
  const redirect = useRedirect();

  console.log('location', location);
  useEffect(() => {
    // get projects
    if (isP('projects')) {
      getProjects().then(data => setProjects(data));
    }
  }, []);

  console.log('projects projects', projects);
  console.log('analytics analytics', analytics);
  // @ts-ignore
  useEffect(async () => {
    getCostsAnalytics(date).then(data => {
      setAnalytics(data);
    });
  }, [date]);

  const handleChangeDate = (e: any) => {
    setDate(e);
  };

  function redirectToCreateFondPage() {
    redirect('/costsAnalytics/create');
  }

  function setSelectedFundHandler(fund: any) {
    console.log('fund', fund);
    if (selectedFund !== null && selectedFund?.fundId === fund.fundId) {
      setSelectedFund(null);
      return;
    }
    setSelectedFund(fund);
  }

  return (
    <>
      <Box>
        <Box
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: '20px',
          }}
          className='yaer_picker'
        >
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              onChange={handleChangeDate}
              value={date}
              views={['year', 'month']}
              label='Set year please'
              renderInput={(props: any) => <TextField {...props} />}
            />
          </LocalizationProvider>
          <Button onClick={redirectToCreateFondPage}>Create</Button>
        </Box>
        <Table>
          <TableHead>
            <TableCell>Project Id</TableCell>
            <TableCell>Quantity</TableCell>
            <TableCell>Monthly IN</TableCell>
            <TableCell>Monthly Out</TableCell>
            <TableCell>Total</TableCell>
          </TableHead>
          <TableBody>
            {analytics?.map(item => (
              <>
                <TableRow
                  style={{
                    // @ts-ignore
                    cursor: item?.extended !==null && item?.extended?.length > 0 ? 'pointer' : 'default',
                  }}
                  key={item.fundId}
                  onClick={() => setSelectedFundHandler({ fundId: item.fundId, extended: item?.extended })}>
                  <TableCell>
                    {projects.find((project: any) => project.id === item.fundId)?.name}
                  </TableCell>
                  <TableCell>{item.quantity}</TableCell>
                  <TableCell>{item.totalIn}</TableCell>
                  <TableCell>{item.totalOut}</TableCell>
                  <TableCell>{item.total}</TableCell>
                </TableRow>
                {
                  selectedFund !== null && selectedFund?.extended?.length > 0 && selectedFund?.fundId === item?.fundId && (
                    selectedFund.extended.map(
                      (extendItem: any) => (
                        <TableRow
                          style={{
                            backgroundColor: '#494949',
                            position: 'relative',
                          }}
                          key={`selectedFund ${extendItem?.fundId}`}>
                          <TableCell>
                            <div
                              style={{
                                position: 'absolute',
                                top: '-53px',
                                left: 0,
                                width: '3px',
                                height: '200%',
                                backgroundColor: 'white',
                              }}
                            />
                            {extendItem.currency}
                          </TableCell>
                          <TableCell>{extendItem?.quantity}</TableCell>
                          <TableCell>{extendItem?.totalIn}</TableCell>
                          <TableCell>{extendItem?.totalOut}</TableCell>
                          <TableCell>{extendItem?.total}</TableCell>
                        </TableRow>
                      ),
                    )
                  )
                }
              </>
            ))}
          </TableBody>
        </Table>
      </Box>
    </>
  );
};
