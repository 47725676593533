import { Theme, useMediaQuery } from '@mui/material';
import {
  CreateButton,
  Datagrid,
  EditButton,
  List,
  SearchInput,
  TextField,
  TopToolbar,
} from 'react-admin';
import PropsListAside from './PropsListAside';

const propsFilters = [<SearchInput source="q" alwaysOn />];

const EPropsList = () => {
  const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('md'));
  return (
    <List
      aside={<PropsListAside />}
      filters={isSmall ? propsFilters : undefined}
      actions={<ListActions />}
      sort={{ field: 'id', order: 'DESC' }}
    >
      <Datagrid rowClick="show">
        <TextField source="name" />
      </Datagrid>
    </List>
  );
};

const ListActions = ({ isSmall }: any) => (
  <TopToolbar sx={{ minHeight: { sm: 56 } }}>
    <CreateButton />
    <EditButton />
  </TopToolbar>
);

export default EPropsList;
