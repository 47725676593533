import * as React from 'react';
import { Create, FormTab, TabbedForm, TextInput, required } from 'react-admin';

const RbacResourceCreate = () => (
  <Create>
    <TabbedForm>
      <FormTab label="resources.rbac_resources.tabs.main" sx={{ maxWidth: '40em' }}>
        <TextInput autoFocus source="name" fullWidth validate={required()} />
      </FormTab>
    </TabbedForm>
  </Create>
);
export default RbacResourceCreate;
