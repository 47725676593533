import {
  ArrayInput,
  AutocompleteInput,
  Create,
  DateInput,
  FormTab,
  ReferenceArrayInput,
  ReferenceInput,
  required,
  SelectArrayInput,
  SelectInput,
  SimpleFormIterator,
  TabbedForm,
  TextInput,
} from 'react-admin';
import * as React from 'react';
import { dateFormatter, dateParser } from '../utils/helpers';

const EmployeeCreate = () => {
  return (
    <Create>
      <TabbedForm
        defaultValues={{
          endDate: null,
          startDate: null,
        }}
      >
        <FormTab label="Common" sx={{ maxWidth: '40em' }}>
          <TextInput autoFocus source="firstname" fullWidth validate={required()} />
          <TextInput source="lastname" fullWidth validate={required()} />
          <TextInput source="futhername" fullWidth />
          <TextInput source="nickname" fullWidth />
          <DateInput
            required={false}
            defaultValue={null}
            format={dateFormatter}
            parse={dateParser}
            source="birthDate"
          />
          <SelectInput
            source="type"
            choices={[
              { id: 'candidate', name: 'Candidate' },
              {
                id: 'employee',
                name: 'Employee',
              },
              { id: 'remote', name: 'Remote' },
            ]}
          />
          <SelectInput
            source="gender"
            choices={[
              { id: 'male', name: 'Male' },
              { id: 'female', name: 'Female' },
            ]}
          />
        </FormTab>
        <FormTab label="Contact" sx={{ maxWidth: '50em' }}>
          <ArrayInput source="properties" label="Contacts">
            <SimpleFormIterator>
              <ReferenceInput
                label="Position"
                source="propertyTypeId"
                reference="properties"
                sort={{ field: 'name', order: 'ASC' }}
                alwaysOn
              >
                <SelectInput optionText="name" optionValue="id" />
              </ReferenceInput>
              <TextInput source="value" />
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
        <FormTab label="Salary" sx={{ maxWidth: '40em' }}>
          <ArrayInput source="salaries" label="Salaries">
            <SimpleFormIterator>
              <SelectInput
                source="currency"
                choices={[
                  { id: 'BTC', name: 'BTC' },
                  { id: 'USDT', name: 'USDT' },
                  { id: 'EUR', name: 'EUR' },
                  { id: 'UAH', name: 'UAH' },
                  { id: 'USD', name: 'USD' },
                  { id: 'AED', name: 'AED' },
                ]}
              />

              <TextInput source="amount" />
              <DateInput
                required={false}
                defaultValue={null}
                format={dateFormatter}
                parse={dateParser}
                source="date"
              />
            </SimpleFormIterator>
          </ArrayInput>

          <SelectInput
            source="paymentType"
            choices={[
              { id: 'Cash', name: 'Cash' },
              { id: 'Card', name: 'Card' },
              { id: 'Crypto', name: 'Crypto' },
            ]}
          />
          <TextInput source="paymentDetails" fullWidth />
          <DateInput
            required={false}
            defaultValue={null}
            format={dateFormatter}
            parse={dateParser}
            source="startDate"
          />

          <DateInput
            required={false}
            defaultValue={null}
            format={dateFormatter}
            parse={dateParser}
            source="endDate"
          />
        </FormTab>
        <FormTab label="Quality" sx={{ maxWidth: '40em' }}>
          <ReferenceInput
            label="Position"
            source="positionId"
            reference="position"
            flex={1}
            perPage={1000}
          >
            <AutocompleteInput optionText="name" optionValue="id" defaultValue={''} />
          </ReferenceInput>

          <ReferenceArrayInput source="skillIds" reference="skills" flex={1} perPage={1000}>
            <SelectArrayInput optionText="name" />
          </ReferenceArrayInput>

          <ArrayInput source="bonuses" label="Projects">
            <SimpleFormIterator>
              <ReferenceInput label="Position" source="type" reference="projects" alwaysOn>
                <SelectInput optionText="project" optionValue="project" />
              </ReferenceInput>
              <TextInput source="value" />
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
      </TabbedForm>
    </Create>
  );
};

export default EmployeeCreate;
