import * as React from 'react';
import {
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

import { TableCellRight } from '../employees/TableCellRight';
import { useParams } from 'react-router';
import { useEffect, useState } from 'react';

const Spacer = () => <Box m={1}>&nbsp;</Box>;

const getProjectAnalitics = async (id: string) => {
  const analitic = await fetch(
    `${process.env.REACT_APP_API_URL}/projects/analitics/${Number(id)}`,
    {
      method: 'GET',
      credentials: 'include',
      redirect: 'follow',
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );
  const data = analitic.json();
  return data;
};
const getOneProject = async (id: string) => {
  const project = await fetch(`${process.env.REACT_APP_API_URL}/projects/${id}`, {
    method: 'GET',
    credentials: 'include',
    redirect: 'follow',
    headers: {
      'Content-Type': 'application/json',
    },
  });
  const data = project.json();
  return data;
};

const getProjectsEmployees = async (id: string, name: string) => {
  const projects = await fetch(
    `${process.env.REACT_APP_API_URL}/projects/?filter={"id": [${Number(id)}], "q": "${String(
      name,
    )}"}&sort=["id", "DESC"]&range=[0,1000]`,
    {
      method: 'GET',
      credentials: 'include',
      redirect: 'follow',
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );
  const data = projects.json();
  return data;
};
const ProjectsList = () => {
  const { id } = useParams();
  const [project, setProject] = useState<any>(null);
  const [employees, setEmployees] = useState<any>(null);
  const [projectAnalitics, setProjectAnalitics] = useState<any>([]);
  console.log('employees',employees)
  console.log('projectAnalitics',projectAnalitics)
  // @ts-ignore
  useEffect(async () => {
    const analitic = await getProjectAnalitics(id as string);
    setProjectAnalitics(analitic?.[0] ?? []);
    const project = await getOneProject(id as string);
    setProject(project);
    const employees = await getProjectsEmployees(id as string, project.name);
    setEmployees(employees[0].employees);
  }, [id]);
  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Project {project?.name ?? id}
      </Typography>

      <Spacer />
      <Table
        style={{
          width: '100%',
          marginTop: '20px',
          whiteSpace: 'nowrap',
          overflow: 'scroll',
        }}
      >
        {projectAnalitics && (
          <TableHead>
            <TableRow>
              <TableCell>TTL USD</TableCell>
              <TableCellRight>TTL UAH</TableCellRight>
              <TableCellRight>TTL USDT</TableCellRight>
              <TableCellRight>TTL EUR</TableCellRight>
              <TableCellRight>TTL BTC</TableCellRight>
              <TableCellRight>Total(USDT)</TableCellRight>
            </TableRow>
          </TableHead>
        )}
        {projectAnalitics && (
          <TableBody>
            <TableRow>
              <TableCell>{projectAnalitics?.analitics?.costs.USD ?? '0'}</TableCell>
              <TableCellRight>{projectAnalitics?.analitics?.costs.UAH ?? '0'}</TableCellRight>
              <TableCellRight>{projectAnalitics?.analitics?.costs.USDT ?? '0'}</TableCellRight>
              <TableCellRight>{projectAnalitics?.analitics?.costs.EUR ?? '0'}</TableCellRight>
              <TableCellRight>{projectAnalitics?.analitics?.costs.BTC ?? '0'}</TableCellRight>
              <TableCellRight>{projectAnalitics?.analitics?.costs.total ?? '0'}</TableCellRight>
            </TableRow>
          </TableBody>
        )}
      </Table>

      <Spacer />
      <Table
        style={{
          width: '100%',
          marginTop: '20px',
          whiteSpace: 'nowrap',
          overflow: 'scroll',
        }}
      >
        <TableHead>
          {employees && employees?.length ? (
            <TableRow>
              <TableCell>ФИО</TableCell>
              <TableCellRight>Позиция</TableCellRight>
              <TableCellRight>Статус</TableCellRight>
              <TableCellRight>Ставка</TableCellRight>
              <TableCellRight>Нагрузка</TableCellRight>
              <TableCellRight>Валюта/Тип</TableCellRight>
            </TableRow>
          ) : null}
        </TableHead>
        <TableBody>
          {employees && employees?.length
            ? employees.map((item: any) => {
                return (
                  <TableRow key={item.id}>
                    <TableCell>
                      {item?.firstname} {item?.lastname}
                    </TableCell>
                    <TableCellRight>{item?.position?.name}</TableCellRight>
                    <TableCellRight>{item?.status}</TableCellRight>
                    <TableCellRight>{item.salary}</TableCellRight>
                    <TableCellRight>
                      {item?.projects.find((p: any) => p.projectId === Number(id))?.load} %
                    </TableCellRight>
                    <TableCellRight>
                      {item.currency}/{item.paymentType}
                    </TableCellRight>
                  </TableRow>
                );
              })
            : null}
        </TableBody>
      </Table>
      {!employees || !projectAnalitics ? <CircularProgress /> : null}
      <Spacer />
    </Box>
  );
};
export default ProjectsList;
