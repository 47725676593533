import { Theme, useMediaQuery } from '@mui/material';
import { useEffect, useState } from 'react';
import {
  CreateButton,
  Datagrid,
  EditButton,
  FunctionField,
  List,
  Loading,
  Pagination,
  SearchInput,
  TextField,
  TopToolbar,
} from 'react-admin';
import ColoredBacklogField from './ColoredBacklogField';
import ColoredHealthField from './ColoredHealthField';
import ColoredMemoryHomeFieldNtf from './ColoredMemoryHomeFieldNtf';
import ColoredRamFieldNtf from './ColoredRamFieldNtf';
import PingPhpFieldNtf from './PingPhpField';
import * as React from 'react';

const notifiersFilters = [<SearchInput source="q" alwaysOn />];

const url = `${process.env.REACT_APP_API_URL}`;

const request = new Request(`${url}/exchanges`, {
  method: 'GET',
  credentials: 'include',
  redirect: 'follow',
});
const PostPagination = () => <Pagination rowsPerPageOptions={[50, 100]} />;

const NotifierList = () => {
  const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('md'));
  const [exchanges, setExchanges] = useState([]);

  useEffect(() => {
    (async () => {
      const response = await fetch(request);
      const res = await response.json();
      setExchanges(res);
    })();
  }, [setExchanges]);
  if (!exchanges) return <Loading />;
  return (
    <>
      <List
        pagination={<PostPagination />}
        filters={isSmall ? notifiersFilters : undefined}
        actions={<ListActions />}
        sort={{ field: 'id', order: 'DESC' }}
      >
        <Datagrid rowClick="edit">
          <TextField source="id" />

          <FunctionField
            label="Notifier"
            render={(record: any) => record?.blockchain?.coreCoinSymbol}
          />
          <FunctionField
            label="Type"
            render={(record: any) => record?.blockchain?.blockchainType?.name}
          />
          <FunctionField label="Exchange" render={(record: any) => record?.exchange?.name} />
          <FunctionField
            label="Provider"
            render={(record: any) => record?.server?.provider?.name}
          />
          <FunctionField
            label="Ping node"
            render={(record: any) => {
              if (Number.isNaN(+(record?.pingOwnNode ?? undefined))) return 'N/A';
              const result = Math.floor(record.pingOwnNode / 1000);
              return `${result} ms`;
            }}
          />
          <FunctionField
            label="Response"
            render={(record: any) => {
              if (Number.isNaN(+(record?.responseTimeOwnNode ?? undefined))) return 'N/A';
              const result = record.responseTimeOwnNode;
              return `${result} ms`;
            }}
          />
          <ColoredBacklogField sortable={false} label="Bacdklog" source="pingFromExchange" />

          <PingPhpFieldNtf sortable={false} label="Ping PHP" source="pingFromExchange" />

          <FunctionField
            label="Health Check"
            render={(record: any) => {
              // console.log('record',record);
              return record?.status ? 'health' : 'unhealth';
              // const currentTime = new Date().getTime();
              // const valueTime = record.lastReplication * 1;
              // let result = (currentTime - valueTime) / 1000 / 60;
              // if (result >= 60) {
              //   return Math.floor(result / 60).toString() + ' h ago';
              // }
              // return Math.ceil(result).toString() + ' min ago';
            }}
          />

          <FunctionField
            label="Last backup"
            render={(record: any) => {
              if (!record.lastBackup) {
                return 'N/A';
              }
              const currentTime = new Date().getTime();
              const valueTime = record.lastBackup * 1;
              let result = (currentTime - valueTime) / 1000 / 60;
              if (result >= 60) {
                return Math.floor(result / 60).toString() + ' h ago';
              }
              return Math.ceil(result).toString() + ' min ago';
            }}
          />
          <ColoredRamFieldNtf sortable={false} label="RAM" source="lastBackup" />

          <ColoredMemoryHomeFieldNtf sortable={false} label="Disc" source="lastBackup" />
          <ColoredHealthField label="Status" source="lastBackup" />
        </Datagrid>
      </List>
    </>
  );
};

const ListActions = ({ isSmall }: any) => {
  return (
    <TopToolbar sx={{ minHeight: { sm: 56 } }}>
      <CreateButton />
      <EditButton />
    </TopToolbar>
  );
};

export default NotifierList;
