import { Theme, useMediaQuery } from '@mui/material';
import {
  CreateButton,
  Datagrid,
  EditButton,
  List,
  SearchInput,
  TextField,
  TopToolbar,
  useTranslate,
} from 'react-admin';
import RbacPermissionListAside from './RbacPermissionListAside';

const filters = [<SearchInput source="q" alwaysOn />];

const RbacPermissionList = () => {
  const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('md'));
  return (
    <List
      aside={<RbacPermissionListAside data={[]} />}
      filters={isSmall ? filters : undefined}
      actions={<ListActions />}
      sort={{ field: 'id', order: 'DESC' }}
    >
      <Datagrid rowClick="edit">
        <TextField source="id" />
        <TextField source="resource.name" />
        <TextField source="name" />
      </Datagrid>
    </List>
  );
};

const ListActions = ({ isSmall }: any) => (
  <TopToolbar sx={{ minHeight: { sm: 56 } }}>
    <CreateButton />
    <EditButton />
  </TopToolbar>
);

export default RbacPermissionList;
