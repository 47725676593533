import {
  Edit,
  FormTab,
  ReferenceInput,
  required,
  SelectInput,
  TabbedForm,
  TextInput,
} from 'react-admin';

const ExchangeInstanceEdit = () => (
  <Edit>
    <TabbedForm>
      <FormTab label="D" sx={{ maxWidth: '40em' }}>
        <TextInput autoFocus source="url" fullWidth validate={required()} />
        <ReferenceInput
          label="Exchange"
          source="exchangeId"
          reference="exchanges"
          sort={{ field: 'name', order: 'ASC' }}
          alwaysOn
        >
          <SelectInput optionText="name" optionValue="id" defaultValue={''} validate={required()} />
        </ReferenceInput>
      </FormTab>
    </TabbedForm>
  </Edit>
);

export default ExchangeInstanceEdit;
