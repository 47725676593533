import * as React from 'react';
import { FormTab, TabbedForm, TextInput, required, Edit, SelectInput } from 'react-admin';

const RbacResourceEdit = () => (
  <Edit>
    <TabbedForm>
      <FormTab label="resources.rbac_resources.tabs.main" sx={{ maxWidth: '40em' }}>
        <TextInput autoFocus source="name" fullWidth validate={required()} />

        <SelectInput
          source="group"
          choices={[
            { id: 'system', name: 'System' },
            { id: 'employees', name: 'Employees' },
            { id: 'users', name: 'Users' },
            { id: 'blockchain', name: 'Blockchain' },
          ]}
        />
      </FormTab>
    </TabbedForm>
  </Edit>
);

export default RbacResourceEdit;
