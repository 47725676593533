import * as React from 'react';
import { SxProps, Typography } from '@mui/material';
import { memo } from 'react';
import { FieldProps, RaRecord, useRecordContext } from 'react-admin';
import { Customer } from '../types';
import AvatarEmployeeField from './AvatarField';

interface Props extends FieldProps<Customer> {
  size?: string;
  fontSize?: string;
  sx?: SxProps;
}

export interface IEmployee extends RaRecord {
  firstname: string;
  lastname: string;
  nickname: string;
  properties: IEmployeeProps[];
  files: any[];
  skills: any[];
}

export interface IEmployeeProps {
  propertyTypeId: number;
  value: string;
  propertyTypeName: string;
}

const FullNameEmployeeField = (props: Props) => {
  const { size, fontSize } = props;
  const record = useRecordContext<IEmployee>();
  return record ? (
    <Typography
      variant="body2"
      display="flex"
      flexWrap="nowrap"
      alignItems="center"
      component="div"
      fontSize={fontSize}
      sx={props.sx}
    >
      <AvatarEmployeeField
        record={record}
        size={size}
        sx={{
          mr: 1,
          mt: -0.5,
          mb: -0.5,
        }}
      />
      {record.firstname} {record.lastname}
    </Typography>
  ) : null;
};

FullNameEmployeeField.defaultProps = {
  source: 'last_name',
  label: 'resources.customers.fields.name',
};

export default memo<Props>(FullNameEmployeeField);
