import { Edit, required, SimpleForm, TextInput } from 'react-admin';
import FullNameField from '../visitors/FullNameField';

const PositionEdit = () => (
  <Edit title={<Title />}>
    <SimpleForm>
      <TextInput autoFocus source="name" fullWidth validate={required()} />
    </SimpleForm>
  </Edit>
);

const Title = () => <FullNameField size="32" sx={{ margin: '5px 0' }} />;
export default PositionEdit;
