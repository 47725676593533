import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import NotifierCreate from './NotifierCreate';
import NotifierEdit from './NotifierEdit';
import NotifierList from './NotifiersList';

export default {
  list: NotifierList,
  icon: NotificationsActiveIcon,
  create: NotifierCreate,
  edit: NotifierEdit,
};
