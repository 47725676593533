import * as React from 'react';

const Logo = () => (
  <div style={{ display: 'flex', alignItems: 'center' }}>
    <img
      src="https://prof-it.bz/assets/images/faicon/android-icon-192x192.png"
      width="24"
      style={{ margin: '0 8px' }}
    />
    <div style={{ color: '#3498db' }}>
      {' '}
      <strong>PROF-IT </strong>CRM
    </div>
  </div>
);

export default Logo;
