import SwitchAccessShortcutIcon from '@mui/icons-material/SwitchAccessShortcut';
import RbacPermissionCreate from './RbacPermissionCreate';

import RbacPermissionList from './RbacPermissionList';

export default {
  list: RbacPermissionList,
  icon: SwitchAccessShortcutIcon,
  create: RbacPermissionCreate,
};
