import * as React from 'react';
import { Box, Card, CardContent, Typography } from '@mui/material';
import { FilterLiveSearch } from 'react-admin';

const PropsListAside = () => (
  <Box
    sx={{
      display: {
        xs: 'none',
        md: 'block',
      },
      order: -1,
      flex: '0 0 15em',
      mr: 2,
      mt: 8,
      alignSelf: 'flex-start',
    }}
  >
    <Card>
      <CardContent sx={{ pt: 1 }}>
        <FilterLiveSearch />
      </CardContent>
    </Card>
    <Typography>Employee properties. For example, contact type: telegram, viber, etc</Typography>
  </Box>
);

export default PropsListAside;
