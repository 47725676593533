import moment from 'moment';

export const dateFormatter = (v: any) => {
  if (!(v instanceof Date) || Number.isNaN(v)) {
    if (!v?.length) return null;
    return null;
  }
  const pad = '00';
  const yy = v.getUTCFullYear().toString();
  const mm = (v.getUTCMonth() + 1).toString();
  const dd = v.getUTCDate().toString();
  return `${yy}-${(pad + mm).slice(-2)}-${(pad + dd).slice(-2)}`;
};

export const dateParser = (v: any) => {
  if (typeof v !== 'string') return null;
  if (typeof v !== 'string') return null;
  const newDate = new Date(v);
  if (
    newDate.getUTCFullYear() &&
    String(newDate.getUTCFullYear())?.length === 4 &&
    newDate.getUTCMonth() &&
    newDate.getUTCDate()
  ) {
    const UTCdate = new Date(
      Date.UTC(newDate.getUTCFullYear(), newDate.getUTCMonth(), newDate.getUTCDate()),
    );
    return UTCdate;
  }
  return v;
};

export const dateFormat = (value: any) => {
  if (value) {
    if (typeof value !== 'string') return null;
    const newDate = new Date(value);
    if (
      newDate.getUTCFullYear() &&
      String(newDate.getUTCFullYear())?.length === 4 &&
      newDate.getUTCMonth() &&
      newDate.getUTCDate()
    ) {
      const UTCdate = new Date(
        Date.UTC(newDate.getUTCFullYear(), newDate.getUTCMonth(), newDate.getUTCDate()),
      );
      return `${UTCdate.toLocaleDateString()}`;
    }
    return moment(String(value)).format('DD/MM/YYYY hh:mm');
  } else {
    return '';
  }
};
