import {
  CreateButton,
  Datagrid,
  EditButton,
  FunctionField,
  List,
  TextField,
  TopToolbar,
} from 'react-admin';

const ExchangeInstanceList = () => (
  <List actions={<ListActions />} sort={{ field: 'id', order: 'DESC' }}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <FunctionField label="Exchange" render={(record: any) => record?.exchange?.name} />
      <TextField source="url" />
    </Datagrid>
  </List>
);

const ListActions = ({ isSmall }: any) => (
  <TopToolbar sx={{ minHeight: { sm: 56 } }}>
    <CreateButton />
    <EditButton />
  </TopToolbar>
);

export default ExchangeInstanceList;
