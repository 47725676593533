import * as React from 'react';
import { useRecordContext, NumberField, NumberFieldProps, FunctionField } from 'react-admin';

const ColoredBacklogField = (props: NumberFieldProps) => {
  const record = useRecordContext(props);
  if (!record || !props.source) {
    return null;
  }

  const scannedHeight = record?.scannedHeight ?? undefined;
  const nodeHeight = record?.nodeHeight ?? undefined;

  const condition = nodeHeight - scannedHeight > 3;
  const title = `${record?.scannedHeight} / ${record?.nodeHeight}`;
  const value = nodeHeight - scannedHeight;

  return condition ? (
    <FunctionField
      title={title}
      sx={{ color: 'red' }}
      render={(record: any) => (Number.isNaN(value) ? 'N/A' : value)}
    />
  ) : (
    <FunctionField title={title} render={(record: any) => (Number.isNaN(value) ? 'N/A' : value)} />
  );
};

ColoredBacklogField.defaultProps = NumberField.defaultProps;

export default ColoredBacklogField;
