import {
  Create,
  FormTab,
  NumberInput,
  ReferenceInput,
  required,
  SelectInput,
  TabbedForm,
  TextInput,
  BooleanInput,
  AutocompleteInput,
} from 'react-admin';

const transform = (data: any) => {
  return {
    ...data,
    scannedHeight: data?.scannedHeight ? String(data?.scannedHeight) : null,
    nodeHeight: data?.nodeHeight ? String(data?.nodeHeight) : null,
    queueHeight: data?.queueHeight ? String(data?.queueHeight) : null,
    apiKey: data?.apiKey ? String(data?.apiKey) : '',
    lastReplication: data?.lastReplication ? Number(data?.lastReplication) : null,
    lastBackup: data?.lastBackup ? Number(data?.lastBackup) : null,
    pingOwnNode: data?.pingOwnNode ? Number(data?.pingOwnNode) : null,
    responseTimeOwnNode: data?.responseTimeOwnNode ? Number(data?.responseTimeOwnNode) : null,
    version: data?.version ? String(data?.version) : null,
    exchangePingCustomSymbol: data?.exchangePingCustomSymbol
      ? String(data?.exchangePingCustomSymbol)
      : '',
    backupCustomSymbol: data?.backupCustomSymbol ? String(data?.backupCustomSymbol) : '',
  };
};

const NotifierCreate = () => (
  <Create transform={transform}>
    <TabbedForm>
      <FormTab label="resources.notifiers.tabs.main" sx={{ maxWidth: '40em' }}>
        <ReferenceInput
          label="Blockchain"
          source="blockchainId"
          reference="blockchains"
          sort={{ field: 'name', order: 'ASC' }}
          alwaysOn
          pagination={false}
          perPage={1000}
        >
          <AutocompleteInput
            optionText="name"
            optionValue="id"
            defaultValue={''}
            validate={required()}
          />
        </ReferenceInput>

        <ReferenceInput
          label="Exchange"
          source="exchangeId"
          reference="exchanges"
          sort={{ field: 'name', order: 'ASC' }}
          alwaysOn
          pagination={false}
          perPage={1000}
        >
          <AutocompleteInput
            optionText="name"
            optionValue="id"
            defaultValue={''}
            validate={required()}
          />
        </ReferenceInput>

        <ReferenceInput
          label="Server"
          source="serverId"
          reference="servers"
          sort={{ field: 'name', order: 'ASC' }}
          alwaysOn
          pagination={false}
          perPage={1000}
        >
          <AutocompleteInput
            optionText="name"
            optionValue="id"
            defaultValue={''}
            validate={required()}
          />
        </ReferenceInput>
        <BooleanInput source="status" />
        <TextInput source="scannedHeight" />
        <TextInput source="nodeHeight" />
        <TextInput source="queueHeight" />
        <TextInput validate={required()} source="url" fullWidth />
        <TextInput source="apiKey" />
        <TextInput source="exchangePingCustomSymbol" defaultValue={''} format={v => v || ''} />
        <TextInput source="backupCustomSymbol" defaultValue={''} format={v => v || ''} />
        <NumberInput source="lastReplication" />
        <NumberInput source="lastBackup" />
        <NumberInput source="pingOwnNode" />
        <NumberInput source="responseTimeOwnNode" />
        <TextInput source="version" />
      </FormTab>
    </TabbedForm>
  </Create>
);

export default NotifierCreate;
