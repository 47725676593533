import StorageIcon from '@mui/icons-material/Storage';
import ServerCreate from './ServerCreate';
import ServerEdit from './ServerEdit';
import ServerList from './ServerList';

export default {
  list: ServerList,
  create: ServerCreate,
  icon: StorageIcon,
  edit: ServerEdit,
};
