import * as React from 'react';
import { Admin, CustomRoutes, fetchUtils, Resource } from 'react-admin';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import { Route } from 'react-router';
import authProvider from './authProvider';
import { Login, Layout } from './layout';
import { Dashboard } from './dashboard';
import englishMessages from './i18n/en';
import { darkTheme } from './layout/themes';
import visitors from './visitors';
import orders from './orders';
import products from './products';
import invoices from './invoices';
import categories from './categories';
import reviews from './reviews';
import Configuration from './configuration/Configuration';
import Segments from './segments/Segments';
import employees from './employees';
import payments from './payments';
import users from './users';
import simpleRestProvider from 'ra-data-simple-rest';
import files from './files';
import projects from './projects';
import skills from './skills';
import positions from './positions';
import employee_props from './employee_props';
import exchanges from './exchange/exchanges';
import blockchains from './exchange/blockchains';
import blockchain_types from './exchange/blockchain_types';
import costs from './costs';
import server_providers from './exchange/server_providers';
import notifiers from './exchange/notifiers';
import antnodes from './exchange/antnodes';
import exchange_instances from './exchange/exchange_instances';
import servers from './exchange/servers';
import resources from './rbac/resources';
import grants from './rbac/grants';
import permissions_rbac from './rbac/permissions_rbac';
import ping from './exchange/ping';
import versions from './exchange/versions';
import ProjectsList from './projectList/ProjectList';
import ProjectItem from './projectList/ProjectItem';
import NotifireAnalistic from './exchange/notifiers/NotifireAnalistic';
import OutstafTable from './employee_outstaff/OutstafTable';
import { isP } from './components/helpers';
import { ExchangePairs } from './exchange/exchange_pairs/ExchangePairs';
import liquidity_bots from './liquidity_bots';
import bot_trades from './bot_trades';
import BotTradesList from './bot_trades/BotTradesList';
import { BotTradesStats } from './bot_trades/BotTradesStats';
import { CostsAnalytics } from './costs/CostsAnalytics';
import { CostsAnalyticsCreate } from './costs/CostsAnalyticsCreate';
import { useEffect } from 'react';

const i18nProvider = polyglotI18nProvider(locale => {
  if (locale === 'fr') {
    return import('./i18n/fr').then(messages => messages.default);
  }

  return englishMessages;
}, 'en');

const httpClient = (url: any, options: any = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' });
  }

  options.credentials = 'include';

  return fetchUtils.fetchJson(url, options);
};

const dataProvider = simpleRestProvider(`${process.env.REACT_APP_API_URL}`, httpClient);

const crmProvider = {
  ...dataProvider,
  banUser: async (userId: number) => {
    const ban = await fetch(`/api/user/${userId}/ban`, { method: 'POST' });
    const res = ban.json();
    return res;
  },
  update: async (resource: any, params: any) => {
    if (resource === 'users' && params?.data?.password) {
      try {
        const result: any = await new Promise((resolve, reject) => {
          httpClient(`${process.env.REACT_APP_API_URL}/users/${params?.data?.id}/password`, {
            method: 'PATCH',
            Headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: JSON.stringify({
              old_password: params?.data?.old_password,
              password: params?.data?.password,
            }),
          })
            .then(result => {
              resolve(JSON.parse(result.body));
            })
            .catch(e => {
              reject(e);
            });
        });
      } catch (e: any) {
        console.error('password change error');
        throw new Error(e.message);
      }

      return dataProvider.update(resource, params);
    }
    if (resource !== 'employees' || !params.data.file) {
      return dataProvider.update(resource, params);
    }
    const meta = { employee: params.data.id, category: '' };
    let formData = new FormData();

    formData.append('paramOne', params.data.paramOne);
    formData.append('file', params.data.file.rawFile);
    if (params.data.file.category) {
      formData.append('category', params.data.file.category);
      meta.category = params.data.file.category;
    }
    formData.append('meta', JSON.stringify(meta));

    if (params.data.file.rawFile) {
      try {
        const file: any = await new Promise((resolve, reject) => {
          httpClient(`${process.env.REACT_APP_API_URL}/files`, {
            method: 'POST',
            body: formData,
          })
            .then(result => {
              resolve(JSON.parse(result.body));
            })
            .catch(e => {
              reject(e);
            });
        });
        if (file) {
          params.data.filesIds.push(file.id);
        }
      } catch (e) {
        console.error('file upload error');
      }
    }

    return dataProvider.update(resource, params);
  },
};

const App = () => {
  const role = localStorage.getItem('role');
  console.log(' App role', role);

  return (
    <Admin
      title=""
      dataProvider={crmProvider}
      authProvider={authProvider}
      dashboard={Dashboard}
      loginPage={Login}
      layout={Layout}
      i18nProvider={i18nProvider}
      disableTelemetry
      theme={darkTheme}
      requireAuth
    >
      <CustomRoutes>
        <Route path="/configuration" element={<Configuration />} />
        <Route path="/segments" element={<Segments />} />

        {isP('costs') && (
          <>
            <Route path="/costsAnalytics" element={<CostsAnalytics />} />
            <Route path="/costsAnalytics/create" element={<CostsAnalyticsCreate />} />
          </>
        )}

        {isP('exchanges') && <Route path="/notifireAnalistic" element={<NotifireAnalistic />} />}
        <Route path="/botTrades" element={<BotTradesStats />} />

        {role === 'super' && isP('projects') | isP('projects@analytics') ? (
          <Route path="/projectsList" element={<ProjectsList />} />
        ) : null}

        {isP('employee_outstaff') && <Route path="/custom_outstaff" element={<OutstafTable />} />}


        {role === 'super' && isP('projects') | isP('projects@analytics') ? (
          <Route path="/projectsList/:id" element={<ProjectItem />} />
        ) : null}
      </CustomRoutes>
      <Resource name="customers" {...visitors} />
      <Resource name="employees/salaries/stats" />
      <Resource name="employee_salaries" />
      <Resource name="commands" {...orders} options={{ label: 'Orders' }} />
      <Resource name="invoices" {...invoices} />

      {isP('employee_outstaff') && <Resource name="employee_outstaff" />}

      {/*{isP('exchange_pairs') && <Resource name="exchange_pairs" list={<ExchangePairs />} />}*/}

      <Resource name="custom_outstaff" />
      <Resource name="products" {...products} />
      <Resource name="categories" {...categories} />
      <Resource name="reviews" {...reviews} />
      <Resource name="files" {...files} />
      <Resource name="projects" {...projects} />

      <Resource name="employees" {...employees} />

      <Resource name="employee_projects" />

      {isP('exchanges') && <Resource name="exchanges" {...exchanges} />}
      {isP('liquidity_bots') && <Resource name="liquidity_bots" {...liquidity_bots} />}
      {isP('bot_trades') && <Resource name="bot_trades" {...bot_trades} />}

      <Resource name="skills" {...skills} />
      <Resource name="position" {...positions} />
      <Resource name="properties" {...employee_props} />

      {isP('blockchains') && <Resource name="blockchains" {...blockchains} />}

      {isP('employees@analytics') | (role === 'super' && isP('employees')) ? (
        <Resource name="custom_payments" {...payments} />
      ) : null}

      <Resource name="blockchain_types" {...blockchain_types} />

      {isP('costs') && <Resource name="costs" {...costs} />}
      <Resource name="fund" {...costs} />

      <Resource name="server_providers" {...server_providers} />
      <Resource name="notifiers" {...notifiers} />
      <Resource name="ping" {...ping} />

      {isP('antnodes') && <Resource name="antnodes" {...antnodes} />}

      <Resource name="exchange_instances" {...exchange_instances} />
      <Resource name="servers" {...servers} />

      {(isP('rbac_resources') || isP('rbac_permissions')) && (
        <Resource name="rbac_resources" {...resources} />
      )}

      <Resource name="rbac_permissions" {...permissions_rbac} />
      <Resource name="rbac_grants" {...grants} />
      <Resource name="versions" {...versions} />

      {role === 'admin' || role === 'super' ? <Resource name="users" {...users} /> : null}
    </Admin>
  );
};

export default App;
