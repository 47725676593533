import * as React from 'react';
import { Box, Chip } from '@mui/material';
import { useRecordContext } from 'react-admin';
import { IEmployee } from './FullNameEmployeeField';

const Skills = () => {
  const record = useRecordContext<IEmployee>();
  if (!record) return null;
  return (
    <Box>
      {record.skills.map((item: any) => (
        <Chip key={item.id} label={item.name} style={{ margin: '0 4px 0' }} />
      ))}
    </Box>
  );
};

export default Skills;
