import ApiIcon from '@mui/icons-material/Api';
import AntnodeCreate from './AntnodeCreate';
import AntnodeEdit from './AntnodeEdit';
import AntnodeList from './AntnodeList';

export default {
  list: AntnodeList,
  icon: ApiIcon,
  create: AntnodeCreate,
  edit: AntnodeEdit,
};
