import { Datagrid, FunctionField, List, TextField } from 'react-admin';
import PingColoredHealthField from './PingColoredHealthField';

const PingList = () => (
  <>
    <List resource="notifier_exchange_report" sort={{ field: 'ping', order: 'DESC' }}>
      <Datagrid rowClick="edit">
        <TextField label="Exchange" source="exchangeInstance.exchange.name" />
        <TextField label="Symbol" source="notifier.blockchain.coreCoinSymbol" />
        <TextField label="Type" source="exchangeInstance.url" />
        <FunctionField
          label="Request time"
          render={(record: any) => {
            const ping = parseInt(record?.ping) / 1000;
            return `${Number.isNaN(ping) ? 'N/A' : `${ping} ms`}  `;
          }}
        />
        <PingColoredHealthField label="TTL Status" source="id" />
      </Datagrid>
    </List>
  </>
);

export default PingList;
