import React, { useEffect, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import SearchIcon from '@mui/icons-material/Search';

import {
  CreateButton,
  Datagrid,
  DateInput,
  EditButton,
  FilterLiveSearch,
  FunctionField,
  List,
  NullableBooleanInput,
  SelectInput,
  TextField,
  TextInput,
  TopToolbar,
  useListContext,
} from 'react-admin';
import { isP } from '../components/helpers';
import { Box, Button, InputAdornment, Tooltip } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import toast from 'react-hot-toast';

export interface ICost {
  id: number;
  type: string;
  amount: string;
  currency: string;
  total: string;
  description: string;
  proof: string;
  createdAt: Date;
  fundId: number;
  project?: any;
}

export interface ICostsListProps {
  data: ICost[];
}

const getProjects = async () => {
  try {
    const projects = await fetch(`${process.env.REACT_APP_API_URL}/fund?filter=[]`, {
      method: 'GET',
      credentials: 'include',
      redirect: 'follow',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const data = projects.json();
    return data;
  } catch (error) {
    return [];
  }
};
const CostsList = (props: any) => {
  const [projects, setProjects] = useState([]);
  console.log('exchanges', projects);
  // @ts-ignore
  useEffect(async () => {
    if (isP('exchanges')) {
      const exchangesArr = await getProjects();
      setProjects(exchangesArr);
    }
  }, []);

  async function copyTextToClipboard(proof: string) {
    await navigator.clipboard.writeText(proof);
  }

  return (
    <List actions={<ListActions />} title="Funds">
      <Datagrid>
        <TextField source="type" />
        <TextField source="amount" />
        <TextField source="currency" />
        <TextField source="total" />
        <TextField source="description" />
        <FunctionField
          style={{
            cursor: 'pointer',
          }}
          label="proof"
          render={(record: any) => {
            return (
              <Tooltip title={record.proof} placement="top">
                <div>
                  <Tooltip placement="bottom" title={'Click to copy'}>
                    <div
                      onClick={async () => {
                        await copyTextToClipboard(record.proof);
                        toast.success('Copied to clipboard', {
                          duration: 2000,
                          position: 'top-center',
                        });
                      }}
                    >
                      {record.proof.slice(0, 10)}...
                    </div>
                  </Tooltip>
                </div>
              </Tooltip>
            );
          }}
        />

        <TextField source="createdAt" />
      </Datagrid>
    </List>
  );
};

function PostFilterForm() {
  const { displayedFilters, filterValues, setFilters, hideFilter } = useListContext();

  const form = useForm({
    defaultValues: filterValues,
  });

  const onSubmit = (values: any) => {
    if (Object.keys(values)?.length > 0) {
      // @ts-ignore
      setFilters(values);
      toast.success('Filtered success', {
        duration: 2000,
        position: 'top-center',
      });
    } else {
      setFilters({}, ['fundId']);
    }
  };

  const resetFilter = () => {
    setFilters({}, ['fundId']);
    toast.success('Reset filters success', {
      duration: 2000,
      position: 'top-center',
    });
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Box display="flex" alignItems="center" justifyContent="space-between" mb={1}>
          <Box component="span" mr={2}>
            <DateInput source="date" label="Date" />
            <SelectInput
              source="currency"
              choices={[
                { id: 'BTC', name: 'BTC' },
                { id: 'USDT', name: 'USDT' },
                { id: 'EUR', name: 'EUR' },
                { id: 'UAH', name: 'UAH' },
                { id: 'USD', name: 'USD' },
                { id: 'AED', name: 'AED' },
              ]}
            />
            <SelectInput
              source="type"
              defaultValue={'Income'}
              choices={[
                { id: 'Income', name: 'Income' },
                { id: 'Expense', name: 'Expense' },
              ]}
            />
          </Box>
          <Box component="span" mr={2} mb={1.5}>
            <Button variant="contained" color="primary" type="submit">
              Filter
            </Button>
          </Box>
          <Box component="span" mb={1.5}>
            <Button variant="contained" onClick={resetFilter}>
              Reset
            </Button>
          </Box>
        </Box>
      </form>
    </FormProvider>
  );
}

const ListActions = () => {
  const listContext = useListContext();

  return (
    <Box>
      <TopToolbar>
        <CreateButton resource={'costs/create?fundId=' + listContext.filterValues.fundId} />
        <EditButton />
      </TopToolbar>
      <PostFilterForm />
    </Box>
  );
};
export default CostsList;
