import * as React from 'react';
import {
  Box, Button,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead, TablePagination,
  TableRow,
  Tabs,
  Typography,
} from '@mui/material';
import { useRecordContext, TextField, DateField, FunctionField } from 'react-admin';
import { TableCellRight } from './TableCellRight';
import { useEffect } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import lv from 'date-fns/locale/fr';
import { dateFormat } from '../utils/helpers';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number | string;
  value: number | string;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number | string) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

enum BonusesType {
  bonus = 'bonus',
  penalty = 'penalty',
}

const getBonuses = async (range: string = `0,10`, id: string, type: BonusesType) => {
  const userBonuses = await fetch(
    `${process.env.REACT_APP_API_URL}/employee_bonuses?sort=["id","ASC"]&range=[${range}]&filter={"employeeId": "${id}", "type": "${type}"}`,
    {
      method: 'GET',
      credentials: 'include',
      redirect: 'follow',
    },
  );
  const data = await userBonuses.json();
  const contentRange = userBonuses.headers.get('content-range');
  const allItemsCount = contentRange?.split('/')[1];

  const all = await fetch(
    `${process.env.REACT_APP_API_URL}/employee_bonuses?sort=["id","ASC"]&range=[0,${allItemsCount}]&filter={"employeeId": "${id}", "type": "${type}"}`,
    {
      method: 'GET',
      credentials: 'include',
      redirect: 'follow',
    },
  );
  const allData = await all.json();
  const total = allData.reduce((acc: number, item: any) => acc + (+item.amount || 0), 0);
  console.log('allData', allData, total);
  return type === BonusesType.bonus ? {
    bonuses: data,
    totalRangeItems: allItemsCount,
    total,
  } : {
    penalty: data,
    totalRangeItems: allItemsCount,
    total,
  };
};

const getSalaryInfo = async (range: string = `0,10`, id: string) => {
  const userBonuses = await fetch(
    `${process.env.REACT_APP_API_URL}/employee_salaries/?sort=["date","ASC"]&range=[${range}]&filter={"employeeId": "${id}"}`,
    {
      method: 'GET',
      credentials: 'include',
      redirect: 'follow',
    },
  );
  const data = await userBonuses.json();
  const contentRange = userBonuses.headers.get('content-range');
  const allItemsCount = contentRange?.split('/')[1];

  const all = await fetch(
    `${process.env.REACT_APP_API_URL}/employee_salaries/?sort=["date","ASC"]&range=[0,${allItemsCount}]&filter={"employeeId": "${id}"}`,
    {
      method: 'GET',
      credentials: 'include',
      redirect: 'follow',
    }
  );

  const allData = await all.json();
  const total = allData.reduce((acc: number, item: any) => acc + (+item.amount || 0), 0);
  console.log('allData', allData, total);
  return { salary: data, totalRangeItems: allItemsCount, total };
};

const getOvertimes = async (range: string = `0,10`, id: string) => {
  const userBonuses = await fetch(
    `${process.env.REACT_APP_API_URL}/employee_overtimes/?sort=["date","ASC"]&range=[${range}]&filter={"employeeId": "${id}"}`,
    {
      method: 'GET',
      credentials: 'include',
      redirect: 'follow',
    },
  );

  const data = await userBonuses.json();
  const contentRange = userBonuses.headers.get('content-range');
  const allItemsCount = contentRange?.split('/')[1];

  const all = await fetch(
    `${process.env.REACT_APP_API_URL}/employee_overtimes/?sort=["date","ASC"]&range=[0,${allItemsCount}]&filter={"employeeId": "${id}"}`,
    {
      method: 'GET',
      credentials: 'include',
      redirect: 'follow',
    }
  )

  const allData = await all.json();
  const total = allData.reduce((acc: number, item: any) => acc + (+item.overtime || 0), 0);
  console.log('allData', allData, total);

  return { overtimes: data, totalRangeItems: allItemsCount, total };

};

const Salary = () => {
  const record = useRecordContext();
  if (!record) return null;
  const [value, setValue] = React.useState(0);
  const [bonuses, setBonuses] = React.useState([]);
  const [penaltyes, setPenaltyes] = React.useState<any>([]);

  const [salaryResize, setSalaryResize] = React.useState([]);
  const [overtimes, setOvertimes] = React.useState([]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setPage(0);
    setValue(newValue);
  };
  const [count, setCount] = React.useState<any>(0);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [total, setTotal] = React.useState(0);
  // @ts-ignore
  useEffect(async () => {
    if (value === 1) {
      const newBonuses = await getBonuses(calcRangeFromCurrentPage(), record.id as string, BonusesType.bonus);
      setCount(newBonuses.totalRangeItems);
      setBonuses(newBonuses.bonuses);
      setTotal(newBonuses.total);
    } else if (value === 2) {
      const newPenanties = await getBonuses(calcRangeFromCurrentPage(), record.id as string, BonusesType.penalty);
      setCount(newPenanties.totalRangeItems);
      setPenaltyes(newPenanties.penalty);
      setTotal(newPenanties.total);
    } else if (value === 3) {
      const salaryInfo = await getSalaryInfo(calcRangeFromCurrentPage(), record.id as string);
      setCount(salaryInfo.totalRangeItems);
      setSalaryResize(salaryInfo.salary);
      setTotal(salaryInfo.total);
    } else if (value === 4) {
      const overtimes = await getOvertimes(calcRangeFromCurrentPage(), record.id as string);
      setCount(overtimes.totalRangeItems);
      setOvertimes(overtimes.overtimes);
      setTotal(overtimes.total);
    }
  }, [value, page, rowsPerPage]);


  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const calcRangeFromCurrentPage = () => {
    const start = page * rowsPerPage;
    const end = (start + rowsPerPage) - 1;
    return `${page === 0 ? 0 : start},${end}`;
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };
  return (
    <Box>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label='basic tabs example'
        style={{
          backgroundColor: 'rgba(0,0,0,0.8)',
        }}
      >
        <Tab label='Info' {...a11yProps(0)} />
        <Tab label='Bonuses' {...a11yProps(1)} />
        <Tab label='Penalties' {...a11yProps(2)} />
        <Tab label='Salary ReSize' {...a11yProps(3)} />
        <Tab label='Overtimes' {...a11yProps(4)} />
      </Tabs>
      <TabPanel value={value} index={0}>
        <Table>
          <TableHead
            style={{
              backgroundColor: 'rgba(0,0,0,0.5)',
              height: '20px',
            }}
          >
            <TableRow
              style={{
                backgroundColor: 'rgba(0,0,0,0.2)',
                height: '10px',
              }}
            >
              <TableCell>Key</TableCell>
              <TableCellRight>Value</TableCellRight>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow
              style={{
                backgroundColor: 'rgba(0,0,0,0.2)',
                height: '10px',
              }}
            >
              <TableCell>Salary</TableCell>
              <TableCellRight>
                {' '}
                <TextField source='salary' />
              </TableCellRight>
            </TableRow>
            <TableRow
              style={{
                backgroundColor: 'rgba(0,0,0,0.2)',
                height: '10px',
              }}
            >
              <TableCell>Currency</TableCell>
              <TableCellRight>
                {' '}
                <TextField source='currency' />
              </TableCellRight>
            </TableRow>
            <TableRow
              style={{
                backgroundColor: 'rgba(0,0,0,0.2)',
                height: '10px',
              }}
            >
              <TableCell>Payment Type</TableCell>
              <TableCellRight>
                {' '}
                <TextField source='paymentType' />
              </TableCellRight>
            </TableRow>
            <TableRow
              style={{
                backgroundColor: 'rgba(0,0,0,0.2)',
                height: '10px',
              }}
            >
              <TableCell>Payment Details</TableCell>
              <TableCellRight>
                {' '}
                <TextField source='paymentDetails' />
              </TableCellRight>
            </TableRow>
            <TableRow
              style={{
                backgroundColor: 'rgba(0,0,0,0.2)',
                height: '10px',
              }}
            >
              <TableCell>Start Date</TableCell>
              <TableCellRight>
                {' '}
                <FunctionField
                  source='startDate'
                  label='startDate'
                  render={(record: any) => {
                    return dateFormat(record.startDate);
                  }}
                />
              </TableCellRight>
            </TableRow>
            <TableRow
              style={{
                backgroundColor: 'rgba(0,0,0,0.2)',
                height: '10px',
              }}
            >
              <TableCell>End Date</TableCell>
              <TableCellRight>
                {' '}
                <FunctionField
                  source='endDate'
                  label='endDate'
                  render={(record: any) => {
                    return dateFormat(record.endDate);
                  }}
                />
              </TableCellRight>
            </TableRow>
          </TableBody>
        </Table>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Box>
          <Table>
            <TableHead
              style={{
                backgroundColor: 'rgba(0,0,0,0.5)',
                height: '20px',
              }}
            >
              <TableRow
                style={{
                  backgroundColor: 'rgba(0,0,0,0.2)',
                  height: '10px',
                }}
              >
                <TableCell>Date</TableCell>
                <TableCellRight>Amount</TableCellRight>
              </TableRow>
            </TableHead>
            {bonuses && bonuses?.length ? (
              bonuses.map((bonus: any) => (
                <TableRow
                  style={{
                    backgroundColor: 'rgba(0,0,0,0.2)',
                    height: '10px',
                  }}
                  key={bonus.id}
                >
                  <TableCell>{new Date(bonus.date).toLocaleDateString('en-GB')}</TableCell>
                  <TableCellRight>{bonus.amount}</TableCellRight>
                </TableRow>
              ))
            ) : (
              <TableRow
                style={{
                  backgroundColor: 'rgba(0,0,0,0.2)',
                  height: '10px',
                }}
              >
                <TableCell>Empty</TableCell>
              </TableRow>
            )}
          </Table>
        </Box>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Box>
          <Table>
            <TableHead
              style={{
                backgroundColor: 'rgba(0,0,0,0.5)',
                height: '20px',
              }}
            >
              <TableRow
                style={{
                  backgroundColor: 'rgba(0,0,0,0.2)',
                  height: '10px',
                }}
              >
                <TableCell>Date</TableCell>
                <TableCellRight>Amount</TableCellRight>
              </TableRow>
            </TableHead>
            {penaltyes && penaltyes?.length ? (
              penaltyes.map((penalty: any) => (
                <TableRow
                  style={{
                    backgroundColor: 'rgba(0,0,0,0.2)',
                    height: '10px',
                  }}
                  key={penalty.id}
                >
                  <TableCell>{new Date(penalty.date).toLocaleDateString('en-GB')}</TableCell>
                  <TableCellRight>{penalty.amount}</TableCellRight>
                </TableRow>
              ))
            ) : (
              <TableRow
                style={{
                  backgroundColor: 'rgba(0,0,0,0.2)',
                  height: '10px',
                }}
              >
                <TableCell>Empty</TableCell>
              </TableRow>
            )}
          </Table>
        </Box>
      </TabPanel>
      <TabPanel value={value} index={3}>
        <Table>
          <TableHead
            style={{
              backgroundColor: 'rgba(0,0,0,0.5)',
              height: '20px',
            }}
          >
            <TableRow
              style={{
                backgroundColor: 'rgba(0,0,0,0.2)',
                height: '10px',
              }}
            >
              <TableCell>Date</TableCell>
              <TableCellRight>Amount</TableCellRight>
              <TableCellRight>Payment type</TableCellRight>
            </TableRow>
          </TableHead>
          {salaryResize && salaryResize?.length ? (
            salaryResize.map((salary: any) => (
              <TableRow
                style={{
                  backgroundColor: 'rgba(0,0,0,0.2)',
                  height: '10px',
                }}
                key={salary.id}
              >
                <TableCell>{new Date(salary.date).toLocaleDateString('en-GB')}</TableCell>
                <TableCellRight>{salary.amount}</TableCellRight>
                <TableCellRight>{salary.currency}</TableCellRight>
              </TableRow>
            ))
          ) : (
            <TableRow
              style={{
                backgroundColor: 'rgba(0,0,0,0.2)',
                height: '10px',
              }}
            >
              <TableCell>Empty</TableCell>
            </TableRow>
          )}
        </Table>
      </TabPanel>
      <TabPanel value={value} index={4}>
        <Box>
          <Table>
            <TableHead
              style={{
                backgroundColor: 'rgba(0,0,0,0.5)',
                height: '20px',
              }}
            >
              <TableRow
                style={{
                  backgroundColor: 'rgba(0,0,0,0.2)',
                  height: '10px',
                }}
              >
                <TableCell>Date</TableCell>
                <TableCellRight>Hours</TableCellRight>
              </TableRow>
            </TableHead>
            {overtimes && overtimes?.length ? (
              overtimes.map((overtime: any) => (
                <TableRow
                  style={{
                    backgroundColor: 'rgba(0,0,0,0.2)',
                    height: '10px',
                  }}
                  key={overtime.id}
                >
                  <TableCell>{new Date(overtime.date).toLocaleDateString('en-GB')}</TableCell>
                  <TableCellRight>{overtime.overtime}</TableCellRight>
                </TableRow>
              ))
            ) : (
              <TableRow
                style={{
                  backgroundColor: 'rgba(0,0,0,0.2)',
                  height: '10px',
                }}
              >
                <TableCell>Empty</TableCell>
              </TableRow>
            )}
          </Table>
        </Box>
      </TabPanel>
      {
        value !== 0 && (
          <Box
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginTop: '10px',

            }}
          >
            <Box>
              <Button
                variant='contained'
                color='primary'
                disabled
              >
                Total: {total}
              </Button>
            </Box>
            <TablePagination
              component='div'
              count={count}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />

          </Box>
        )
      }
    </Box>
  );
};

export default Salary;
