import * as React from 'react';
import {
  Create,
  FormTab,
  TabbedForm,
  TextInput,
  required,
  ReferenceArrayInput,
  SelectArrayInput,
} from 'react-admin';
import { RichTextInput } from 'ra-input-rich-text';

const ProjectCreate = () => (
  <Create>
    <TabbedForm>
      <FormTab label="resources.projects.tabs.main" sx={{ maxWidth: '40em' }}>
        <TextInput autoFocus source="name" fullWidth validate={required()} />
        <RichTextInput source="description" label="" />
        <ReferenceArrayInput source="skillIds" reference="skills">
          <SelectArrayInput optionText="name" />
        </ReferenceArrayInput>
      </FormTab>
    </TabbedForm>
  </Create>
);

export default ProjectCreate;
