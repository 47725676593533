import React, { useEffect } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  FormControl,
  IconButton,
  Input,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { es } from 'date-fns/locale';
import { TableCellRight } from '../employees/TableCellRight';
import { useParams } from 'react-router';
import { Box } from '@mui/system';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DayJsUtils from '@date-io/dayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { usePermissions } from 'react-admin';
import { isP } from './helpers';

const dateFormatter = (v: any) => {
  if (!(v instanceof Date) || Number.isNaN(v)) {
    if (!v?.length) return null;
    return null;
  }
  const pad = '00';
  const yy = v.getUTCFullYear().toString();
  const mm = (v.getUTCMonth() + 1).toString();
  const dd = v.getUTCDate().toString();
  return `${yy}-${(pad + mm).slice(-2)}-${(pad + dd).slice(-2)}`;
};

const dateParser = (v: any) => {
  if (typeof v !== 'string') return null;
  if (typeof v !== 'string') return null;
  const newDate = new Date(v);
  if (
    newDate.getUTCFullYear() &&
    String(newDate.getUTCFullYear())?.length === 4 &&
    newDate.getUTCMonth() &&
    newDate.getUTCDate()
  ) {
    const UTCdate = new Date(
      Date.UTC(newDate.getUTCFullYear(), newDate.getUTCMonth(), newDate.getUTCDate()),
    );
    return UTCdate;
  }
  return v;
};

const createBonuse = async (body: any) => {
  const userBonuses = await fetch(`${process.env.REACT_APP_API_URL}/employee_bonuses`, {
    method: 'POST',
    credentials: 'include',
    redirect: 'follow',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });
  const data = userBonuses.json();
  return data;
};

const getBonuses = async (id: any, type: any) => {
  try {
    const bonuses = await fetch(
      `${process.env.REACT_APP_API_URL}/employee_bonuses?sort=["id","ASC"]&range=[0,1000]&filter={"employeeId": "${id}", "type": "${type}"}`,
      {
        method: 'GET',
        credentials: 'include',
        redirect: 'follow',
      },
    );
    const data = bonuses.json();
    return data;
  } catch (e) {
    return [];
  }
};

const deleteBonuses = async (id: any) => {
  const bonuses = await fetch(`${process.env.REACT_APP_API_URL}/employee_bonuses/${id}`, {
    method: 'DELETE',
    credentials: 'include',
    redirect: 'follow',
  });
  const data = bonuses.json();
  return data;
};

const createSalary = async (body: any) => {
  const userBonuses = await fetch(`${process.env.REACT_APP_API_URL}/employee_salaries`, {
    method: 'POST',
    credentials: 'include',
    redirect: 'follow',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });
  const data = userBonuses.json();
  return data;
};

const getSalaryInfo = async (id: any) => {
  try {
    const salaries = await fetch(
      `${process.env.REACT_APP_API_URL}/employee_salaries/?sort=["date","ASC"]&range=[0,1000]&filter={"employeeId": "${id}"}`,
      {
        method: 'GET',
        credentials: 'include',
        redirect: 'follow',
      },
    );
    const data = salaries.json();
    return data;
  } catch (e) {
    return [];
  }
};

const deleteSalary = async (id: any) => {
  try {
    const salaries = await fetch(`${process.env.REACT_APP_API_URL}/employee_salaries/${id}`, {
      method: 'DELETE',
      credentials: 'include',
      redirect: 'follow',
    });
    const data = salaries.json();
    return data;
  } catch (e) {
    return [];
  }
};

const createOvertime = async (body: any) => {
  try {
    const overtime = await fetch(`${process.env.REACT_APP_API_URL}/employee_overtimes`, {
      method: 'POST',
      credentials: 'include',
      redirect: 'follow',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });
    const data = overtime.json();
    return data;
  } catch (e) {
    return [];
  }
};

const getOvertimes = async (id: any) => {
  try {
    const overtime = await fetch(
      `${process.env.REACT_APP_API_URL}/employee_overtimes/?sort=["date","ASC"]&range=[0,1000]&filter={"employeeId": "${id}"}`,
      {
        method: 'GET',
        credentials: 'include',
        redirect: 'follow',
      },
    );
    const data = overtime.json();
    return data;
  } catch (e) {
    return [];
  }
};

const deleteOvertime = async (id: any) => {
  try {
    const overtime = await fetch(`${process.env.REACT_APP_API_URL}/employee_overtimes/${id}`, {
      method: 'DELETE',
      credentials: 'include',
      redirect: 'follow',
    });
    const data = overtime.json();
    return data;
  } catch (e) {
    return [];
  }
};

function OutstafAddings({ expanded, panels, handleChangePanel }: any) {
  const outstafTypes = {
    sick: 'sick',
    vacation: 'vacation',
    reschedule: 'reschedule',
  };
  const [outstaff, setOutstaff] = React.useState([]);
  const [outstaffType, setOutstaffType] = React.useState('sick');
  const [dateFrom, setDateFrom] = React.useState<Date | null>(null);
  const [dateTo, setDateTo] = React.useState<Date | null>(null);
  const [year, setYear] = React.useState<any>(
    // currentYear,
    new Date().getFullYear(),
  );
  const [description, setDescription] = React.useState('');
  const { id } = useParams<{ id: string }>();

  const changeDescription = (e: any) => {
    setDescription(e.target.value);
  };
  const handleOutstaffTypeChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setOutstaffType(event.target.value as string);
  };
  const handleDateFromChange = (date: Date | null) => {
    // @ts-ignore
    const newDate = new Date(date);
    if (
      newDate.getUTCFullYear() &&
      String(newDate.getUTCFullYear())?.length === 4 &&
      newDate.getUTCMonth() &&
      newDate.getUTCDate()
    ) {
      const UTCdate = new Date(
        Date.UTC(newDate.getUTCFullYear(), newDate.getUTCMonth(), newDate.getUTCDate()),
      );
      // @ts-ignore
      setDateFrom(UTCdate);
      return;
    } else {
      setDateFrom(date);
      return;
    }
  };
  const handleDateToChange = (date: Date | null) => {
    // @ts-ignore
    const newDate = new Date(date);
    if (
      newDate.getUTCFullYear() &&
      String(newDate.getUTCFullYear())?.length === 4 &&
      newDate.getUTCMonth() &&
      newDate.getUTCDate()
    ) {
      const UTCdate = new Date(
        Date.UTC(newDate.getUTCFullYear(), newDate.getUTCMonth(), newDate.getUTCDate()),
      );
      // @ts-ignore
      setDateTo(UTCdate);
      return;
    } else {
      setDateTo(date);
      return;
    }
  };
  const handleYearChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setYear(event.target.value as number);
  };
  const handleGetOutstaff = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/employee_outstaff/?sort=["id","ASC"]&range=[0,1000]&filter={"employeeId": "${id}", "year": ${year}}`,
        {
          method: 'GET',
          credentials: 'include',
          redirect: 'follow',
        },
      );
      const data = response.json();
      return data;
    } catch (e) {
      return [];
    }
  };
  const handleAddOutstaff = async () => {
    const body = {
      type: outstaffType,
      dateFrom: dateFrom,
      dateTo: dateTo,
      employeeId: id,
      description: description,
    };

    async function createOutstaff(body: {
      dateTo: Date | null;
      employeeId: string | undefined;
      type: string;
      dateFrom: Date | null;
    }) {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/employee_outstaff`, {
          method: 'POST',
          credentials: 'include',
          redirect: 'follow',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(body),
        });
        const data = response.json();
        return data;
      } catch (e) {
        return [];
      }
    }

    // clear form
    setOutstaffType('sick');
    setDateFrom(null);
    setDateTo(null);
    setDescription('');
    await createOutstaff(body);
    const outstaffList = await handleGetOutstaff();
    setOutstaff(outstaffList);
  };
  const handleDeleteOutstaff = async (id: any) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/employee_outstaff/${id}`, {
        method: 'DELETE',
        credentials: 'include',
        redirect: 'follow',
      });
      const data = response.json();
      const outstaffList = await handleGetOutstaff();
      setOutstaff(outstaffList);
      return data;
    } catch (e) {
      setOutstaff([]);
      return [];
    }
  };
  React.useEffect(() => {
    async function getOutstaff() {
      const data = await handleGetOutstaff();
      setOutstaff(data);
    }

    getOutstaff();
  }, [year]);
  return (
    <Accordion
      expanded={expanded === panels.outstaff}
      onChange={handleChangePanel(panels.outstaff)}
    >
      <AccordionSummary
        aria-controls="panel5a-content"
        id="panel5a-header"
        expandIcon={<ExpandMoreIcon />}
      >
        <Typography>Outstaf</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h6">Outstaff</Typography>
            <Box display="flex" alignItems="center" gap={2}>
              <TextField
                id="year"
                select
                label="Year"
                value={year}
                onChange={handleYearChange}
                SelectProps={{
                  native: true,
                }}
                helperText="Please select year"
              >
                <option value={2023}>2023</option>
                <option value={2022}>2022</option>
              </TextField>
              <Button
                disabled={!dateTo || !dateFrom || !outstaffType || !id}
                variant="contained"
                color="primary"
                onClick={handleAddOutstaff}
              >
                Add
              </Button>
            </Box>
          </Box>
          <Box display="flex" justifyContent="space-between" alignItems="center" gap={2}>
            <TextField
              id="outstaffType"
              select
              label="Outstaff type"
              value={outstaffType}
              onChange={handleOutstaffTypeChange}
              SelectProps={{
                native: true,
              }}
            >
              <option value={outstafTypes.sick}>Sick</option>
              <option value={outstafTypes.vacation}>Vacation</option>
              <option value={outstafTypes.reschedule}>Reschedule</option>
            </TextField>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                value={dateFrom}
                label="Date from"
                onChange={handleDateFromChange}
                renderInput={(params: any) => <TextField {...params} />}
              />
              <DesktopDatePicker
                value={dateTo}
                label="Date to"
                onChange={handleDateToChange}
                renderInput={(params: any) => <TextField {...params} />}
              />
            </LocalizationProvider>
            <TextField
              id="description"
              label="Description"
              value={description}
              onChange={changeDescription}
            />
          </Box>
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Outstaff type</TableCell>
                  <TableCell align="right">Date from</TableCell>
                  <TableCell align="right">Date to</TableCell>
                  <TableCell align="right">Description</TableCell>
                  <TableCell align="right">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {outstaff?.map((row: any) => (
                  <TableRow key={row.id}>
                    <TableCell component="th" scope="row">
                      {row.type}
                    </TableCell>
                    <TableCell align="right">
                      {new Date(row.dateFrom).toLocaleDateString()}
                    </TableCell>
                    <TableCell align="right">{new Date(row.dateTo).toLocaleDateString()}</TableCell>
                    <TableCell align="right">{row.description}</TableCell>
                    <TableCell align="right">
                      <IconButton aria-label="delete" onClick={() => handleDeleteOutstaff(row.id)}>
                        x
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
}

const EmployeeBonuses = () => {
  const { id } = useParams();

  const [date, setDate] = React.useState(null);
  const [amount, setAmount] = React.useState(0);
  const [bonuses, setBonuses] = React.useState<any>([]);
  const [penaltyes, setPenaltyes] = React.useState<any>([]);
  const [overtimeH, setOvertimeH] = React.useState(0);
  const [overtimes, setOvertimes] = React.useState<any>([]);
  const [currency, setCurrency] = React.useState('USDT');
  const [salaryResize, setSelaryResize] = React.useState<any>([]);
  const [expanded, setExpanded] = React.useState<string | false>('');
  const panels = {
    salary: 'panel1',
    bonuses: 'panel2',
    penaltyes: 'panel3',
    overtime: 'panel4',
    outstaff: 'panel5',
  };

  const { permissions } = usePermissions();
  const handleChangePanel =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const handleChangeCurrencyType = (event: SelectChangeEvent) => {
    setCurrency(event.target.value as string);
  };
  const resetForm = () => {
    setAmount(0);
    setDate(null);
  };
  //Bonuses
  const createBonuseHandler = async (type: string) => {
    const body = {
      amount,
      type: type,
      date,
      employeeId: id,
    };
    await createBonuse(body);
    if (type === 'bonus') {
      const newBonuses = await getBonuses(id, type);
      setBonuses(newBonuses);
    } else {
      const newPenaltyes = await getBonuses(id, type);
      setPenaltyes(newPenaltyes);
    }
    resetForm();
  };
  const deleteBonusesHandler = async (id: any, type: string) => {
    await deleteBonuses(id).then(async () => {
      if (type === 'bonus') {
        const bonuses = await getBonuses(id, type);
        setBonuses(bonuses);
      } else {
        const penaltyes = await getBonuses(id, type);
        setPenaltyes(penaltyes);
      }
    });
  };
  //Salary
  const createSalaryHandler = async () => {
    const body = {
      amount,
      currency,
      date,
      employeeId: id,
    };
    await createSalary(body);
    resetForm();
    const sr = await getSalaryInfo(id);
    setSelaryResize(sr);
  };
  const deleteSalaryHandler = async (id: any) => {
    await deleteSalary(id);
    const sr = await getSalaryInfo(id);
    setSelaryResize(sr);
  };
  //Overtimes
  const createOvertimeHandler = async () => {
    const body = {
      overtime: Number(overtimeH),
      date,
      employeeId: id,
    };
    await createOvertime(body);
    resetForm();
    const sr = await getOvertimes(id);
    setOvertimes(sr);
  };
  const deleteOvertimeHandler = async (id: any) => {
    await deleteOvertime(id);
    const sr = await getOvertimes(id);
    setOvertimes(sr);
  };

  // @ts-ignore
  useEffect(async () => {
    const newBonuses = await getBonuses(id, 'bonus');
    setBonuses(newBonuses);
    const penaltyes = await getBonuses(id, 'penalty');
    setPenaltyes(penaltyes);
    const sr = await getSalaryInfo(id);
    setSelaryResize(sr);
    const over = await getOvertimes(id);
    setOvertimes(over);
  }, []);

  const dateChangeHandler = (newValue: any) => {
    const newDate = new Date(newValue);
    if (
      newDate.getUTCFullYear() &&
      String(newDate.getUTCFullYear())?.length === 4 &&
      newDate.getUTCMonth() &&
      newDate.getUTCDate()
    ) {
      const UTCdate = new Date(
        Date.UTC(newDate.getUTCFullYear(), newDate.getUTCMonth(), newDate.getUTCDate()),
      );
      // @ts-ignore
      setDate(UTCdate);
      return;
    } else {
      setDate(newValue);
      return;
    }
  };

  return (
    <Box>
      {isP('employee_salaries') && (
        <Accordion
          expanded={expanded === panels.salary}
          onChange={handleChangePanel(panels.salary)}
        >
          <AccordionSummary
            aria-controls="panel1a-content"
            id="panel1a-header"
            expandIcon={<ExpandMoreIcon />}
          >
            <Typography>Salary</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Table>
              <TableRow>
                <TableCell>
                  <Typography>Amount</Typography>
                  <Input
                    type="number"
                    value={amount}
                    onChange={(e: any) => setAmount(e.target.value)}
                  />
                </TableCell>
                <TableCellRight style={{ minWidth: 200 }}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                      // @ts-ignore
                      value={date}
                      onChange={dateChangeHandler}
                      renderInput={(params: any) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </TableCellRight>
                <TableCellRight>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Type</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={currency}
                      label="Currency"
                      onChange={handleChangeCurrencyType}
                    >
                      <MenuItem value="BTC">BTC</MenuItem>
                      <MenuItem value="USDT">USDT</MenuItem>
                      <MenuItem value="EUR">EUR</MenuItem>
                      <MenuItem value="UAH">UAH</MenuItem>
                      <MenuItem value="USD">USD</MenuItem>
                    </Select>
                  </FormControl>
                </TableCellRight>
                <TableCellRight>
                  <Button disabled={amount < 0 || !currency || !date} onClick={createSalaryHandler}>
                    Create
                  </Button>
                </TableCellRight>
              </TableRow>
              {salaryResize && salaryResize?.length ? (
                salaryResize.map((resize: any) => (
                  <TableRow key={resize.id}>
                    <TableCell>{new Date(resize.date).toLocaleDateString('en-GB')}</TableCell>
                    <TableCellRight>{resize.amount}</TableCellRight>
                    <TableCellRight>{resize.currency}</TableCellRight>
                    <TableCellRight>{resize.type}</TableCellRight>
                    <TableCellRight>
                      <Button onClick={() => deleteSalaryHandler(resize.id)}>Remove</Button>
                    </TableCellRight>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell>Empty</TableCell>
                </TableRow>
              )}
            </Table>
          </AccordionDetails>
        </Accordion>
      )}
      {isP('employee_bonuses') && (
        <>
          <Accordion
            expanded={expanded === panels.bonuses}
            onChange={handleChangePanel(panels.bonuses)}
          >
            <AccordionSummary
              aria-controls="panel2a-content"
              id="panel2a-header"
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>Bonuses</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Table>
                <TableRow>
                  <TableCell>
                    <Typography>Amount</Typography>
                    <Input
                      type="number"
                      value={amount}
                      onChange={(e: any) => setAmount(e.target.value)}
                    />
                  </TableCell>
                  <TableCellRight style={{ minWidth: 200 }}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker
                        // @ts-ignore
                        value={date}
                        onChange={dateChangeHandler}
                        renderInput={(params: any) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                  </TableCellRight>
                  <TableCellRight>
                    <Button
                      disabled={amount < 0 || !date}
                      onClick={() => createBonuseHandler('bonus')}
                    >
                      Create
                    </Button>
                  </TableCellRight>
                </TableRow>
                {bonuses && bonuses?.length ? (
                  bonuses.map((bonus: any) => (
                    <TableRow key={bonus.id}>
                      <TableCell>{new Date(bonus.date).toLocaleDateString('en-GB')}</TableCell>
                      <TableCellRight>{bonus.amount}</TableCellRight>
                      <TableCellRight>{bonus.type}</TableCellRight>
                      <TableCellRight>
                        <Button onClick={() => deleteBonusesHandler(bonus.id, 'bonus')}>
                          Remove
                        </Button>
                      </TableCellRight>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell>Empty</TableCell>
                  </TableRow>
                )}
              </Table>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === panels.penaltyes}
            onChange={handleChangePanel(panels.penaltyes)}
          >
            <AccordionSummary
              aria-controls="panel3a-content"
              id="panel3a-header"
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>Penalty</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Table>
                <TableRow>
                  <TableCell>
                    <Typography>Amount</Typography>
                    <Input
                      type="number"
                      value={amount}
                      onChange={(e: any) => setAmount(e.target.value)}
                    />
                  </TableCell>
                  <TableCellRight style={{ minWidth: 200 }}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker
                        // @ts-ignore
                        value={date}
                        onChange={dateChangeHandler}
                        renderInput={(params: any) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                  </TableCellRight>
                  <TableCellRight>
                    <Button
                      disabled={amount < 0 || !date}
                      onClick={() => createBonuseHandler('penalty')}
                    >
                      Create
                    </Button>
                  </TableCellRight>
                </TableRow>
                {penaltyes && penaltyes?.length ? (
                  penaltyes.map((bonus: any) => (
                    <TableRow key={bonus.id}>
                      <TableCell>{new Date(bonus.date).toLocaleDateString('en-GB')}</TableCell>
                      <TableCellRight>{bonus.amount}</TableCellRight>
                      <TableCellRight>{bonus.type}</TableCellRight>
                      <TableCellRight>
                        <Button onClick={() => deleteBonusesHandler(bonus.id, 'penalty')}>
                          Remove
                        </Button>
                      </TableCellRight>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell>Empty</TableCell>
                  </TableRow>
                )}
              </Table>
            </AccordionDetails>
          </Accordion>
        </>
      )}
      {isP('employee_overtimes') && (
        <Accordion
          expanded={expanded === panels.overtime}
          onChange={handleChangePanel(panels.overtime)}
        >
          <AccordionSummary
            aria-controls="panel4a-content"
            id="panel4a-header"
            expandIcon={<ExpandMoreIcon />}
          >
            <Typography>Overtimes</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Table>
              <TableRow>
                <TableCell>
                  <Typography>Overtime</Typography>
                  <Input
                    type="number"
                    value={overtimeH}
                    onChange={(e: any) => setOvertimeH(e.target.value)}
                  />
                </TableCell>
                <TableCellRight style={{ minWidth: 200 }}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      value={date}
                      onChange={dateChangeHandler}
                      renderInput={params => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </TableCellRight>
                <TableCellRight>
                  <Button disabled={overtimeH < 0 || !date} onClick={createOvertimeHandler}>
                    Create
                  </Button>
                </TableCellRight>
              </TableRow>
              {overtimes && overtimes?.length ? (
                overtimes.map((resize: any) => (
                  <TableRow key={resize.id}>
                    <TableCell>{new Date(resize.date).toLocaleDateString('en-GB')}</TableCell>
                    <TableCellRight>{resize.amount}</TableCellRight>
                    <TableCellRight>{resize.overtime}</TableCellRight>
                    <TableCellRight>{resize.type}</TableCellRight>
                    <TableCellRight>
                      <Button onClick={() => deleteOvertimeHandler(resize.id)}>Remove</Button>
                    </TableCellRight>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell>Empty</TableCell>
                </TableRow>
              )}
            </Table>
          </AccordionDetails>
        </Accordion>
      )}
      {isP('employee_outstaff') && (
        <OutstafAddings expanded={expanded} panels={panels} handleChangePanel={handleChangePanel} />
      )}
    </Box>
  );
};

export default EmployeeBonuses;
