import * as React from 'react';
import { useRecordContext, NumberField, NumberFieldProps, FunctionField } from 'react-admin';

const ColoredMemoryHomeFieldNtf = (props: NumberFieldProps) => {
  const record = useRecordContext(props);
  if (!record || !props.source) {
    return null;
  }

  const memoryHome = (record?.server?.homeMemoryUsage / record?.server?.homeMemoryTotal) * 100;

  const left = Number.isNaN(+(record?.server?.homeMemoryUsage ?? undefined))
    ? 'N/A'
    : (record?.server?.homeMemoryUsage / 1024 / 1024 / 1024).toFixed(2);
  const right = Number.isNaN(+(record?.server?.homeMemoryTotal ?? undefined))
    ? 'N/A'
    : (record?.server?.homeMemoryTotal / 1024 / 1024 / 1024).toFixed(2);
  const value = `${Number(+right - +left).toFixed(2)}  GB`;
  const percent = `${memoryHome.toFixed()}%`;

  if (memoryHome > 80) {
    return (
      <FunctionField
        title={percent}
        sx={{ color: 'red' }}
        render={(record: any) => `${value} (${percent})`}
      />
    );
  } else if (memoryHome > 75 && memoryHome < 80) {
    return (
      <FunctionField title={percent} sx={{ color: 'orange' }} render={(record: any) => value} />
    );
  } else {
    return <FunctionField title={percent} render={(record: any) => value} />;
  }
};

ColoredMemoryHomeFieldNtf.defaultProps = NumberField.defaultProps;

export default ColoredMemoryHomeFieldNtf;
