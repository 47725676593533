import * as React from 'react';
import Button from '@mui/material/Button';

import { Box, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { useEffect, useState } from 'react';

const url = process.env.REACT_APP_API_URL;

async function revertDiffHandler() {
  try {
    await fetch(`${process.env.REACT_APP_API_URL}/bot_trades/analitics/reset`, {
      method: 'POST',
      credentials: 'include',
      redirect: 'follow',
    });
    return null;
  } catch (e) {
    console.error('revertDiffHandler error');
    return null;
  }
}

const getDiffs = async () => {
  // {{url}}/bot_trades/analitics/diffs
  try {
    const bots = await fetch(`${url}/bot_trades/analitics/diffs`, {
      method: 'GET',
      credentials: 'include',
      redirect: 'follow',
    });
    const res = await bots.json();
    return res;
  } catch (e) {
    console.error('error', e);
    return [];
  }
};

const getBotsAnalitics = async () => {
  const bots = await fetch(`${url}/bot_trades/analitics/bots`, {
    method: 'GET',
    credentials: 'include',
    redirect: 'follow',
  });
  const res = await bots.json();
  return res;
};

const getSnapshots = async () => {
  try {
    const bots = await fetch(`${url}/bot_trades/analitics/snapshots`, {
      method: 'GET',
      credentials: 'include',
      redirect: 'follow',
    });
    const res = await bots.json();
    return res;
  } catch (e) {
    console.error('error', e);
    return [];
  }
};

export const BotTradesStats = () => {
  const [analitics, setAnalitics] = useState([]);
  const [snapshots, setSnapshots] = useState([]);
  const [diffs, setDiffs] = useState([]);

  // @ts-ignore
  useEffect(async () => {
    const stats = await getBotsAnalitics();
    setAnalitics(stats);
    const diffsArr = await getDiffs();
    setDiffs(diffsArr);

    const snapshots = await getSnapshots();
    setSnapshots(snapshots);
  }, []);

  async function handleDelete(id: number) {
    const bot = await fetch(`${url}/bot_trades/analitics/snapshots/${id}`, {
      method: 'DELETE',
      credentials: 'include',
      redirect: 'follow',
    });
    const res = await bot.json();
    return res;
  }

  async function revert() {
    await revertDiffHandler().then(async () => {
      const stats = await getBotsAnalitics();
      setAnalitics(stats);

      const snapshots = await getSnapshots();
      setSnapshots(snapshots);

      const diffsArr = await getDiffs();
      setDiffs(diffsArr);
    });
  }

  async function deleteHandler(id: number) {
    await handleDelete(id).then(async () => {
      const stats = await getBotsAnalitics();
      setAnalitics(stats);

      const snapshots = await getSnapshots();
      setSnapshots(snapshots);

      const diffsArr = await getDiffs();
      setDiffs(diffsArr);
    });
  }

  return (
    <Box>
      <h1>Bot trades analitics</h1>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Project</TableCell>
            <TableCell>TTL Bots</TableCell>
            <TableCell>TTL Pair</TableCell>
            <TableCell>Active Pairs</TableCell>
            <TableCell>TTL Diff</TableCell>
            <TableCell>Untraded</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {analitics.map((row: any) => {
            return (
              <TableRow key={row.exchangeId}>
                <TableCell>{row.exchangeName}</TableCell>
                <TableCell>{row.ttlBots}</TableCell>
                <TableCell>{row.ttlPairs}</TableCell>
                <TableCell></TableCell>
                <TableCell>
                  {diffs?.length > 0
                    ? diffs?.map((diff: any) => {
                        if (diff?.exchangeId === row?.exchangeId) {
                          return <div>{diff?.diff}</div>;
                        }
                        return null;
                      })
                    : null}
                </TableCell>
                <TableCell>
                  {row.untraded ? (
                    <div
                      style={{
                        color: 'rgb(255,0,0)',
                      }}
                    >
                      true
                    </div>
                  ) : (
                    <div
                      style={{
                        color: 'rgb(47,255,0)',
                      }}
                    >
                      false
                    </div>
                  )}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <br />
      <br />
      <br />
      <div
        style={{
          background: 'rgb(38,38,38)',
        }}
      >
        <div
          style={{
            width: '100%',
            padding: '20px',
          }}
        >
          <div />
          <div>
            <Button variant="contained" color="primary" onClick={revert}>
              {' '}
              Revert diff{' '}
            </Button>
          </div>
        </div>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Diff</TableCell>
              <TableCell>Date</TableCell>
              <TableCell width="20px"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {snapshots.map((row: any) => {
              return (
                <TableRow key={row.id}>
                  <TableCell>
                    {row.report.diffs?.length > 0
                      ? row.report.diffs.map(({ diff, exchangeId }: any) => {
                          return (
                            <>
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  width: '100%',
                                }}
                              >
                                <div>
                                  {analitics && exchangeId && analitics?.length > 0
                                    ? // @ts-ignore
                                      (
                                        analitics.find(
                                          (item: any) => item.exchangeId === exchangeId,
                                        ) as any
                                      )?.exchangeName
                                    : '-'}
                                </div>
                                <div>{diff}</div>
                              </div>
                            </>
                          );
                        })
                      : '-'}
                  </TableCell>
                  <TableCell>{row.date}</TableCell>
                  <TableCell width="20px">
                    <Button variant="outlined" onClick={() => deleteHandler(row.id)}>
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
    </Box>
  );
};

export default BotTradesStats;
