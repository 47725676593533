import { Box, Card, CardContent, Grid, Typography } from '@mui/material';
import { FunctionField, Labeled, Show, TextField, useTranslate } from 'react-admin';
import Aside from './Aside';
import Contacts from './Contacs';
import Files from './Files';
import FullNameEmployeeField from './FullNameEmployeeField';
import Skills from './Skills';
import * as React from 'react';
import Salary from './Salary';

const Spacer = () => <Box m={1}>&nbsp;</Box>;

const EmployeeShow = () => {
  const translate = useTranslate();
  return (
    <Show title={<EmployeeTitle />} aside={<Aside />}>
      <Box>
        <Card>
          <CardContent>
            <Grid container spacing={1} width={{ xs: '100%', xl: 700 }}>
              <Grid item xs={12} sm={12} md={12}>
                <Typography variant="h6" gutterBottom>
                  <FullNameEmployeeField size="36" fontSize="24px" sx={{ margin: '15px 0' }} />
                </Typography>
                <Spacer />
                <Grid container>
                  <Grid item xs={12} sm={12} md={3}>
                    <Labeled source="Position">
                      <TextField source="position.name" />
                    </Labeled>
                  </Grid>
                  <Grid item xs={12} sm={12} md={3}>
                    <Labeled source="Nickname">
                      <TextField source="nickname" />
                    </Labeled>
                  </Grid>
                  <Grid item xs={12} sm={12} md={3}>
                    <Labeled source="Status">
                      <FunctionField
                        source="status"
                        render={(record: any) => `${record.outstaffStatus ?? record.status}`}
                      />
                    </Labeled>
                  </Grid>
                  <Grid item xs={12} sm={12} md={3}>
                    <Labeled source="Type">
                      <TextField source="type" />
                    </Labeled>
                  </Grid>
                  <Grid item xs={12} sm={12} md={3}>
                    <Labeled source="Gender">
                      <TextField source="gender" />
                    </Labeled>
                  </Grid>
                  <Spacer />
                  <Grid item xs={12} sm={12} md={12}>
                    <Typography variant="h6" gutterBottom>
                      {translate('resources.employees.section.contacts')}
                    </Typography>
                    <Contacts />
                    <Spacer />
                    <Typography variant="h6" gutterBottom>
                      {translate('resources.employees.section.skills')}
                    </Typography>
                    <Skills />
                    <Spacer />
                    <Typography variant="h6" gutterBottom>
                      Salary
                    </Typography>
                    <Salary />
                    <Typography variant="h6" gutterBottom>
                      {translate('resources.employees.section.files')}
                    </Typography>
                    <Files />
                  </Grid>
                </Grid>
                <Spacer />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </Show>
  );
};
const EmployeeTitle = () => <FullNameEmployeeField size="32" sx={{ margin: '5px 0' }} />;
export default EmployeeShow;
