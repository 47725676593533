import {
  AutocompleteInput,
  Create,
  FormTab,
  NumberInput,
  ReferenceInput,
  required,
  TabbedForm,
  TextInput,
} from 'react-admin';

// {
//    "name": "test_bot",
//    "url": "http://127.0.0.1:9898",
//    "apiKey": "secret",
//    "fingerprint": "test_bot_1_uniq",
//    "exchangeId": 1
// }

const LiquiditybBotsCreate = (props: any) => (
  <Create {...props}>
    <TabbedForm>
      <FormTab
        label={
          <span>
            <span>Bot</span>
          </span>
        }
      >
        <TextInput autoFocus source="name" fullWidth validate={required()} />
        <TextInput autoFocus source="url" fullWidth validate={required()} />
        <TextInput autoFocus source="apiKey" fullWidth validate={required()} />
        <TextInput autoFocus source="fingerprint" fullWidth validate={required()} />
        <ReferenceInput
          label="Exchange"
          source="exchangeId"
          reference="exchanges"
          sort={{ field: 'name', order: 'ASC' }}
          alwaysOn
          pagination={false}
          perPage={1000}
        >
          <AutocompleteInput
            optionText="name"
            optionValue="id"
            defaultValue={''}
            validate={required()}
          />
        </ReferenceInput>
      </FormTab>
    </TabbedForm>
  </Create>
);

export default LiquiditybBotsCreate;
