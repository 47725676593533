import { Create, FormTab, required, TabbedForm, TextInput } from 'react-admin';

const BlockchainTypeCreate = () => {
  return (
    <Create>
      <TabbedForm>
        <FormTab label="resources.blockchaintype.tabs.main" sx={{ maxWidth: '40em' }}>
          <TextInput autoFocus source="name" fullWidth validate={required()} />
          <TextInput source="lastNotifierVersion" fullWidth />
        </FormTab>
      </TabbedForm>
    </Create>
  );
};

export default BlockchainTypeCreate;
