import {
  Create,
  FormTab,
  ReferenceInput,
  required,
  SelectInput,
  TabbedForm,
  TextInput,
} from 'react-admin';

const ExchangeInstanceCreate = () => (
  <Create>
    <TabbedForm>
      <FormTab label="resources.blockchaintype.tabs.main" sx={{ maxWidth: '40em' }}>
        <TextInput autoFocus source="url" fullWidth validate={required()} />
        <ReferenceInput
          label="Exchange"
          source="exchangeId"
          reference="exchanges"
          sort={{ field: 'name', order: 'ASC' }}
          alwaysOn
        >
          <SelectInput optionText="name" optionValue="id" defaultValue={''} validate={required()} />
        </ReferenceInput>
      </FormTab>
    </TabbedForm>
  </Create>
);

export default ExchangeInstanceCreate;
