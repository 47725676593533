import * as React from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { TableCellRight } from '../employees/TableCellRight';
import { useEffect } from 'react';

const getProjects = async () => {
  const projects = await fetch(`${process.env.REACT_APP_API_URL}/projects/analitics/all`, {
    method: 'GET',
    credentials: 'include',
    redirect: 'follow',
    headers: {
      'Content-Type': 'application/json',
    },
  });
  const data = projects.json();
  return data;
};

const getTotalAnalitics = async () => {
  const analitic = await fetch(`${process.env.REACT_APP_API_URL}/projects/analitics/total`, {
    method: 'GET',
    credentials: 'include',
    redirect: 'follow',
    headers: {
      'Content-Type': 'application/json',
    },
  });
  const data = analitic.json();
  return data;
};

const Spacer = () => <Box m={1}>&nbsp;</Box>;

async function deleteProject(id: number) {
  try {
    await fetch(`${process.env.REACT_APP_API_URL}/projects/${id}`, {
      method: 'DELETE',
      credentials: 'include',
      redirect: 'follow',
    });
    return null;
  } catch (e) {
    console.error('deleteProject error');
    return null;
  }
}

const ProjectsList = () => {
  const [projects, setProjects] = React.useState<any>([]);
  const [totalAnalitics, setTotalAnalitics] = React.useState<any>(null);

  // @ts-ignore
  useEffect(async () => {
    if (projects?.length === 0) {
      const projectsData = await getProjects();
      setProjects(projectsData ?? []);
    }

    if (totalAnalitics === null) {
      const totalAnaliticsData = await getTotalAnalitics();
      setTotalAnalitics(totalAnaliticsData);
    }
  }, []);

  async function deleteProjectHandler(id: number) {
    await deleteProject(id).then(async () => {
      const projectsData = await getProjects();
      setProjects(projectsData ?? []);
    });
  }

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Projects
      </Typography>
      <Spacer />
      <Table
        style={{
          width: '100%',
          marginTop: '20px',
          whiteSpace: 'nowrap',
          overflow: 'scroll',
        }}
      >
        <TableHead>
          <TableRow>
            <TableCell>TTL Employees</TableCell>
            <TableCellRight>TTL Candidates</TableCellRight>
            <TableCellRight>TTL Staff</TableCellRight>
            <TableCellRight>TTL Remote</TableCellRight>
            <TableCellRight>TTL Leavers</TableCellRight>
            <TableCellRight>TTL Costs</TableCellRight>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>{totalAnalitics?.employees?.employees}</TableCell>
            <TableCellRight>{totalAnalitics?.employees?.candidates}</TableCellRight>

            <TableCellRight>{totalAnalitics?.employees?.totalStaff}</TableCellRight>

            <TableCellRight>{totalAnalitics?.employees?.remote}</TableCellRight>

            <TableCellRight>{totalAnalitics?.employees?.leavers}</TableCellRight>

            <TableCellRight>{totalAnalitics?.costs?.total} USDT</TableCellRight>
          </TableRow>
        </TableBody>
      </Table>
      <Spacer />
      <Table
        style={{
          width: '100%',
          marginTop: '20px',
          whiteSpace: 'nowrap',
          overflow: 'scroll',
        }}
      >
        <TableHead>
          <TableRow>
            <TableCell>Project</TableCell>
            <TableCellRight>Employees</TableCellRight>
            <TableCellRight>Remote</TableCellRight>
            <TableCellRight>Candidates</TableCellRight>
            <TableCellRight>Leavers</TableCellRight>
            <TableCellRight>TTL Staff</TableCellRight>
            <TableCellRight>TTL Costs</TableCellRight>
            <TableCellRight></TableCellRight>
          </TableRow>
        </TableHead>
        <TableBody>
          {projects && projects?.length ? (
            projects.map((item: any) => {
              return (
                <TableRow key={item?.id}>
                  <TableCell>{item?.name}</TableCell>
                  <TableCellRight>{item?.analitics?.employees.employees}</TableCellRight>

                  <TableCellRight>{item?.analitics?.employees?.remote}</TableCellRight>

                  <TableCellRight>{item?.analitics?.employees?.candidates}</TableCellRight>

                  <TableCellRight>{item?.analitics?.employees?.leavers}</TableCellRight>

                  <TableCellRight>{item?.analitics?.employees?.totalStaff}</TableCellRight>
                  <TableCellRight>{item?.analitics?.costs?.total}</TableCellRight>
                  <TableCellRight>
                    <Button onClick={() => deleteProjectHandler(item?.id)}>Delete</Button>
                  </TableCellRight>
                </TableRow>
              );
            })
          ) : (
            <CircularProgress />
          )}
        </TableBody>
      </Table>
      <Spacer />
    </Box>
  );
};
export default ProjectsList;
