import * as React from 'react';
import { Card, CardContent } from '@mui/material';
import LocalOfferIcon from '@mui/icons-material/LocalOfferOutlined';
import { FilterList, FilterListItem, FilterLiveSearch, useListContext } from 'react-admin';

const Aside = (data: any) => {
  let positions = data.data;
  if (!Array.isArray(positions)) positions = [];

  return (
    <Card
      sx={{
        display: {
          xs: 'none',
          md: 'block',
        },
        order: -1,
        flex: '0 0 15em',
        mr: 2,
        mt: 8,
        alignSelf: 'flex-start',
      }}
    >
      <CardContent sx={{ pt: 1 }}>
        <FilterLiveSearch />

        <FilterList label="resources.employees.filters.position" icon={<LocalOfferIcon />}>
          {positions.map((item: any) => (
            <FilterListItem label={item.name} key={item.id} value={{ position: item.id }} />
          ))}
        </FilterList>

        <FilterList label="resources.employees.filters.status" icon={<LocalOfferIcon />}>
          {[
            { id: 'work', name: 'work' },
            { id: 'vacation', name: 'vacation' },
            { id: 'liberated', name: 'liberated' },
          ].map(segment => (
            <FilterListItem label={segment.name} key={segment.id} value={{ status: segment.id }} />
          ))}
        </FilterList>

        <FilterList label="resources.employees.filters.type" icon={<LocalOfferIcon />}>
          {[
            { id: 'employee', name: 'employee' },
            { id: 'candidate', name: 'candidate' },
          ].map(segment => (
            <FilterListItem label={segment.name} key={segment.id} value={{ type: segment.id }} />
          ))}
        </FilterList>

        <FilterList label="resources.employees.filters.gender" icon={<LocalOfferIcon />}>
          {[
            { id: 'male', name: 'men' },
            { id: 'female', name: 'woman' },
          ].map(segment => (
            <FilterListItem label={segment.name} key={segment.id} value={{ gender: segment.id }} />
          ))}
        </FilterList>
      </CardContent>
    </Card>
  );
};

export default Aside;
