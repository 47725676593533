import * as React from 'react';
import {
  Identifier,
  Datagrid,
  DateField,
  TextField,
  BulkDeleteButton,
  FunctionField,
} from 'react-admin';
import ProductReferenceField from '../products/ProductReferenceField';
import CustomerReferenceField from '../visitors/CustomerReferenceField';
import StarRatingField from './StarRatingField';
import rowStyle from './rowStyle';
import BulkAcceptButton from './BulkAcceptButton';
import BulkRejectButton from './BulkRejectButton';
import DateFnsUtils from '@date-io/date-fns';
import lv from 'date-fns/locale/fr';
import { dateFormat } from '../utils/helpers';

export interface ReviewListDesktopProps {
  selectedRow?: Identifier;
}

const ReviewsBulkActionButtons = () => (
  <>
    <BulkAcceptButton />
    <BulkRejectButton />
    <BulkDeleteButton />
  </>
);

const ReviewListDesktop = ({ selectedRow }: ReviewListDesktopProps) => (
  <Datagrid
    rowClick="edit"
    rowStyle={rowStyle(selectedRow)}
    optimized
    bulkActionButtons={<ReviewsBulkActionButtons />}
    sx={{
      '& .RaDatagrid-thead': {
        borderLeftColor: 'transparent',
        borderLeftWidth: 5,
        borderLeftStyle: 'solid',
      },
      '& .column-comment': {
        maxWidth: '18em',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },
    }}
  >
    <FunctionField
      source="date"
      label="date"
      render={(record: any) => {
        return dateFormat(record.date);
      }}
    />
    <CustomerReferenceField link={false} />
    <ProductReferenceField link={false} />
    <StarRatingField size="small" />
    <TextField source="comment" />
    <TextField source="status" />
  </Datagrid>
);

export default ReviewListDesktop;
