import * as React from 'react';
import {
  BooleanField,
  Datagrid,
  DateField,
  DateInput,
  FunctionField,
  List,
  NullableBooleanInput,
  NumberField,
  SearchInput,
} from 'react-admin';
import { useMediaQuery, Theme } from '@mui/material';
import SegmentsField from './SegmentsField';
import SegmentInput from './SegmentInput';
import CustomerLinkField from './CustomerLinkField';
import ColoredNumberField from './ColoredNumberField';
import MobileGrid from './MobileGrid';
import VisitorListAside from './VisitorListAside';
import DateFnsUtils from '@date-io/date-fns';
import lv from 'date-fns/locale/fr';
import { dateFormat } from '../utils/helpers';

const visitorFilters = [
  <SearchInput source="q" alwaysOn />,
  <DateInput source="last_seen_gte" />,
  <NullableBooleanInput source="has_ordered" />,
  <NullableBooleanInput source="has_newsletter" defaultValue />,
  <SegmentInput source="groups" />,
];

const VisitorList = () => {
  const isXsmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));
  const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('md'));
  return (
    <List
      filters={isSmall ? visitorFilters : undefined}
      sort={{ field: 'last_seen', order: 'DESC' }}
      perPage={25}
      aside={<VisitorListAside />}
    >
      {isXsmall ? (
        <MobileGrid />
      ) : (
        <Datagrid
          optimized
          rowClick="edit"
          sx={{
            '& .column-groups': {
              md: { display: 'none' },
              lg: { display: 'table-cell' },
            },
          }}
        >
          <CustomerLinkField />
          <FunctionField
            source="last_seen"
            label="last_seen"
            render={(record: any) => {
              return dateFormat(record.last_seen);
            }}
          />
          <NumberField
            source="nb_commands"
            label="resources.customers.fields.commands"
            sx={{ color: 'purple' }}
          />
          <ColoredNumberField
            source="total_spent"
            options={{ style: 'currency', currency: 'USD' }}
          />
          <FunctionField
            source="latest_purchase"
            label="latest_purchase"
            render={(record: any) => {
              return dateFormat(record.latest_purchase);
            }}
          />
          <BooleanField source="has_newsletter" label="News." />
          <SegmentsField
            // @ts-ignore
            source="groups"
          />
        </Datagrid>
      )}
    </List>
  );
};

export default VisitorList;
