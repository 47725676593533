import {
  CreateButton,
  Datagrid,
  EditButton,
  FunctionField,
  List,
  TextField,
  TopToolbar,
} from 'react-admin';

const BlockchainList = () => (
  <List actions={<ListActions />} sort={{ field: 'id', order: 'DESC' }}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="name" />
      <FunctionField label="Type" render={(record: any) => record?.blockchainType?.name} />
      <TextField source="nodeUrl" />
      <FunctionField
        label="Height"
        render={(record: any) =>
          Number.isNaN(+(record?.height ?? undefined)) ? 'N/A' : record?.height
        }
      />
      <TextField source="coreCoinSymbol" />
    </Datagrid>
  </List>
);

const ListActions = ({ isSmall }: any) => (
  <TopToolbar sx={{ minHeight: { sm: 56 } }}>
    <CreateButton />
    <EditButton />
  </TopToolbar>
);

export default BlockchainList;
