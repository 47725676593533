import {
  BooleanField,
  BooleanInput,
  Loading,
  NumberField,
  useRecordContext,
  useRedirect,
  useTranslate,
  useUpdate,
} from 'react-admin';
import Button from '@mui/material/Button';
import { useEffect, useState } from 'react';
import { Checkbox, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { styled } from '@mui/material/styles';
import ThumbUp from '@mui/icons-material/ThumbUp';
import ThumbDown from '@mui/icons-material/ThumbDown';
import { AlignVerticalCenter } from '@mui/icons-material';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { useDelete } from 'react-admin';

const styles = {
  flex: { display: 'flex' },
  flexColumn: { display: 'flex', flexDirection: 'column' },
  leftCol: { flex: 1, marginRight: '0.5em' },
  rightCol: { flex: 1, marginLeft: '0.5em' },
  singleCol: { marginTop: '1em', marginBottom: '1em' },
};

const TableCellRight = styled(TableCell)({ textAlign: 'right' });

const url = `${process.env.REACT_APP_API_URL}`;

const Grants = (data: any) => {
  const permissions = data.data;
  const record = useRecordContext<any>();
  const translate = useTranslate();

  if (!record) return null;

  const request_grants = new Request(`${url}/rbac_grants?filter={"resourceId":${record.id}}`, {
    method: 'GET',
    credentials: 'include',
    redirect: 'follow',
  });
  const [grants, setGrants]: any[] = useState('');

  useEffect(() => {
    (async () => {
      const response = await fetch(request_grants);
      const res = await response.json();
      setGrants(res);
    })();
  }, [setGrants]);
  if (!grants) return <Loading />;

  const handleOnChange = (grant_id: any) => {
    const request = new Request(`${url}/rbac_grants/${grant_id}`, {
      method: 'DELETE',
      credentials: 'include',
      redirect: 'follow',
    });
    (async () => {
      const response = await fetch(request);
      const res = await response.json();
      (async () => {
        const response = await fetch(request_grants);
        const res = await response.json();

        setGrants(res);
      })();
    })();
  };
  const handleOnChange2 = (roleId: any, permissionId: any) => {
    var urlencoded = new URLSearchParams();
    urlencoded.append('roleId', roleId);
    urlencoded.append('permissionId', permissionId);
    const request = new Request(`${url}/rbac_grants`, {
      method: 'POST',
      credentials: 'include',
      redirect: 'follow',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: urlencoded,
    });
    (async () => {
      const response = await fetch(request);
      const res = await response.json();
      (async () => {
        const response = await fetch(request_grants);
        const res = await response.json();
        setGrants(res);
      })();
    })();
  };

  const roles = [
    { id: 1, name: 'super' },
    { id: 2, name: 'admin' },
    { id: 3, name: 'hr' },
    { id: 4, name: 'blockchain' },
  ];

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Action</TableCell>

            <TableCellRight>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                Admin
              </div>
            </TableCellRight>
            <TableCellRight>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                HR
              </div>
            </TableCellRight>
            <TableCellRight>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                Blockchain
              </div>
            </TableCellRight>
          </TableRow>
        </TableHead>
        <TableBody>
          {permissions.map((item: any) => {
            const permissionRoleSuper = grants.find(
              (grant: any) => grant?.role?.name === 'super' && grant?.permission?.id === item.id,
            );
            const permissionRoleAdmin = grants.find(
              (grant: any) => grant?.role?.name === 'admin' && grant?.permission?.id === item.id,
            );
            const permissionRoleHr = grants.find(
              (grant: any) => grant?.role?.name === 'hr' && grant?.permission?.id === item.id,
            );
            const permissionRoleBlockchain = grants.find(
              (grant: any) =>
                grant?.role?.name === 'blockchain' && grant?.permission?.id === item.id,
            );

            return (
              <TableRow key={item.id}>
                <TableCell>{item.name}</TableCell>

                {/*
                            <TableCellRight>


                                {permissionRoleSuper?.id ? (
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <CheckIcon sx={{ color: 'green' }} />
                                        <Button variant="outlined" color="primary" size="small"
                                            onClick={() => handleOnChange(permissionRoleSuper?.id)}
                                        >
                                            {translate('resources.reviews.action.reject')}
                                        </Button>
                                    </div>

                                ) : (
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <ClearIcon sx={{ color: 'red' }} />
                                        <Button variant="outlined" color="primary" size="small"
                                            onClick={() => handleOnChange2(roles[0].id, item.id)}
                                        >
                                            {translate('resources.reviews.action.accept')}
                                        </Button>
                                    </div>
                                )}
                            </TableCellRight>
                */}
                <TableCellRight>
                  {permissionRoleAdmin?.id ? (
                    <div
                      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                    >
                      <CheckIcon sx={{ color: 'green' }} />
                      <Button
                        variant="outlined"
                        color="primary"
                        size="small"
                        onClick={() => handleOnChange(permissionRoleAdmin?.id)}
                      >
                        {translate('resources.reviews.action.reject')}
                      </Button>
                    </div>
                  ) : (
                    <div
                      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                    >
                      <ClearIcon sx={{ color: 'red' }} />
                      <Button
                        variant="outlined"
                        color="primary"
                        size="small"
                        onClick={() => handleOnChange2(roles[1].id, item.id)}
                      >
                        {translate('resources.reviews.action.accept')}
                      </Button>
                    </div>
                  )}
                </TableCellRight>
                <TableCellRight>
                  {permissionRoleHr?.id ? (
                    <div
                      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                    >
                      <CheckIcon sx={{ color: 'green' }} />
                      <Button
                        variant="outlined"
                        color="primary"
                        size="small"
                        onClick={() => handleOnChange(permissionRoleHr?.id)}
                      >
                        {translate('resources.reviews.action.reject')}
                      </Button>
                    </div>
                  ) : (
                    <div
                      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                    >
                      <ClearIcon sx={{ color: 'red' }} />
                      <Button
                        variant="outlined"
                        color="primary"
                        size="small"
                        onClick={() => handleOnChange2(roles[2].id, item.id)}
                      >
                        {translate('resources.reviews.action.accept')}
                      </Button>
                    </div>
                  )}
                </TableCellRight>
                <TableCellRight>
                  {permissionRoleBlockchain?.id ? (
                    <div
                      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                    >
                      <CheckIcon sx={{ color: 'green' }} />
                      <Button
                        variant="outlined"
                        color="primary"
                        size="small"
                        onClick={() => handleOnChange(permissionRoleBlockchain?.id)}
                      >
                        {translate('resources.reviews.action.reject')}
                      </Button>
                    </div>
                  ) : (
                    <div
                      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                    >
                      <ClearIcon sx={{ color: 'red' }} />
                      <Button
                        variant="outlined"
                        color="primary"
                        size="small"
                        onClick={() => handleOnChange2(roles[3].id, item.id)}
                      >
                        {translate('resources.reviews.action.accept')}
                      </Button>
                    </div>
                  )}
                </TableCellRight>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </>
  );
};

export default Grants;
