import * as React from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { Link, useTranslate, useRecordContext } from 'react-admin';
import { TableCellRight } from './TableCellRight';
import { IEmployee } from './FullNameEmployeeField';

const Files = () => {
  const record = useRecordContext<IEmployee>();
  const translate = useTranslate();
  if (!record) return null;
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>{translate('resources.files.fields.files.id')}</TableCell>
          <TableCell>{translate('resources.files.fields.files.action')}</TableCell>
          <TableCellRight>{translate('resources.files.fields.files.category')}</TableCellRight>
          <TableCellRight>{translate('resources.files.fields.files.size')}</TableCellRight>
        </TableRow>
      </TableHead>
      <TableBody>
        {record.files.map((item: any) => (
          <TableRow key={item.id}>
            <TableCell>{item.id}</TableCell>
            <TableCell>
              <Link to={`/files/${item.id}`}>view</Link>
            </TableCell>
            <TableCellRight>{item.category}</TableCellRight>
            <TableCellRight>{item.size}</TableCellRight>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default Files;
