import { Create, FormTab, required, TabbedForm, TextInput } from 'react-admin';

const ServerProviderCreate = () => (
  <Create>
    <TabbedForm>
      <FormTab label="resources.blockchaintype.tabs.main" sx={{ maxWidth: '40em' }}>
        <TextInput autoFocus source="name" fullWidth validate={required()} />
      </FormTab>
    </TabbedForm>
  </Create>
);

export default ServerProviderCreate;
