import { BooleanField, CreateButton, Datagrid, List, TextField, TopToolbar } from 'react-admin';

const UserList = () => (
  <List actions={<ListActions />}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="username" />
      <TextField source="email" />
      <BooleanField source="isActive" />

      <TextField source="role" />
    </Datagrid>
  </List>
);
const ListActions = () => (
  <TopToolbar sx={{ minHeight: { sm: 56 } }}>
    <CreateButton />
  </TopToolbar>
);
export default UserList;
