import { Card, CardContent, Grid } from '@mui/material';
import { Box } from '@mui/system';
import { Show, TextField } from 'react-admin';

const BlockchainShow = () => (
  <Show>
    <Box>
      <Card>
        <CardContent>
          <Grid container spacing={1} width={{ xs: '100%', xl: 700 }}>
            <Grid item xs={12} sm={12} md={12}>
              <TextField source="name" />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  </Show>
);
export default BlockchainShow;
