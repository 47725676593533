import { useEffect, useState } from 'react';
import {
  BooleanField,
  CreateButton,
  Datagrid,
  EditButton,
  FunctionField,
  List,
  Loading,
  Pagination,
  TextField,
  TopToolbar,
} from 'react-admin';
import ColoredBacklogFieldAnt from './ColoredBacklogFieldAnt';
import ColoredHealthField from './ColoredHealthField';
import ColoredRamFieldAnt from './ColoredRamFieldAnt';
import * as React from 'react';

const url = `${process.env.REACT_APP_API_URL}`;

const request = new Request(`${url}/blockchains`, {
  method: 'GET',
  credentials: 'include',
  redirect: 'follow',
});
const PostPagination = () => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} />;

const AntnodeList = () => {
  const [blockchains, setBlockchains] = useState('');
  useEffect(() => {
    (async () => {
      const response = await fetch(request);
      const res = await response.json();
      setBlockchains(res);
    })();
  }, [setBlockchains]);
  if (!blockchains) return <Loading />;
  return (
    <List
      actions={<ListActions />}
      sort={{ field: 'id', order: 'DESC' }}
      pagination={<PostPagination />}
    >
      <Datagrid rowClick="edit">
        <TextField label="Node" source="name" />
        <FunctionField
          label="Provider"
          render={(record: any) => `${record?.server?.provider?.name} - ${record?.server?.name}`}
        />

        <ColoredBacklogFieldAnt sortable={false} label="Block" source="height" />
        <FunctionField
          label="Ping"
          render={(record: any) => {
            if (Number.isNaN(+(record?.ping ?? undefined))) return 'N/A';
            return `${+record?.ping / 1000} ms`;
          }}
        />
        <FunctionField
          label="Response"
          render={(record: any) => {
            if (Number.isNaN(+(record?.responseTime ?? undefined))) return 'N/A';
            return `${+record?.responseTime} ms`;
          }}
        />
        <FunctionField
          label="Disk"
          render={(record: any) => {
            const left = Number.isNaN(+(record?.server?.homeMemoryUsage ?? undefined))
              ? 'N/A'
              : (record?.server?.homeMemoryUsage / 1024 / 1024 / 1024).toFixed(2);
            const right = Number.isNaN(+(record?.server?.homeMemoryTotal ?? undefined))
              ? 'N/A'
              : (record?.server?.homeMemoryTotal / 1024 / 1024 / 1024).toFixed(2);
            return `${Number(+right - +left).toFixed(2)} GB`;
          }}
        />
        <FunctionField
          label=" Root"
          render={(record: any) => {
            const left = Number.isNaN(+(record?.server?.rootMemoryUsage ?? undefined))
              ? 'N/A'
              : (record?.server?.rootMemoryUsage / 1024 / 1024 / 1024).toFixed(2);
            const right = Number.isNaN(+(record?.server?.rootMemoryTotal ?? undefined))
              ? 'N/A'
              : (record?.server?.rootMemoryTotal / 1024 / 1024 / 1024).toFixed(2);
            return `${Number(+right - +left).toFixed(2)} GB`;
          }}
        />

        <ColoredRamFieldAnt sortable={false} label="RAM" source="inPool" />
        <BooleanField sortable={false} source="inPool" />
        <TextField label="Version" source="version" />
        <ColoredHealthField label="Status" source="ping" />
      </Datagrid>
    </List>
  );
};

const ListActions = () => (
  <TopToolbar sx={{ minHeight: { sm: 56 } }}>
    <CreateButton />
    <EditButton />
  </TopToolbar>
);

export default AntnodeList;
