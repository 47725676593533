import { Edit, FormTab, required, TabbedForm, TextInput } from 'react-admin';

const BlockchainTypeEdit = () => {
  return (
    <Edit>
      <TabbedForm>
        <FormTab label="D" sx={{ maxWidth: '40em' }}>
          <TextInput autoFocus source="name" fullWidth validate={required()} />
          <TextInput source="lastNotifierVersion" fullWidth />
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

export default BlockchainTypeEdit;
