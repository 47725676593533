import { Theme, useMediaQuery } from '@mui/material';
import {
  CreateButton,
  Datagrid,
  DateField,
  EditButton,
  FunctionField,
  List,
  Loading,
  NullableBooleanInput,
  Pagination,
  SearchInput,
  TextField,
  TopToolbar,
  useListContext,
} from 'react-admin';
import FullNameEmployeeField from './FullNameEmployeeField';
import EmployeeListAside from './EmployeeListAside';
import { useEffect, useState } from 'react';
import * as React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import lv from 'date-fns/locale/fr';
import { dateFormat } from '../utils/helpers';

const employeeFilters = [
  <SearchInput source="q" alwaysOn />,
  <NullableBooleanInput source="has_ordered" />,
  <NullableBooleanInput source="has_newsletter" defaultValue />,
];

const url = `${process.env.REACT_APP_API_URL}`;

const request = new Request(`${url}/position`, {
  method: 'GET',
  credentials: 'include',
  redirect: 'follow',
});
const PostPagination = () => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} />;

const EmployeeList = () => {
  const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('md'));
  const [title, setTitle] = useState('');
  const [positions, setPositions] = useState('');
  useEffect(() => {
    (async () => {
      const response = await fetch(request);
      const res = await response.json();
      setPositions(res);
    })();
  }, [setPositions, setTitle]);
  if (!positions) return <Loading />;
  return (
    <List
      aside={<EmployeeListAside data={positions} />}
      title={title}
      filters={isSmall ? employeeFilters : undefined}
      actions={<ListActions />}
      sort={{ field: 'startDate', order: 'ASC' }}
      pagination={<PostPagination />}
    >
      <EmployeeDateField title={title} setTitle={setTitle} />
    </List>
  );
};

const EmployeeDateField = ({ setTitle }: any) => {
  const { filterValues } = useListContext();

  useEffect(() => {
    setTitle(filterValues?.showOnlyActive ? 'Employees' : 'Leavers');
  });
  return (
    <>
      <Datagrid rowClick="show" sort={{ field: 'startDate', order: 'ASC' }}>
        <FullNameEmployeeField label="Name" />
        <TextField source="nickname" />
        <TextField source="salary" />
        <FunctionField label="Currency" render={(record: any) => record.currency} />
        <FunctionField
          label="status"
          render={(record: any) => record.outstaffStatus ?? record.status}
        />
        <TextField source="type" />
        <TextField source="filesCount" />
        <FunctionField label="Position" render={(record: any) => record?.position?.name} />
        <FunctionField
          source="startDate"
          label={`${filterValues?.showOnlyActive ? 'Start Date' : 'End Data'}`}
          render={(record: any) => {
            if (filterValues?.showOnlyActive) {
              return dateFormat(record.startDate);
            }
            if (filterValues?.showOnlyInactive) {
              return dateFormat(record.endDate);
            }
          }}
        />
      </Datagrid>
    </>
  );
};

const ListActions = () => {
  const { filterValues } = useListContext();
  return (
    <TopToolbar sx={{ minHeight: { sm: 56 } }}>
      {filterValues?.showOnlyActive ? <CreateButton /> : null}
      <EditButton />
    </TopToolbar>
  );
};

export default EmployeeList;
