import PositionsIcon from '@mui/icons-material/Badge';
import PositionCreate from './PositionCreate';
import PositionEdit from './PositionEdit';
import PositionsList from './PositionsList';
import SkillsList from './PositionsList';

export default {
  list: PositionsList,
  icon: PositionsIcon,
  create: PositionCreate,
  edit: PositionEdit,
};
