import SkillsIcon from '@mui/icons-material/CollectionsBookmark';
import SkillCreate from './SkillCreate';
import SkillEdit from './SkillEdit';
import SkillsList from './SkillsList';

export default {
  list: SkillsList,
  icon: SkillsIcon,
  create: SkillCreate,
  edit: SkillEdit,
};
