import * as React from 'react';
import { Avatar, Box, Button, List, ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import CustomerIcon from '@mui/icons-material/PersonAdd';
import { Link } from 'react-router-dom';
import { useTranslate, useGetList } from 'react-admin';
import { subDays } from 'date-fns';
import CardWithIcon from './CardWithIcon';
import { Employee } from '../types';

const NewEmployees = () => {
  const translate = useTranslate();

  const aMonthAgo = subDays(new Date(), 30);
  aMonthAgo.setDate(aMonthAgo.getUTCDate() - 30);
  aMonthAgo.setHours(0);
  aMonthAgo.setMinutes(0);
  aMonthAgo.setSeconds(0);
  aMonthAgo.setMilliseconds(0);

  const { isLoading, data: visitors } = useGetList<Employee>('employees', {
    filter: {
      type: 'employee',
    },
    sort: { field: 'id', order: 'DESC' },
    pagination: { page: 1, perPage: 10 },
  });

  const nb = visitors ? visitors.reduce((nb: number) => ++nb, 0) : 0;
  return (
    <CardWithIcon
      to="/employees"
      icon={CustomerIcon}
      title={translate('pos.dashboard.new_employees')}
      subtitle={nb}
    >
      <List sx={{ display: isLoading ? 'none' : 'block' }}>
        {visitors
          ? visitors.map((record: Employee) => (
              <ListItem button to={`/employees/${record.id}`} component={Link} key={record.id}>
                <ListItemAvatar>
                  <Avatar src={`${record.avatar}?size=32x32`} />
                </ListItemAvatar>
                <ListItemText primary={`${record.firstname} ${record.lastname}`} />
              </ListItem>
            ))
          : null}
      </List>
      <Box flexGrow={1}>&nbsp;</Box>
      <Button
        sx={{ borderRadius: 0 }}
        component={Link}
        to="/employees"
        size="small"
        color="primary"
      >
        <Box p={1} sx={{ color: 'primary.main' }}>
          {translate('pos.dashboard.all_customers')}
        </Box>
      </Button>
    </CardWithIcon>
  );
};

export default NewEmployees;
