import {
  Edit,
  FormTab,
  ReferenceInput,
  required,
  SelectInput,
  TabbedForm,
  TextInput,
} from 'react-admin';

const BlockchainEdit = () => (
  <Edit>
    <TabbedForm>
      <FormTab label="D" sx={{ maxWidth: '40em' }}>
        <TextInput autoFocus source="name" fullWidth validate={required()} />
        <TextInput source="height" fullWidth />
        <TextInput validate={required()} source="nodeUrl" fullWidth />
        <ReferenceInput
          label="Blockchain type"
          source="blockchainTypeId"
          reference="blockchain_types"
          sort={{ field: 'name', order: 'ASC' }}
          alwaysOn
        >
          <SelectInput optionText="name" optionValue="id" defaultValue={''} />
        </ReferenceInput>
        <TextInput source="coreCoinSymbol" fullWidth validate={required()} />
      </FormTab>
    </TabbedForm>
  </Edit>
);

export default BlockchainEdit;
