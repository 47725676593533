import * as React from 'react';
import { useRecordContext, NumberField, NumberFieldProps, FunctionField } from 'react-admin';

const ColoredRamFieldNtf = (props: NumberFieldProps) => {
  const record = useRecordContext(props);
  if (!record || !props.source) {
    return null;
  }

  const left = Number.isNaN(+(record?.server?.ramUsage ?? undefined))
    ? 'N/A'
    : (record?.server?.ramUsage / 1024 / 1024).toFixed(2);
  const right = Number.isNaN(+(record?.server?.ramTotal ?? undefined))
    ? 'N/A'
    : (record?.server?.ramTotal / 1024 / 1024).toFixed(2);
  const ram = (parseInt(record?.server?.ramUsage) / parseInt(record?.server?.ramTotal)) * 100;
  const condition = ram > 80;
  const value = `${Number(+right - +left).toFixed(2)} GB`;
  const percent = `${ram.toFixed(2)}%`;
  if (ram > 80) {
    return (
      <FunctionField
        title={percent}
        sx={{ color: 'red' }}
        render={(record: any) => `${value} (${percent})`}
      />
    );
  } else if (ram > 75 && ram < 80) {
    return (
      <FunctionField
        title={percent}
        sx={{ color: 'orange' }}
        render={(record: any) => `${value}`}
      />
    );
  } else {
    return <FunctionField title={percent} render={(record: any) => `${value}`} />;
  }
};

ColoredRamFieldNtf.defaultProps = NumberField.defaultProps;

export default ColoredRamFieldNtf;
