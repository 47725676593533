import {
  Edit,
  number,
  ReferenceInput,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
} from 'react-admin';

const transform = (data: any) => {
  return {
    ...data,
    homeMemoryUsage: data?.homeMemoryUsage ? String(data?.homeMemoryUsage) : null,
    homeMemoryTotal: data?.homeMemoryTotal ? String(data?.homeMemoryTotal) : null,
    rootMemoryUsage: data?.rootMemoryUsage ? String(data?.rootMemoryUsage) : null,
    rootMemoryTotal: data?.rootMemoryTotal ? String(data?.rootMemoryTotal) : null,
    ramUsage: data?.ramUsage ? String(data?.ramUsage) : null,
    ramTotal: data?.ramTotal ? String(data?.ramTotal) : null,
    swapUsage: data?.swapUsage ? String(data?.swapUsage) : null,
    swapTotal: data?.swapTotal ? String(data?.swapTotal) : null,
  };
};
const ServerEdit = () => {
  return (
    <Edit transform={transform}>
      <SimpleForm>
        <TextInput autoFocus source="name" fullWidth validate={required()} />
        <TextInput source="monitoringUrl" fullWidth validate={required()} />
        <TextInput source="monitoringApiKey" fullWidth validate={required()} />

        <TextInput fullWidth validate={number()} source="homeMemoryUsage" />
        <TextInput fullWidth validate={number()} source="homeMemoryTotal" />
        <TextInput fullWidth validate={number()} source="rootMemoryUsage" />
        <TextInput fullWidth validate={number()} source="rootMemoryTotal" />
        <TextInput fullWidth validate={number()} source="ramUsage" />
        <TextInput fullWidth validate={number()} source="ramTotal" />
        <TextInput fullWidth validate={number()} source="swapUsage" />
        <TextInput fullWidth validate={number()} source="swapTotal" />
        <ReferenceInput
          label="Provider"
          source="providerId"
          reference="server_providers"
          sort={{ field: 'name', order: 'ASC' }}
          alwaysOn
        >
          <SelectInput optionText="name" optionValue="id" defaultValue={''} validate={required()} />
        </ReferenceInput>
      </SimpleForm>
    </Edit>
  );
};

export default ServerEdit;
