import * as React from 'react';
import { Table, TableBody, TableCell, TableRow } from '@mui/material';
import { useRecordContext } from 'react-admin';
import { TableCellRight } from './TableCellRight';
import { IEmployee } from './FullNameEmployeeField';

const Contacts = () => {
  const record = useRecordContext<IEmployee>();
  if (!record) return null;
  return (
    <Table>
      <TableBody>
        {record.properties.map((item: any) => (
          <TableRow key={item.propertyTypeId}>
            <TableCell>{item.propertyTypeName}</TableCell>
            <TableCellRight>{item.value}</TableCellRight>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default Contacts;
