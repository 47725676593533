import EmployeeList from './EmployeeList';
import EmployeeShow from './EmployeeShow';
import EmployeeCreate from './EmployeeCreate';
import EmployeeIcon from '@mui/icons-material/EmojiPeopleTwoTone';
import EmployeeEdit from './EmployeeEdit';

export default {
  list: EmployeeList,
  show: EmployeeShow,
  create: EmployeeCreate,
  edit: EmployeeEdit,
  icon: EmployeeIcon,
};
