import React from 'react';
import {
  ArrayInput,
  AutocompleteInput,
  Create,
  FormTab,
  NumberInput,
  ReferenceArrayInput,
  ReferenceInput,
  required,
  SelectArrayInput,
  SelectInput,
  SimpleFormIterator,
  TabbedForm,
  TextInput,
} from 'react-admin';
import { useParams } from 'react-router';
import { useLocation } from 'react-router-dom';
// example data for create form : {
//     "type": "Income",
//     "amount": "3000",
//     "currency": "USDT",
//     "total": "3000",
//     "description": "desc",
//     "proof": "txid",
//     "projectId": 1
// }

const CostsCreate = (props: any) => {
  const location = useLocation();
  console.log('location', location);
  // location?.search example "?projectId=5/create"
  const id = location?.search?.split('=')[1]?.split('/')[0];
  console.log('id', id);
  return (
    <Create
      // redirect to list after save
      {...props}
      redirect={'list'}
    >
      <TabbedForm>
        <FormTab label={<span>Cost create</span>}>
          <SelectInput
            source="type"
            defaultValue={'Income'}
            choices={[
              { id: 'Income', name: 'Income' },
              { id: 'Expense', name: 'Expense' },
            ]}
          />
          <TextInput autoFocus fullWidth source="amount" />

          <SelectInput
            source="currency"
            defaultValue={'USDT'}
            choices={[
              { id: 'BTC', name: 'BTC' },
              { id: 'USDT', name: 'USDT' },
              { id: 'EUR', name: 'EUR' },
              { id: 'UAH', name: 'UAH' },
              { id: 'USD', name: 'USD' },
              { id: 'SOL', name: 'SOL' },
              { id: 'CNB', name: 'CNB' },
              { id: 'QMALL', name: 'QMALL' },
              { id: 'TDX', name: 'TDX' },
              { id: 'AED', name: 'AED' },
            ]}
          />
          <TextInput
            autoFocus
            label={'Total USD'}
            placeholder={'Total USD'}
            fullWidth
            source="total"
          />
          <TextInput autoFocus fullWidth source="description" />
          <TextInput autoFocus fullWidth source="proof" />
          {/*<ReferenceInput*/}
          {/*  label='Project'*/}
          {/*  source='projectId'*/}
          {/*  reference='projects'*/}
          {/*  sort={{ field: 'name', order: 'ASC' }}*/}
          {/*  defaultValue={1}*/}
          {/*  validate={[]}*/}
          {/*>*/}
          {/*  <AutocompleteInput*/}
          {/*    optionText='name'*/}
          {/*    // @ts-ignore*/}
          {/*    optionValue='id'*/}
          {/*    parse={(v: any) => +v}*/}
          {/*    defaultValue={''}*/}
          {/*  />*/}
          {/*</ReferenceInput>*/}

          {/*<ReferenceArrayInput*/}
          {/*  label='Project' source='fundIds' reference='costs'>*/}
          {/*  <SelectArrayInput*/}
          {/*    defaultValue={id}*/}
          {/*    optionText='name' optionValue='id' />*/}
          {/*</ReferenceArrayInput>*/}
          {/* example with one <ReferenceInput*/}
          {/*  label='Project' source='fundId' reference='fund' alwaysOn>*/}
          {/*  <SelectInput*/}
          {/*    defaultValue={id}*/}
          {/*    optionText='name' optionValue='id' />*/}
          {/*</ReferenceInput>*/}
          {/*  need send multiply value   */}
          <ReferenceArrayInput
            label="Project"
            source="fundIds"
            reference="fund"
            defaultValue={id}
            validate={required()}
          >
            <SelectArrayInput optionText="name" optionValue="id" />
          </ReferenceArrayInput>
        </FormTab>
      </TabbedForm>
    </Create>
  );
};

export default CostsCreate;
