import {
  AutocompleteInput,
  Datagrid,
  DateInput,
  FilterLiveSearch,
  FunctionField,
  List,
  NullableBooleanInput,
  NumberField,
  ReferenceInput,
  SearchInput,
  SelectField,
  SelectInput,
  TextField,
  TextInput,
  useListContext,
} from 'react-admin';
import { Button } from '@mui/material';
import * as React from 'react';
import { Customer } from '../types';
import { useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { dateFormat } from '../utils/helpers';

function recordOneHandler(id: any) {
  try {
    fetch(`${process.env.REACT_APP_API_URL}/bot_trades/retrade/${+id}`, {
      method: 'POST',
      credentials: 'include',
      redirect: 'follow',
    });
    return null;
  } catch (e) {
    console.error('recordOneHandler error');
    return null;
  }
}

function tradeAllHandler(exchangeId: any) {
  try {
    fetch(`${process.env.REACT_APP_API_URL}/bot_trades/retrade/all`, {
      method: 'POST',
      credentials: 'include',
      redirect: 'follow',
      body: JSON.stringify({ exchangeId: +exchangeId }),
    });

    return null;
  } catch (e) {
    console.error('recordOneHandler error');
    return null;
  }
}
//
// function revertDiffHandler() {
//   try {
//     fetch(`${process.env.REACT_APP_API_URL}/bot_trades/analitics/reset`, {
//       method: 'POST',
//       credentials: 'include',
//       redirect: 'follow',
//     });
//     window.location.reload();
//     return null;
//   } catch (e) {
//     console.error('revertDiffHandler error');
//     return null;
//   }
// }

function TradesList() {
  const { data, isLoading } = useListContext();
  const [searchParams] = useSearchParams();
  const { exchangeId } = JSON.parse(searchParams.get('filter') || '{}');

  return (
    <>
      {data && data?.length > 0 && (
        <div
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            justifyItems: 'flex-end',
            background: 'transparent',
          }}
        >
          <div />
          <div>
            {/*<Button*/}
            {/*  style={{*/}
            {/*    margin: '2rem 0.5rem ',*/}
            {/*    marginLeft: 'auto',*/}
            {/*  }}*/}
            {/*  color='primary' onClick={revertDiffHandler}>*/}
            {/*  {' '}*/}
            {/*  Retrade all{' '}*/}
            {/*</Button>*/}
            <Button
              style={{
                margin: '0.5rem 0.5rem ',
                marginLeft: 'auto',
              }}
              color="primary"
              onClick={() => tradeAllHandler(exchangeId)}
            >
              {' '}
              Trade all{' '}
            </Button>
          </div>
        </div>
      )}

      <div>
        <Datagrid>
          <TextField source="id" />
          <FunctionField
            source="updatedAt"
            label="updatedAt"
            render={(record: any) => dateFormat(record.updatedAt)}
          />
          <TextField source="side" />
          <TextField source="pair" />
          <TextField source="amount" />
          <TextField source="value" />
          <TextField source="openPrice" />
          <TextField source="source" />
          <TextField source="closePrice" />
          <TextField source="closeFee" />
          <TextField source="totalDiff" />
          <FunctionField
            source="status"
            render={(record: any) => {
              if (record.status?.toLowerCase() === 'failed') {
                return (
                  <Button color="primary" onClick={() => recordOneHandler(record.id)}>
                    {' '}
                    Revert{' '}
                  </Button>
                );
              }
              return record.status;
            }}
          />
        </Datagrid>
      </div>
    </>
  );
}

const BotTradesList = (props: any) => {
  let botTradesFilters = [
    <TextInput label="Pair" source="pair" alwaysOn />,
    <SelectInput
      source="side"
      choices={[
        { id: 'Bid', name: 'Bid' },
        { id: 'Ask', name: 'Ask' },
      ]}
    />,
    <SelectInput
      source="status"
      choices={[
        { id: 'Traded', name: 'Traded' },
        { id: 'Pending', name: 'Pending' },
        { id: 'Failed', name: 'Failed' },
      ]}
    />,
  ];
  return (
    <List title="Bot Trades" filters={botTradesFilters} {...props}>
      <TradesList />
    </List>
  );
};

export default BotTradesList;
