import { Box, Button, Card, CardContent, Grid, MenuItem, Select, Typography } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import {
  ArrayInput,
  AutocompleteInput,
  Datagrid,
  DateField,
  DateInput,
  Edit,
  EditButton,
  FileField,
  FileInput,
  FormTab,
  FunctionField,
  Labeled,
  NumberField,
  ReferenceArrayInput,
  ReferenceInput,
  ReferenceManyField,
  required,
  SaveButton,
  SelectArrayInput,
  SelectInput,
  SimpleFormIterator,
  TabbedForm,
  TextField,
  TextInput,
  Toolbar,
  useRecordContext,
  useTranslate,
} from 'react-admin';
import { TextField as MuiTextField } from '@mui/material';
import { File } from '../types';
import FullNameEmployeeField from './FullNameEmployeeField';
import Poster2 from './Poster2';
import * as React from 'react';
import EmployeeBonuses from '../components/EmployeeBonuses';
import { useEffect } from 'react';
import { useParams } from 'react-router';
import { dateFormat, dateFormatter, dateParser } from '../utils/helpers';
import DateFnsUtils from '@date-io/date-fns';
import lv from 'date-fns/locale/fr';
import { isP } from '../components/helpers';

const addEmployeeToProject = async (loadLvl: number, projectId: string, employeeId: string) => {
  const url = `${process.env.REACT_APP_API_URL}/employee_projects/`;
  const body = {
    load: Number(loadLvl),
    projectId: projectId,
    employeeId: employeeId,
  };
  const data = await fetch(url, {
    method: 'POST',
    credentials: 'include',
    redirect: 'follow',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });
  const result = await data.json();
  return result;
};

const getProjects = async () => {
  const projects = await fetch(`${process.env.REACT_APP_API_URL}/projects?filter=[]`, {
    method: 'GET',
    credentials: 'include',
    redirect: 'follow',
    headers: {
      'Content-Type': 'application/json',
    },
  });
  const data = projects.json();
  return data;
};

function EmployeeProjectsList(props: {  employeeId: string | undefined }) {
  const { employeeId } = props;
  return (
    <ReferenceManyField
      reference="employee_projects"
      target="employeeId"
      sort={{ field: 'id', order: 'ASC' }}
      filter={{ employeeId: employeeId }}
      {...props}
    >
      <Datagrid>
        <TextField source="id" />
        <TextField source="projectId" />
        <TextField source="load" />
        <TextField source="project.name" />
      </Datagrid>
    </ReferenceManyField>
  );
}

export const usePlaceholderStyles = makeStyles((theme: any) => ({
  placeholder: {
    color: '#aaa',
  },
}));

const Placeholder = ({ children }: any) => {
  const classes: any = usePlaceholderStyles();
  return <div className={classes.placeholder}>{children}</div>;
};

function AddEmployeeToProj() {
  const { id } = useParams();
  const [loadLvl, setLoadLvl] = React.useState(0);

  const setLoadLvlHandler = (e: any) => {
    if (e.target.value.startsWith('0')) {
      e.target.value = e.target.value.replace(/^0+/, '');
    }

    if (e.target.value > 100) {
      return;
    }
    setLoadLvl(e.target.value);
  };

  const [projects, setProjects] = React.useState([]);
  const [projectName, setProjectName] = React.useState('');
  const setProjectNameHandler = (e: any) => {
    setProjectName(e.target.value);
  };

  // @ts-ignore
  useEffect(async () => {
    const proj = await getProjects();
    setProjects(proj);
  }, []);

  async function handleSend() {
    await addEmployeeToProject(loadLvl, projectName as string, id as string);
    setLoadLvl(0);
    setProjectName('');
  }

  return projects && projects?.length > 0 ? (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '100%',
      }}
    >
      <Box
        style={{
          alignItems: 'center',
          display: 'flex',
          gap: 25,
          marginBottom: 20,
        }}
      >
        <Select
          value={projectName}
          displayEmpty
          renderValue={projectName !== '' ? undefined : () => <Placeholder>Project</Placeholder>}
          onChange={setProjectNameHandler}
        >
          {projects.map((project: any) => {
            return (
              <MenuItem value={project.id} key={project.id}>
                {project.name}
              </MenuItem>
            );
          })}
        </Select>

        {projectName !== null && projectName !== '' ? (
          <MuiTextField label={'Load level'} value={loadLvl} onChange={setLoadLvlHandler} />
        ) : null}

        {loadLvl && projectName && id ? (
          <Button onClick={handleSend} variant="contained" color="primary">
            Add
          </Button>
        ) : null}
      </Box>

          <EmployeeProjectsList employeeId={id } />
    </Box>
  ) : null;
}

const EmployeeEditToolbar = (props: any) => (
  <Toolbar {...props} >
      <SaveButton disabled={props.pristine} />
  </Toolbar>
);

const EmployeeEdit = () => {
  console.log('isRule',isP('employee_projects@filter'));
  return (
    <Edit title={<EmployeeTitle />}>
      <TabbedForm
        defaultValues={{
          endDate: null,
          startDate: null,
        }}
        toolbar={<EmployeeEditToolbar/>}
      >
        <FormTab label="Common" sx={{ maxWidth: '40em' }} >
          <TextInput autoFocus source="firstname" fullWidth validate={required()} />
          <TextInput source="lastname" fullWidth validate={required()} />
          <TextInput source="futhername" fullWidth />
          <TextInput source="nickname" fullWidth />
          <DateInput
            required={false}
            defaultValue={null}
            format={dateFormatter}
            parse={dateParser}
            source="birthDate"
          />
          <SelectInput
            source="type"
            validate={required()}
            defaultValue={'candidate'}
            choices={[
              { id: 'candidate', name: 'Candidate' },
              {
                id: 'employee',
                name: 'Employee',
              },
              { id: 'remote', name: 'Remote' },
            ]}
          />
          <SelectInput
            source="gender"
            validate={required()}
            defaultValue={''}
            choices={[
              { id: 'male', name: 'Male' },
              { id: 'female', name: 'Female' },
            ]}
          />
          <Box>
            <Box
              style={{
                color: 'rgba(255, 255, 255, 0.7)',
                fontWeight: 400,
                fontSize: '0.8rem',
                lineHeight: '1.4375em',
                letterSpacing: '0.00938em',
                padding: 0,
              }}
            >
              Status
            </Box>
            <Box>
              <FunctionField
                style={{
                  fontWeight: 400,
                  fontSize: '1.2rem',
                  lineHeight: '1.4375em',
                  letterSpacing: '0.00938em',
                  padding: 0,
                }}
                render={(record: any) => `${record.outstaffStatus ?? record.status}`}
              />
            </Box>
          </Box>
        </FormTab>
        <FormTab label="Contact" sx={{ maxWidth: '40em' }}>
          <ArrayInput source="properties" label="Contacts">
            <SimpleFormIterator>
              <ReferenceInput
                label="status"
                source="propertyTypeId"
                reference="properties"
                sort={{ field: 'name', order: 'ASC' }}
                alwaysOn
                allowEmpty
              >
                <SelectInput optionText="name" optionValue="id" defaultValue={''} />
              </ReferenceInput>

              <TextInput source="value" />
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
        <FormTab label="Salary" sx={{ maxWidth: '40em' }}>
          <SelectInput
            source="paymentType"
            validate={required()}
            choices={[
              { id: 'Cash', name: 'Cash' },
              { id: 'Card', name: 'Card' },
              { id: 'Crypto', name: 'Crypto' },
            ]}
          />
          <TextInput source="paymentDetails" fullWidth />

          <DateInput
            required={false}
            source="startDate"
            defaultValue={null}
            format={dateFormatter}
            parse={dateParser}
          />

          <DateInput
            required={false}
            source="endDate"
            defaultValue={null}
            format={dateFormatter}
            parse={dateParser}
          />

          <EmployeeBonuses />
        </FormTab>
        <FormTab label="Quality">
          <ReferenceInput
            flex={1}
            label="Position"
            source="positionId"
            reference="position"
            pagination={false}
            perPage={1000}
          >
            <AutocompleteInput optionText="name" optionValue="id" />
          </ReferenceInput>

          <ReferenceArrayInput source="skillIds" reference="skills">
            <SelectArrayInput optionText="name" />
          </ReferenceArrayInput>

          {isP('employee_projects@filter') || isP('employee_projects') && (<AddEmployeeToProj />)}
        </FormTab>
        <FormTab label="Files" sx={{ maxWidth: '80em' }}>
          <FileInput source="file" label="Related files">
            <FileField source="src" title="title" />
          </FileInput>
          <ReferenceInput
            label="File category"
            source="file.category"
            reference="files/categories/list"
            page={1}
            perPage={10}
            sort={{ field: 'name', order: 'ASC' }}
            alwaysOn
            allowEmpty
          >
            <SelectInput optionText="value" optionValue="id" defaultValue={''} />
          </ReferenceInput>
          <Labeled label="resources.employees.fields.files" fullWidth>
            <ReferenceManyField reference="files" target="employee_id" perPage={10}>
              <Datagrid
                rowClick="expand"
                expand={<EmployeeFileShow />}
                sx={{
                  '& .column-thumbnail': {
                    width: 25,
                    padding: 0,
                  },
                }}
              >
                <NumberField source="id" />
                <TextField source="key" />
                <FunctionField
                  source="createdAt"
                  label="createdAt"
                  render={(record: any) => {
                    return dateFormat(record.createdAt);
                  }}
                />
                <TextField source="category" />
                <TextField source="mimetype" />
                <TextField source="size" />
                <EditButton />
              </Datagrid>
            </ReferenceManyField>
          </Labeled>
        </FormTab>
      </TabbedForm>
    </Edit>
  );
}

const EmployeeTitle = () => <FullNameEmployeeField size="32" sx={{ margin: '5px 0' }} />;

const EmployeeFileShow = () => {
  const record = useRecordContext<File>();
  if (!record) return null;
  return (
    <Card sx={{ width: 1000, margin: 'auto' }}>
      <CardContent>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom align="left">
              Key: {record.key}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Poster2 />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default EmployeeEdit;
