import {
  AutocompleteInput,
  Edit,
  FormTab,
  ReferenceInput,
  required,
  TabbedForm,
  TextInput,
} from 'react-admin';

const LiquiditybBotsEdit = () => (
  <Edit>
    <TabbedForm>
      <FormTab
        label={
          <span>
            <span>Bot</span>
          </span>
        }
      >
        <TextInput autoFocus source="name" fullWidth validate={required()} />
        <TextInput autoFocus source="url" fullWidth validate={required()} />
        <TextInput autoFocus source="apiKey" fullWidth validate={required()} />
        <TextInput autoFocus source="fingerprint" fullWidth validate={required()} />
        <ReferenceInput
          label="Exchange"
          source="exchangeId"
          reference="exchanges"
          sort={{ field: 'name', order: 'ASC' }}
          alwaysOn
          pagination={false}
          perPage={1000}
        >
          <AutocompleteInput
            optionText="name"
            optionValue="id"
            defaultValue={''}
            validate={required()}
          />
        </ReferenceInput>
      </FormTab>
    </TabbedForm>
  </Edit>
);

export default LiquiditybBotsEdit;
