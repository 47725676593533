import * as React from 'react';
import {
  DateField,
  useTranslate,
  useGetList,
  RecordContextProvider,
  useLocaleState,
  useRecordContext,
  FunctionField,
} from 'react-admin';
import {
  Typography,
  Card,
  CardContent,
  Box,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Grid,
} from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

import order from '../orders';
import review from '../reviews';
import { Order as OrderRecord, Review as ReviewRecord, Customer, UserAction } from '../types';
import { IEmployee } from './FullNameEmployeeField';
import DateFnsUtils from '@date-io/date-fns';
import lv from 'date-fns/locale/fr';
import { dateFormat } from '../utils/helpers';

const Aside = () => {
  const record = useRecordContext<IEmployee>();
  return (
    <Box width={400} display={{ xs: 'none', lg: 'block' }}>
      {record && <EventList />}
    </Box>
  );
};

const EventList = () => {
  const record = useRecordContext<Customer>();
  const translate = useTranslate();
  const [locale] = useLocaleState();

  const { data: user_actions } = useGetList<UserAction>('users/actions/list', {
    pagination: { page: 1, perPage: 10 },
    sort: { field: 'date', order: 'DESC' },
    filter: { identity: record.id },
  });

  const events = mixOrdersAndReviews(user_actions);

  return (
    <Box ml={2}>
      <Card>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            {translate('resources.customers.fieldGroups.history')}
          </Typography>
          <Grid container rowSpacing={1} columnSpacing={1}>
            <Grid item xs={6} display="flex" gap={1}>
              <AccessTimeIcon fontSize="small" color="disabled" />
              <Box flexGrow={1}>
                <Typography variant="body2">
                  {translate('resources.employees.fields.created')}
                </Typography>
                <FunctionField
                  source="createdAt"
                  label="createdAt"
                  render={(record: any) => {
                    return dateFormat(record.createdAt);
                  }}
                />{' '}
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <Stepper orientation="vertical" sx={{ mt: 1 }}>
        {events.map(event => (
          <Step key={`${event.type}-${event.data.id}`} expanded active completed>
            <StepLabel
              icon={
                event.type === 'order' ? (
                  <order.icon color="disabled" sx={{ pl: 0.5, fontSize: '1.25rem' }} />
                ) : (
                  <review.icon color="disabled" sx={{ pl: 0.5, fontSize: '1.25rem' }} />
                )
              }
            >
              {new Date(event.date).toLocaleString(locale, {
                weekday: 'long',
                year: 'numeric',
                month: 'short',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
              })}
            </StepLabel>
            <StepContent>
              <RecordContextProvider value={event.data}>
                {event.type === 'action' && <UsrAction />}
              </RecordContextProvider>
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

interface AsideEvent {
  type: string;
  date: Date;
  data: OrderRecord | ReviewRecord | UserAction;
}

const mixOrdersAndReviews = (user_actions?: UserAction[]): AsideEvent[] => {
  const eventsFromActions = user_actions
    ? user_actions.map<AsideEvent>(action => ({
        type: 'action',
        date: action.dateTime,
        data: action,
      }))
    : [];
  return eventsFromActions;
};

const UsrAction = () => {
  const record = useRecordContext();
  const translate = useTranslate();
  if (!record) return null;
  let headline = '';
  let value = '';
  if (record.action === 'files.post') {
    headline = 'upload file';
    value = record.payload.meta.category;
  }
  if (record.action === 'employee.update.status') {
    headline = 'status change';
    value = record.payload.value;
  }
  if (record.action === 'employee.create') {
    headline = 'create';
    value = record.payload.value;
  }
  return (
    <>
      {headline}: <strong>{value}</strong>
    </>
  );
};

export default Aside;
