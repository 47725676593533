import StorageIcon from '@mui/icons-material/Storage';
import ServerProviderCreate from './ServerProviderCreate';
import ServerProviderEdit from './ServerProviderEdit';
import ServerProviderList from './ServerProviderList';

export default {
  list: ServerProviderList,
  create: ServerProviderCreate,
  icon: StorageIcon,
  edit: ServerProviderEdit,
};
