import SkillsIcon from '@mui/icons-material/CollectionsBookmark';
import EPropsCreate from './EPropsCreate';
import EPropsEdit from './EPropsEdit';
import EPropsList from './EPropsList';

export default {
  list: EPropsList,
  icon: SkillsIcon,
  edit: EPropsEdit,
  create: EPropsCreate,
};
