import * as React from 'react';
import {
  Create,
  FormTab,
  TabbedForm,
  TextInput,
  required,
  ReferenceInput,
  SelectInput,
} from 'react-admin';
import { RichTextInput } from 'ra-input-rich-text';

const RbacPermissionCreate = () => (
  <Create>
    <TabbedForm>
      <FormTab label="resources.rbac_permission.tabs.main" sx={{ maxWidth: '40em' }}>
        <TextInput autoFocus source="name" fullWidth validate={required()} />
        <ReferenceInput
          label="Resource"
          source="resourceId"
          reference="rbac_resources"
          sort={{ field: 'name', order: 'ASC' }}
          alwaysOn
        >
          <SelectInput optionText="name" optionValue="id" defaultValue={''} validate={required()} />
        </ReferenceInput>
        <RichTextInput source="description" label="" />
      </FormTab>
    </TabbedForm>
  </Create>
);

export default RbacPermissionCreate;
