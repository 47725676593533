import * as React from 'react';
import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import GroupsIcon from '@mui/icons-material/Groups';
import SensorOccupiedIcon from '@mui/icons-material/SensorOccupied';
import SavingsIcon from '@mui/icons-material/Savings';
import {
  useTranslate,
  DashboardMenuItem,
  MenuItemLink,
  MenuProps,
  useSidebarState,
  usePermissions,
} from 'react-admin';
import PaymentsIcon from '@mui/icons-material/Payments';
import CompassCalibrationIcon from '@mui/icons-material/CompassCalibration';
import visitors from '../visitors';
import users from '../users';
import employees from '../employees';
import SubMenu from './SubMenu';
import Files from '../files';
import Skills from '../skills';
import Positions from '../positions';
import Exchanges from '../exchange/exchanges';
import Blockchains from '../exchange/blockchains';
import Blockchain_types from '../exchange/blockchain_types';
import Server_providers from '../exchange/server_providers';
import Notifiers from '../exchange/notifiers';
import Antnodes from '../exchange/antnodes';
import Exchange_instances from '../exchange/exchange_instances';
import Servers from '../exchange/servers';
import Resources from '../rbac/resources';
import Permissions from '../rbac/permissions_rbac';
import { useNavigate } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import { isP } from '../components/helpers';
import AttractionsIcon from '@mui/icons-material/Attractions';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import { on } from '../utils/events';

const getProjects = async () => {
  try {
    const projects = await fetch(`${process.env.REACT_APP_API_URL}/projects?filter=[]`, {
      method: 'GET',
      credentials: 'include',
      redirect: 'follow',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const data = projects.json();
    return data;
  } catch (error) {
    return [];
  }
};
const getFunds = async () => {
  try {
    const projects = await fetch(`${process.env.REACT_APP_API_URL}/fund?filter=[]`, {
      method: 'GET',
      credentials: 'include',
      redirect: 'follow',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const data = projects.json();
    return data;
  } catch (error) {
    return [];
  }
};

const getExchanges = async () => {
  try {
    const exchanges = await fetch(`${process.env.REACT_APP_API_URL}/exchanges/`, {
      method: 'GET',
      credentials: 'include',
      redirect: 'follow',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const data = exchanges.json();
    return data;
  } catch (e) {
    return [];
  }
};

async function getBotTrades() {
  try {
    const botTrades = await fetch(`${process.env.REACT_APP_API_URL}/exchanges/`, {
      method: 'GET',
      credentials: 'include',
      redirect: 'follow',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const data = botTrades.json();
    return data;
  } catch (e) {
    return [];
  }
}

type MenuName =
  | 'menuProperties'
  | 'costsAnalytics'
  | 'menuCatalog'
  | 'menuSales'
  | 'menuCustomers'
  | 'menuEmployees'
  | 'menuFiles'
  | 'menuProjects'
  | 'menuNotifiers'
  | 'botTrades'
  | 'menuSkills'
  | 'menuSystem'
  | 'menuPosition'
  | 'menuExchanges'
  | 'menuRbac';

var refresh = 0;

const Menu = ({ dense = false }: MenuProps) => {
  const [funds, setFunds] = useState<any>([]);
  const [projects, setProjects] = useState<any>([]);
  const [exchanges, setExchanges] = useState([]);
  const [botTrades, setBotTrades] = useState([]);
  const [state, setState] = useState({
    menuCatalog: true,
    menuSales: true,
    menuCustomers: true,
    menuEmployees: true,
    costsAnalytics: false,
    menuFiles: true,
    menuProjects: false,
    menuNotifiers: false,
    botTrades: false,
    menuSkills: true,
    menuSystem: false,
    menuPosition: true,
    menuProperties: true,
    menuExchanges: true,
    menuRbac: false,
  });

  const timer = React.useRef<any>();
  const userRole = localStorage.getItem('role') ?? ('' as string);
  const navigate = useNavigate();
  const translate = useTranslate();
  const [open] = useSidebarState();
  const { permissions } = usePermissions();

  // @ts-ignore
  useEffect(async () => {
    if ((userRole === 'super' && isP('projects')) || isP('projects@analytics')) {
      const projectsArr = await getProjects();
      setProjects(projectsArr);
    }
    if (isP('fund')) {
      const fundsArr = await getFunds();
      setFunds(fundsArr);
    }

    if (isP('exchanges')) {
      const exchangesArr = await getExchanges();
      setExchanges(exchangesArr);
    }
    if (isP('bot_trades')) {
      const botTradesArr = await getBotTrades();
      setBotTrades(botTradesArr);
    }

  }, []);

  const updateMenuHandler =async (data:any) => {
    if ((userRole === 'super' && isP('projects')) || isP('projects@analytics')) {
      const projectsArr = await getProjects();
      setProjects(projectsArr);
      const fundsArr = await getFunds();
      setFunds(fundsArr);
    }

    if (isP('exchanges')) {
      const exchangesArr = await getExchanges();
      setExchanges(exchangesArr);
    }
    if (isP('bot_trades')) {
      const botTradesArr = await getBotTrades();
      setBotTrades(botTradesArr);
    }
  };

  useEffect(() => {
    on('updateMenu', updateMenuHandler);
  }, []);

  const handleToggle = (menu: MenuName) => {
    setState(state => ({ ...state, [menu]: !state[menu] }));
  };

  return permissions?.length ? (
    <Box
      sx={{
        width: open ? 200 : 50,
        marginTop: 1,
        marginBottom: 1,
        transition: theme =>
          theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
      }}
    >
      <DashboardMenuItem />
      {isP('employee_outstaff') && (
        <MenuItemLink
          to="/custom_outstaff"
          state={{ _scrollToTop: true }}
          primaryText="Outstaff"
          leftIcon={<SensorOccupiedIcon />}
          dense={dense}
        />
      )}

      {/* TODO: add page with exchanges */}
      {/*{isP('exchange_pairs') && (*/}
      {/*  <MenuItemLink*/}
      {/*    to="/exchange_pairs"*/}
      {/*    state={{ _scrollToTop: true }}*/}
      {/*    primaryText="Exchange Pairs"*/}
      {/*    leftIcon={<AttractionsIcon />}*/}
      {/*    dense={dense}*/}
      {/*  />*/}
      {/*)}*/}

      {isP('users') && (
        <MenuItemLink
          to="/users"
          state={{ _scrollToTop: true }}
          primaryText={translate(`resources.users.name`, {
            smart_count: 2,
          })}
          leftIcon={<visitors.icon />}
          dense={dense}
        />
      )}
      {isP('files') && (
        <MenuItemLink
          to="/files"
          state={{ _scrollToTop: true }}
          primaryText={translate(`resources.files.name`, {
            smart_count: 2,
          })}
          leftIcon={<Files.icon />}
          dense={dense}
        />
      )}

      {
        // @ts-ignore
        isP('employees@analytics') | (userRole === 'super') && isP('employees') ? (
          <MenuItemLink
            to="/custom_payments"
            state={{ _scrollToTop: true }}
            primaryText={translate(`resources.payments.name`)}
            dense={dense}
            leftIcon={<PaymentsIcon />}
          />
        ) : null
      }
      {isP('employees') && (
        <>
          <MenuItemLink
            to="/employees?displayedFilters=%7B%22showOnlyActive%22%3Atrue%2C%22showOnlyInactive%22%3Afalse%7D&filter=%7B%22showOnlyActive%22%3Atrue%2C%22showOnlyInactive%22%3Afalse%7D"
            state={{ _scrollToTop: true }}
            primaryText={translate(`resources.employees.name`, {
              smart_count: 2,
            })}
            leftIcon={<employees.icon />}
            dense={dense}
          />
          <MenuItemLink
            to="/employees?displayedFilters=%7B%22showOnlyActive%22%3Afalse%2C%22showOnlyInactive%22%3Atrue%7D&filter=%7B%22showOnlyActive%22%3Afalse%2C%22showOnlyInactive%22%3Atrue%7D"
            state={{ _scrollToTop: true }}
            primaryText={'Leavers'}
            leftIcon={<PersonRemoveIcon />}
            dense={dense}
          />
        </>
      )}

      {userRole === 'super' && isP('projects') | isP('projects@analytics') ? (
        <SubMenu
          handleToggle={() => {
            handleToggle('menuProjects');
            navigate('/projectsList');
          }}
          isOpen={state.menuProjects}
          name="Projects"
          icon={<GroupsIcon />}
          dense={dense}
        >
          {projects && projects?.length
            ? projects.map((p: any) => {
                return (
                  <MenuItemLink
                    key={p.id}
                    to={`/projectsList/${p.id}`}
                    state={{ _scrollToTop: true }}
                    primaryText={`${p.name}`}
                    dense={dense}
                  />
                );
              })
            : null}
        </SubMenu>
      ) : null}

      {isP('antnodes') && (
        <MenuItemLink
          to="/antnodes"
          state={{ _scrollToTop: true }}
          primaryText={translate(`resources.antnodes.name`, {
            smart_count: 2,
          })}
          leftIcon={<Antnodes.icon />}
          dense={dense}
        />
      )}

      {isP('exchanges') && (
        <SubMenu
          handleToggle={() => {
            handleToggle('menuNotifiers');
            navigate({
              pathname: '/notifireAnalistic',
            });
          }}
          isOpen={state.menuNotifiers}
          name={translate(`resources.notifiers.name`, {
            smart_count: 2,
          })}
          icon={<Notifiers.icon />}
          dense={dense}
        >
          {exchanges.map((p: any) => (
            <MenuItemLink
              key={p?.id}
              primaryText={p?.name}
              to=""
              onClick={e => {
                e.preventDefault();
                navigate({
                  pathname: '/notifiers',
                  search: `?displayedFilters=%7B%7D&filter=%7B"exchangeId"%3A"${p?.id}"%7D&order=DESC&page=1&perPage=10&sort=id`,
                });
              }}
            />
          ))}
        </SubMenu>
      )}

      {isP('costs') && (
        <SubMenu
          handleToggle={() => {
            handleToggle('costsAnalytics');
            navigate({
              pathname: '/costsAnalytics',
            });
          }}
          isOpen={state.costsAnalytics}
          name={'Costs'}
          icon={<SavingsIcon />}
          dense={dense}
        >
          {isP('fund') && (
            <>
              {funds.map((p: any) => (
                <MenuItemLink
                  key={p?.id}
                  primaryText={p?.name}
                  to=""
                  onClick={e => {
                    e.preventDefault();
                    navigate({
                      pathname: '/costs',
                      search: `?displayedFilters=%7B%7D&filter=%7B"fundId"%3A${p?.id}%7D`,
                    });
                  }}
                />
              ))}
            </>
          )}
        </SubMenu>
      )}

      {isP('bot_trades') && (
        <SubMenu
          handleToggle={() => {
            handleToggle('botTrades');
            navigate({
              pathname: '/botTrades',
            });
          }}
          isOpen={state.botTrades}
          name="Trading Bots	"
          icon={<SmartToyIcon />}
          dense={dense}
        >
          {botTrades.map((p: any) => (
            <MenuItemLink
              key={p?.id}
              primaryText={p?.name}
              to={`bot_trades?filter={"exchangeId":${p?.id}}`}
            />
          ))}
        </SubMenu>
      )}

      {isP('notifier_exchange_report') && (
        <MenuItemLink
          to="/ping"
          state={{ _scrollToTop: true }}
          primaryText={translate(`resources.notifiers.ping.name`, {
            smart_count: 2,
          })}
          leftIcon={<CompassCalibrationIcon />}
          dense={dense}
        />
      )}
      {isP('notifier_exchange_report') && (
        <MenuItemLink
          to="/versions"
          state={{ _scrollToTop: true }}
          primaryText={translate(`resources.versions.name`, {
            smart_count: 2,
          })}
          leftIcon={<FormatListNumberedIcon />}
          dense={dense}
        />
      )}

      <SubMenu
        handleToggle={() => handleToggle('menuSystem')}
        isOpen={state.menuSystem}
        name="pos.menu.system"
        icon={<SettingsSuggestIcon />}
        dense={dense}
      >
        {(isP('rbac_resources') || isP('rbac_permissions')) && (
          <SubMenu
            handleToggle={() => handleToggle('menuRbac')}
            isOpen={state.menuRbac}
            name="pos.menu.rbac"
            icon={<GroupsIcon />}
            dense={dense}
          >
            {isP('rbac_resources') && (
              <MenuItemLink
                to="/rbac_resources"
                state={{ _scrollToTop: true }}
                primaryText={translate(`resources.rbac_resources.name`, {
                  smart_count: 2,
                })}
                leftIcon={<Resources.icon />}
                dense={dense}
              />
            )}
            {isP('rbac_permissions') && (
              <MenuItemLink
                to="/rbac_permissions"
                state={{ _scrollToTop: true }}
                primaryText={translate(`resources.rbac_permissions.name`, {
                  smart_count: 2,
                })}
                leftIcon={<Permissions.icon />}
                dense={dense}
              />
            )}
          </SubMenu>
        )}

        {isP('skills') && (
          <MenuItemLink
            to="/skills"
            state={{ _scrollToTop: true }}
            primaryText={translate(`resources.skills.name`, {
              smart_count: 2,
            })}
            leftIcon={<Skills.icon />}
            dense={dense}
          />
        )}
        {isP('position') && (
          <MenuItemLink
            to="/position"
            state={{ _scrollToTop: true }}
            primaryText={translate(`resources.position.name`, {
              smart_count: 2,
            })}
            leftIcon={<Positions.icon />}
            dense={dense}
          />
        )}

        {isP('properties') && (
          <MenuItemLink
            to="/properties"
            state={{ _scrollToTop: true }}
            primaryText={translate(`resources.employees.properties.name`, {
              smart_count: 2,
            })}
            leftIcon={<ContactMailIcon />}
            dense={dense}
          />
        )}
        {isP('exchanges') && (
          <MenuItemLink
            to="/exchanges"
            state={{ _scrollToTop: true }}
            primaryText={translate(`resources.exchanges.name`, { smart_count: 2 })}
            leftIcon={<Exchanges.icon />}
            dense={dense}
          />
        )}
        {isP('liquidity_bots') && (
          <MenuItemLink
            to="/liquidity_bots"
            state={{ _scrollToTop: true }}
            primaryText={'Liquidity Bots'}
            leftIcon={<Exchanges.icon />}
            dense={dense}
          />
        )}

        {isP('exchange_instances') && (
          <MenuItemLink
            to="/exchange_instances"
            state={{ _scrollToTop: true }}
            primaryText={translate(`resources.exchange_instances.name`, { smart_count: 2 })}
            leftIcon={<Exchange_instances.icon />}
            dense={dense}
          />
        )}
        {isP('blockchains') && (
          <MenuItemLink
            to="/blockchains"
            state={{ _scrollToTop: true }}
            primaryText={translate(`resources.blockchains.name`, { smart_count: 2 })}
            leftIcon={<Blockchains.icon />}
            dense={dense}
          />
        )}
        {isP('blockchain_types') && (
          <MenuItemLink
            to="/blockchain_types"
            state={{ _scrollToTop: true }}
            primaryText={translate(`resources.blockchaintypes.name`, { smart_count: 2 })}
            leftIcon={<Blockchain_types.icon />}
            dense={dense}
          />
        )}
        {isP('server_providers') && (
          <MenuItemLink
            to="/server_providers"
            state={{ _scrollToTop: true }}
            primaryText={translate(`resources.serverproviders.name`, { smart_count: 2 })}
            leftIcon={<Server_providers.icon />}
            dense={dense}
          />
        )}
        {isP('servers') && (
          <MenuItemLink
            to="/servers"
            state={{ _scrollToTop: true }}
            primaryText={translate(`resources.servers.name`, { smart_count: 2 })}
            leftIcon={<Servers.icon />}
            dense={dense}
          />
        )}
      </SubMenu>
    </Box>
  ) : (
    <CircularProgress />
  );
};

export default Menu;
