import * as React from 'react';
import { useRecordContext, NumberField, NumberFieldProps, FunctionField } from 'react-admin';
import { Tooltip } from '@mui/material';

const ColoredBacklogFieldAnt = (props: NumberFieldProps) => {
  const record = useRecordContext(props);
  if (!record || !props.source) {
    return null;
  }

  const left = Number.isNaN(+(record?.height ?? undefined)) ? 'N/A' : record?.height;
  const right = Number.isNaN(+(record?.blockchain?.height ?? undefined))
    ? 'N/A'
    : record?.blockchain?.height;

  const condition = right - left >= 2;
  const value = `${+right - +left}`;
  const tooltipValue = `${left} / ${right}`;

  return condition ? (
    <FunctionField
      sx={{ color: 'red' }}
      render={(record: any) => (
        <Tooltip
          title={tooltipValue}
          style={{
            height: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
          placement="top"
        >
          <p>{value}</p>
        </Tooltip>
      )}
    />
  ) : (
    <FunctionField
      render={(record: any) => (
        <Tooltip
          title={tooltipValue}
          style={{
            height: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
          placement="top"
        >
          <p>{value}</p>
        </Tooltip>
      )}
    />
  );
};

ColoredBacklogFieldAnt.defaultProps = NumberField.defaultProps;

export default ColoredBacklogFieldAnt;
