import React from 'react';
import {
  AutocompleteInput,
  Create,
  Edit,
  FormTab,
  ReferenceInput,
  required,
  SelectInput,
  TabbedForm,
  TextField,
  TextInput,
} from 'react-admin';

const CostsEdit = (props: any) => (
  <Edit {...props}>
    <TabbedForm>
      <FormTab
        label={
          <span>
            <span>Cost</span>
          </span>
        }
      >
        <SelectInput
          source="type"
          defaultValue={'Income'}
          choices={[
            { id: 'Income', name: 'Income' },
            { id: 'Expense', name: 'Expense' },
          ]}
        />
        <TextInput autoFocus fullWidth source="amount" />

        <SelectInput
          source="currency"
          defaultValue={'USDT'}
          choices={[
            { id: 'BTC', name: 'BTC' },
            { id: 'USDT', name: 'USDT' },
            { id: 'EUR', name: 'EUR' },
            { id: 'UAH', name: 'UAH' },
            { id: 'USD', name: 'USD' },
            { id: 'AED', name: 'AED' },
          ]}
        />
        <TextInput autoFocus fullWidth source="total" />
        <TextInput autoFocus fullWidth source="description" />
        <TextInput autoFocus fullWidth source="proof" />
        <ReferenceInput
          label="Exchange"
          source="projectId"
          reference="projects"
          sort={{ field: 'name', order: 'ASC' }}
          alwaysOn
          pagination={false}
          perPage={1000}
        >
          <AutocompleteInput
            optionText="name"
            optionValue="id"
            defaultValue={''}
            validate={required()}
          />
        </ReferenceInput>
      </FormTab>
    </TabbedForm>
  </Edit>
);

export default CostsEdit;
