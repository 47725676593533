import ExchangesList from './ExchangesList';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import ExchangeCreate from './ExchangeCreate';
import ExchangeEdit from './ExchangeEdit';

export default {
  list: ExchangesList,
  create: ExchangeCreate,
  icon: AccountBalanceIcon,
  edit: ExchangeEdit,
};
