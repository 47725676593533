import * as React from 'react';
import { useEffect, useState } from 'react';
import { Box, Card, CardContent, Grid, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import { Loading, ReferenceField, TextField, useRecordContext } from 'react-admin';
import Grants from './Grants';

const url = `${process.env.REACT_APP_API_URL}`;

const RbacResourceShow = () => {
  const record = useRecordContext<any>();
  if (!record) return null;
  const request = new Request(`${url}/rbac_permissions?filter={"resourceId":${record.id}}`, {
    method: 'GET',
    credentials: 'include',
    redirect: 'follow',
  });
  const [permissions, setPermissions] = useState('');

  useEffect(() => {
    (async () => {
      const response = await fetch(request);
      const res = await response.json();
      setPermissions(res);
    })();
  }, [setPermissions]);
  if (!permissions) return <Loading />;

  const handleCreateActions = (resourceId: number) => {
    const request_batch = new Request(`${url}/rbac_permissions/${resourceId}/create_batch`, {
      method: 'POST',
      credentials: 'include',
      redirect: 'follow',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    });
    (async () => {
      const response = await fetch(request_batch);
      const resp = await response.json();

      (async () => {
        const response = await fetch(request);
        const res = await response.json();
        setPermissions(res);
      })();
    })();
  };

  return (
    <Card sx={{ width: 600, margin: 'auto' }}>
      <CardContent>
        <Box margin="5px 0">
          <Grants data={permissions} />
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '1rem',
            }}
          >
            <Button
              variant="outlined"
              color="primary"
              size="small"
              onClick={() => handleCreateActions(record.id)}
            >
              CREATE BASE ACTIONS
            </Button>
          </div>
        </Box>
      </CardContent>
    </Card>
  );
};

export default RbacResourceShow;
