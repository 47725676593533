import * as React from 'react';
import { Identifier, Datagrid, DateField, TextField, FunctionField } from 'react-admin';
import rowStyleFiles from './rowStyle';
import DateFnsUtils from '@date-io/date-fns';
import lv from 'date-fns/locale/fr';
import { dateFormat } from '../utils/helpers';

export interface ReviewListDesktopProps {
  selectedRow?: Identifier;
}

const FilesListDesktop = ({ selectedRow }: ReviewListDesktopProps) => (
  <Datagrid
    rowClick="edit"
    rowStyle={rowStyleFiles(selectedRow)}
    optimized
    sx={{
      '& .RaDatagrid-thead': {
        borderLeftColor: 'transparent',
        borderLeftWidth: 5,
        borderLeftStyle: 'solid',
      },
      '& .column-comment': {
        maxWidth: '18em',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },
    }}
  >
    <TextField source="id" />
    <TextField source="key" />
    <FunctionField
      label="Assigned to"
      render={(record: any) =>
        `${record?.employees?.[0]?.lastname} ${record?.employees?.[0]?.firstname}`
      }
    />
    <FunctionField
      source="createdAt"
      label="createdAt"
      render={(record: any) => {
        return dateFormat(record.createdAt);
      }}
    />{' '}
    <TextField source="category" />
    <TextField source="mimetype" />
    <TextField source="size" />
    <TextField source="author" />
  </Datagrid>
);

export default FilesListDesktop;
