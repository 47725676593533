import { Box, Typography } from '@mui/material';
import {
  Create,
  email,
  PasswordInput,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
  useTranslate,
} from 'react-admin';

export const validateForm = (values: Record<string, any>): Record<string, any> => {
  const errors = {} as any;

  if (!values.email) {
    errors.email = 'ra.validation.required';
  } else {
    const error = email()(values.email);
    if (error) {
      errors.email = error;
    }
  }
  if (values?.password !== values?.confirm_password) {
    errors.confirm_password = 'resources.customers.errors.password_mismatch';
  }
  return errors;
};

const UserCreate = () => {
  const permissions = localStorage.getItem('role') ?? '';

  return (
    <Create>
      <SimpleForm validate={validateForm}>
        <SectionTitle label="resources.customers.fieldGroups.identity" />
        <Box display={{ xs: 'block', sm: 'flex' }}>
          <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
            <TextInput type="email" source="email" autoFocus isRequired fullWidth />
          </Box>
          <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
            <TextInput type="text" source="username" isRequired fullWidth />
          </Box>
        </Box>
        <Separator />

        <SectionTitle label="resources.users.fieldGroups.role" />
        <Box display={{ xs: 'block', sm: 'flex' }}>
          <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
            {permissions === 'super' && (
              <SelectInput
                source="role"
                validate={required()}
                defaultValue={'hr'}
                choices={[
                  { id: 'hr', name: 'HR' },
                  { id: 'admin', name: 'Admin' },
                  { id: 'super', name: 'Superadmin' },
                  { id: 'blockchain', name: 'Blockchain' },
                ]}
              />
            )}
            {permissions === 'admin' && (
              <SelectInput
                source="role"
                validate={required()}
                defaultValue={'hr'}
                choices={[
                  { id: 'hr', name: 'HR' },
                  { id: 'admin', name: 'Admin' },
                ]}
              />
            )}
          </Box>
        </Box>

        <SectionTitle label="resources.users.fieldGroups.password" />
        <Box display={{ xs: 'block', sm: 'flex' }}>
          <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
            <PasswordInput source="password" fullWidth autoComplete="new-password" />
          </Box>
          <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
            <PasswordInput source="confirm_password" fullWidth />
          </Box>
        </Box>
      </SimpleForm>
    </Create>
  );
};

const SectionTitle = ({ label }: { label: string }) => {
  const translate = useTranslate();

  return (
    <Typography variant="h6" gutterBottom>
      {translate(label as string)}
    </Typography>
  );
};

const Separator = () => <Box pt="1em" />;

export default UserCreate;
