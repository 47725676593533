import React, { useMemo, CSSProperties } from 'react';
import { useGetList } from 'react-admin';
import { useMediaQuery, Theme } from '@mui/material';
import Welcome from './Welcome';
import { Order, ProjectTotal } from '../types';
import NewPersons from './NewPersons';
import NewEmployees from './NewEmployees';
import TotalProjects from './TotalProjects';

interface State {
  nbNewOrders?: number;
  pendingOrders?: Order[];
  recentOrders?: Order[];
  revenue?: string;
  totalProjects?: string;
}

const styles = {
  flex: { display: 'flex' },
  flexColumn: { display: 'flex', flexDirection: 'column' },
  leftCol: { flex: 1, marginRight: '0.5em' },
  rightCol: { flex: 1, marginLeft: '0.5em' },
  singleCol: { marginTop: '1em', marginBottom: '1em' },
};

const Spacer = () => <span style={{ width: '1em' }} />;

const Dashboard = () => {
  const isXSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));

  const { data: projects } = useGetList<ProjectTotal>('projects', {
    filter: {},
    sort: { field: 'id', order: 'DESC' },
    pagination: { page: 1, perPage: 1000 },
  });
  const aggregation = useMemo<State>(() => {
    if (!projects) return {};

    return {
      totalProjects: projects?.length.toString(),
    };
  }, [projects]);
  const { totalProjects } = aggregation;

  return isXSmall ? (
    <div>
      <div style={styles.flexColumn as CSSProperties}>
        <Welcome />
      </div>
    </div>
  ) : isSmall ? (
    <div style={styles.flexColumn as CSSProperties}>
      <div style={styles.singleCol}>
        <Welcome />
      </div>
      <div style={styles.flex}></div>
      <div style={styles.singleCol}></div>
      <div style={styles.singleCol}></div>
    </div>
  ) : (
    <>
      <Welcome />
      <div style={styles.flex}>
        <div style={styles.leftCol}>
          <div style={styles.flex}>
            <TotalProjects value={totalProjects} />
          </div>
        </div>
        <div style={styles.rightCol}>
          <div style={styles.flex}>
            <NewPersons />
            <Spacer />
            <NewEmployees />
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
