import { Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import {
  BooleanInput,
  Edit,
  PasswordInput,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
  useGetOne,
  useTranslate,
} from 'react-admin';
import { useParams } from 'react-router';
import FullNameField from '../visitors/FullNameField';
import { validateForm } from './UserCreate';

const UserEdit = () => {
  const translate = useTranslate();
  const permissions = localStorage.getItem('role') ?? '';
  const { id } = useParams();
  const { data, isLoading } = useGetOne('users', { id });

  if (isLoading) return null;
  const disabled = (data.role === 'admin' || data.role === 'super') && permissions === 'admin';
  const activeForAdmin = permissions === 'admin' && data.role === 'hr';
  return (
    <Edit title={<UserTitle />}>
      <SimpleForm validate={validateForm}>
        <Grid container width={{ xs: '100%', xl: 800 }} spacing={2}>
          <Grid item xs={12} md={8}>
            <Typography variant="h6" gutterBottom>
              {translate('resources.customers.fieldGroups.identity')}
            </Typography>
            <Box display={{ xs: 'block', sm: 'flex' }}>
              <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                <TextInput source="username" autoFocus fullWidth />
              </Box>
              <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                <TextInput type="email" source="email" isRequired fullWidth />
              </Box>
            </Box>

            {(permissions === 'super' || activeForAdmin) && (
              <BooleanInput fullWidth source="isActive" />
            )}

            <Box display={{ xs: 'block', sm: 'flex' }}>
              <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                {permissions === 'super' && (
                  <SelectInput
                    source="role"
                    validate={required()}
                    defaultValue={'hr'}
                    choices={[
                      { id: 'hr', name: 'HR' },
                      { id: 'admin', name: 'Admin' },
                      { id: 'super', name: 'Superadmin' },
                      { id: 'blockchain', name: 'Blockchain' },
                    ]}
                  />
                )}
                {permissions === 'admin' && (
                  <SelectInput
                    source="role"
                    disableValue="not_available"
                    disabled={disabled}
                    choices={[
                      { id: 'hr', name: 'HR' },
                      { id: 'admin', name: 'Admin' },
                      { id: 'super', name: 'Superadmin', not_available: true },
                    ]}
                  />
                )}
              </Box>
            </Box>

            {permissions === 'super' && (
              <>
                <Box mt="1em" />

                <Typography variant="h6" gutterBottom>
                  {translate('resources.customers.fieldGroups.old_password')}
                </Typography>
                <Box display={{ xs: 'block', sm: 'flex' }}>
                  <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                    <PasswordInput source="old_password" fullWidth autoComplete="new-password" />
                  </Box>
                </Box>
                <Typography variant="h6" gutterBottom>
                  {translate('resources.customers.fieldGroups.change_password')}
                </Typography>

                <Box display={{ xs: 'block', sm: 'flex' }}>
                  <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                    <PasswordInput source="password" fullWidth autoComplete="new-password" />
                  </Box>
                  <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                    <PasswordInput source="confirm_password" fullWidth />
                  </Box>
                </Box>
              </>
            )}
          </Grid>
        </Grid>
      </SimpleForm>
    </Edit>
  );
};
const UserTitle = () => <FullNameField size="32" sx={{ margin: '5px 0' }} />;
export default UserEdit;
