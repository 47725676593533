import {
  CreateButton,
  Datagrid,
  EditButton,
  FunctionField,
  List,
  TextField,
  TopToolbar,
} from 'react-admin';

const ServerList = () => (
  <List actions={<ListActions />} sort={{ field: 'id', order: 'DESC' }}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="name" />
      <FunctionField label="Provider" render={(record: any) => record?.provider?.name} />
      <FunctionField
        label="Home Memory"
        render={(record: any) => {
          const left = Number.isNaN(+(record?.homeMemoryUsage ?? undefined))
            ? 'N/A'
            : (record?.homeMemoryUsage / 1024 / 1024 / 1024).toFixed(2);
          const right = Number.isNaN(+(record?.homeMemoryTotal ?? undefined))
            ? 'N/A'
            : (record?.homeMemoryTotal / 1024 / 1024 / 1024).toFixed(2);
          return `${left} / ${right} GB`;
        }}
      />
      <FunctionField
        label="Root Memory"
        render={(record: any) => {
          const left = Number.isNaN(+(record?.rootMemoryUsage ?? undefined))
            ? 'N/A'
            : (record?.rootMemoryUsage / 1024 / 1024 / 1024).toFixed(2);
          const right = Number.isNaN(+(record?.rootMemoryTotal ?? undefined))
            ? 'N/A'
            : (record?.rootMemoryTotal / 1024 / 1024 / 1024).toFixed(2);
          return `${left} / ${right} GB`;
        }}
      />
      <FunctionField
        label="RAM"
        render={(record: any) => {
          const left = Number.isNaN(+(record?.ramUsage ?? undefined))
            ? 'N/A'
            : (record?.ramUsage / 1024 / 1024).toFixed(2);
          const right = Number.isNaN(+(record?.ramTotal ?? undefined))
            ? 'N/A'
            : (record?.ramTotal / 1024 / 1024).toFixed(2);
          return `${left} / ${right} GB`;
        }}
      />
      <FunctionField
        label="SWAP"
        render={(record: any) => {
          const left = Number.isNaN(+(record?.swapUsage ?? undefined))
            ? 'N/A'
            : (record?.swapUsage / 1024 / 1024).toFixed(2);
          const right = Number.isNaN(+(record?.swapTotal ?? undefined))
            ? 'N/A'
            : (record?.swapTotal / 1024 / 1024).toFixed(2);
          return `${left} / ${right} GB`;
        }}
      />
      <TextField source="monitoringApiKey" />
      <TextField source="monitoringUrl" />
    </Datagrid>
  </List>
);

const ListActions = ({ isSmall }: any) => (
  <TopToolbar sx={{ minHeight: { sm: 56 } }}>
    <CreateButton />
    <EditButton />
  </TopToolbar>
);

export default ServerList;
