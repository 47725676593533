import * as React from 'react';
import { Create, FormTab, TabbedForm, TextInput, required } from 'react-admin';

const SkillCreate = () => (
  <Create>
    <TabbedForm>
      <FormTab label="resources.projects.tabs.main" sx={{ maxWidth: '40em' }}>
        <TextInput autoFocus source="name" fullWidth validate={required()} />
        <TextInput source="category" fullWidth />
      </FormTab>
    </TabbedForm>
  </Create>
);

export default SkillCreate;
