import FormatBoldIcon from '@mui/icons-material/FormatBold';
import CostsList from './CostsList';
import CostsCreate from './CostsCreate';
import CostsEdit from './CostsEdit';

export default {
  list: CostsList,
  create: CostsCreate,
  icon: FormatBoldIcon,
  edit: CostsEdit,
};
