import * as React from 'react';
import { useRecordContext, NumberField, NumberFieldProps, FunctionField } from 'react-admin';

const PingColoredHealthField = (props: NumberFieldProps) => {
  const record = useRecordContext(props);
  if (!record || !props.source) {
    return null;
  }
  const ping = parseInt(record?.ping) / 1000;

  const requestTimeCondition = !Number.isNaN(ping) && ping > 30 ? false : true;
  const condition = !requestTimeCondition;

  return condition ? (
    <FunctionField sx={{ color: 'red' }} render={(record: any) => 'UnHealth'} />
  ) : (
    <FunctionField sx={{ color: 'green' }} render={(record: any) => 'Health'} />
  );
};

PingColoredHealthField.defaultProps = NumberField.defaultProps;

export default PingColoredHealthField;
