import { AuthProvider } from 'react-admin';

const url = `${process.env.REACT_APP_API_URL}`;

const authProvider: AuthProvider = {
  login: ({ username, password }) => {
    const request = new Request(`${url}/auth/login`, {
      method: 'POST',
      body: JSON.stringify({ email: username, password }),
      headers: new Headers({ 'Content-Type': 'application/json' }),
      credentials: 'include',
    });
    return fetch(request)
      .then(response => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then(({ id, role, username }) => {
        localStorage.setItem('role', role);
        localStorage.setItem('userId', id);
        localStorage.setItem('username', username);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch(() => {
        throw new Error('Network error');
      });
  },
  logout: () => {
    const request = new Request(`${url}/auth/logout`, {
      method: 'POST',
      credentials: 'include',
      redirect: 'follow',
    });
    return fetch(request)
      .then(response => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }

        return Promise.resolve();
      })
      .then(() => {
        localStorage.removeItem('role');
        localStorage.removeItem('userId');
        localStorage.removeItem('username');
      })
      .catch(() => {
        throw new Error('Network error');
      });
  },
  checkError: ({ status }) => {
    if (status === 401) {
      return Promise.reject({ redirectTo: '/login' });
    }
    return Promise.resolve();
  },
  checkAuth: () => {
    const request = new Request(`${url}/auth/authenticate`, {
      method: 'GET',
      credentials: 'include',
      redirect: 'follow',
    });
    return fetch(request)
      .then(response => {
        return Promise.resolve();
      })

      .catch(e => {
        return Promise.reject({ redirectTo: '/login' });
      });
  },
  getPermissions: async () => {
    const projects = await fetch(`${process.env.REACT_APP_API_URL}/system_rbac`, {
      method: 'GET',
      credentials: 'include',
      redirect: 'follow',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const data = await projects.json();
    // @ts-ignore
    const grants = data?.grants;
    const userPermissionsNames = grants?.[localStorage.getItem('role') ?? ('' as string)] ?? [];
    console.log('userPermissionsNames',userPermissionsNames);
    const userPermissions: any = userPermissionsNames?.map((permissionName: string) => {
      if (permissionName === 'employees@analytics') {
        return permissionName;
      }
      if (permissionName === 'exchange_pairs@filter') {
        return permissionName;
      }
      if (permissionName === 'projects@analytics') {
        return permissionName;
      }
      if (permissionName === 'employee_projects@filter' || permissionName === 'employee_projects') {
        return permissionName;
      }

      if (permissionName?.includes('@')) {
        const [resource, action] = permissionName?.split('@');

        return resource;
      }
      return permissionName;
    });
    // @ts-ignore
    const uniqueUserPermissions = [...new Set(userPermissions)];
    console.log('[Current role permissions]: ', uniqueUserPermissions);
    localStorage.setItem('permissions', JSON.stringify(uniqueUserPermissions));
    return uniqueUserPermissions;
  },
  getIdentity: () => {
    const id = localStorage.getItem('userId');
    if (!id) return Promise.reject({ redirectTo: '/login' });

    return Promise.resolve({
      id: localStorage.getItem('userId') || 'user',
      fullName: localStorage.getItem('username') || 'Test',
      username: localStorage.getItem('username'),
      avatar:
        'https://secure.gravatar.com/avatar/b12cd662a157ee749044bdbad0a39af7?s=80&d=identicon',
    });
  },
};

export default authProvider;
