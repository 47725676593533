import * as React from 'react';
import { useRecordContext, NumberField, NumberFieldProps, FunctionField } from 'react-admin';

const PingPhpFieldNtf = (props: NumberFieldProps) => {
  const record = useRecordContext(props);
  if (!record || !props.source) {
    return null;
  }
  let title = '';
  for (const report of record?.notifierExchangeReports.values()) {
    const ping = parseInt(report?.ping) / 1000;
    const exchangeInstanceUrl = report?.exchangeInstance?.url;
    title += `${exchangeInstanceUrl}: ${Number.isNaN(ping) ? 'N/A' : ping} ms \n`;
  }
  const value = record?.pingFromExchange;

  return <FunctionField title={title} render={(record: any) => `${value ? 'ok' : 'not ok'}`} />;
};

PingPhpFieldNtf.defaultProps = NumberField.defaultProps;

export default PingPhpFieldNtf;
