import {
  FormTab,
  number,
  minValue,
  NumberInput,
  ReferenceInput,
  required,
  SelectInput,
  TabbedForm,
  TextInput,
  BooleanInput,
  Edit,
  AutocompleteInput,
} from 'react-admin';

const transform = (data: any) => {
  return {
    ...data,
    ping: data?.ping ? String(data?.ping) : null,
    responseTime: data?.responseTime ? Number(data.responseTime) : 0,
  };
};
const AntnodeEdit = () => {
  return (
    <Edit transform={transform}>
      <TabbedForm>
        <FormTab label="resources.antnodes.tabs.main" sx={{ maxWidth: '40em' }}>
          <ReferenceInput
            label="Blockchain"
            source="blockchainId"
            reference="blockchains"
            sort={{ field: 'name', order: 'ASC' }}
            alwaysOn
            pagination={false}
            perPage={1000}
          >
            <AutocompleteInput
              optionText="name"
              optionValue="id"
              defaultValue={''}
              validate={required()}
            />
          </ReferenceInput>

          <TextInput source="name" fullWidth validate={required()} />
          <TextInput source="nodeUrl" fullWidth validate={required()} />
          <BooleanInput source="status" />
          <NumberInput validate={number()} source="height" />
          <ReferenceInput
            label="Server"
            source="serverId"
            reference="servers"
            sort={{ field: 'name', order: 'ASC' }}
            alwaysOn
            pagination={false}
            perPage={1000}
          >
            <AutocompleteInput
              optionText="name"
              optionValue="id"
              defaultValue={''}
              validate={required()}
            />
          </ReferenceInput>
          <NumberInput validate={number()} source="ping" />
          <NumberInput validate={number()} source="responseTime" />
          <BooleanInput source="inPool" validate={required()} />
          <TextInput source="version" fullWidth />
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

export default AntnodeEdit;
