import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import ExchangeInstanceCreate from './ExchangeInstanceCreate';
import ExchangeInstanceEdit from './ExchangeInstanceEdit';
import ExchangeInstanceList from './ExchangeInstanceList';

export default {
  list: ExchangeInstanceList,
  create: ExchangeInstanceCreate,
  icon: IntegrationInstructionsIcon,
  edit: ExchangeInstanceEdit,
};
