import { CreateButton, Datagrid, EditButton, List, TextField, TopToolbar } from 'react-admin';

const ExchangesList = () => (
  <List actions={<ListActions />} sort={{ field: 'id', order: 'DESC' }}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="apiKey" />
    </Datagrid>
  </List>
);

const ListActions = ({ isSmall }: any) => (
  <TopToolbar sx={{ minHeight: { sm: 56 } }}>
    <CreateButton />
    <EditButton />
  </TopToolbar>
);

export default ExchangesList;
